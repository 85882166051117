import { Bar, BarChart, LabelList, XAxis, YAxis } from 'recharts';
import { ChartConfig, ChartContainer } from '@/components/ui/chart';
import {
  CalculatorIcon,
  Clock,
  PlaneIcon,
  ShareIcon,
  TrainIcon,
  ZapIcon,
} from 'lucide-react';
import { TrainEmissions } from 'utils/lowtrip/trains';
import ReactCountryFlag from 'react-country-flag';
import { getCountryName } from 'utils/localisation';
import { useLocale } from 'next-intl';
import { Button } from '../ui/button';
import { toast } from '../ui/use-toast';

const formatCarbonData = (emissions: number) => {
  return parseInt(emissions.toFixed(1));
};
const CustomIcon = ({ mode }) => {
  const Icon = mode === 'train' ? TrainIcon : null;
  const color = mode === 'train' ? '#2a966f' : '#F84F39';
  return <g>{Icon && <Icon className={`w-6 h-6 text-[${color}]`} x={44} y={74} />}</g>;
};

const CarbonChart = ({
  t,
  originName,
  destinationName,
  trainCarbonEmissions,
  flightCarbonEmissions,
  detailedTrainCarbonEmissions,
}: {
  t: any;
  originName: string;
  destinationName: string;
  trainCarbonEmissions: number | null;
  flightCarbonEmissions: number;
  detailedTrainCarbonEmissions: TrainEmissions[] | null;
}) => {
  const chartConfig = {
    emissions: {
      label: t('XaxisUnit'),
    },
    labeled_emissions: {
      label: 'Emissions : ',
    },
    plane: {
      label: t('Plane'),
      color: 'hsl(var(--chart-1))',
    },
    train: {
      label: t('Train'),
      color: '#2a966f',
    },
  } satisfies ChartConfig;

  const computedCarbonEmissions = [
    {
      mode: 'train',
      icon: TrainIcon,
      emissions: formatCarbonData(trainCarbonEmissions ?? 0),
      fill: 'var(--color-train)',
    },
    {
      mode: 'plane',
      icon: PlaneIcon,
      emissions: formatCarbonData(flightCarbonEmissions),
      fill: 'var(--color-plane)',
    },
  ];

  const yearlyEmissionsOfWashingMachine = 55.8;
  const yearlyEmissionsOfHeatingStudio = 11.9 * 18;
  const emissionsGapFactor = flightCarbonEmissions / (trainCarbonEmissions ?? 1);
  const emissionsGap = flightCarbonEmissions - (trainCarbonEmissions ?? 0);
  const equivalentInWashingMachineEmissions = Math.max(
    1,
    Math.round(emissionsGap / yearlyEmissionsOfWashingMachine)
  );
  const equivalentInHeatingStudioEmissions = Math.max(
    1,
    Math.round(emissionsGap / yearlyEmissionsOfHeatingStudio)
  );

  return (
    <div className="flex flex-col w-full bg-white rounded-lg p-6 space-y-5">
      <h2 className="text-base font-semibold">
        {' '}
        {t('mathsDoneFor', { journey: `${originName} - ${destinationName}` })}
      </h2>
      <EmissionExplanation
        icon={ZapIcon}
        active={true}
        title={t('timesCO2Less', { factor: Math.round(emissionsGapFactor) })}
        description={t.rich('equivalentTo', {
          years: equivalentInHeatingStudioEmissions,
          appliance: t('washingMachine'),
          washingMachine: equivalentInWashingMachineEmissions,
          strong: (chunks) => <div className="inline-block font-semibold">{chunks}</div>,
        })}
      >
        <div className="relative">
          <PlaneIcon className="w-6 h-6 text-orange absolute top-4 right-11 transform -translate-y-1/2" />

          <ChartContainer config={chartConfig} className="h-32 -pl-2">
            <BarChart
              data={computedCarbonEmissions}
              margin={{ top: 26, right: 0, left: 0, bottom: 5 }}
            >
              <XAxis
                dataKey="mode"
                type="category"
                axisLine={false}
                tickLine={false}
                hide
              />
              <YAxis dataKey="emissions" type="number" hide />

              <Bar dataKey="emissions" barSize={80} minPointSize={22} radius={4}>
                <LabelList dataKey="mode" position="top" content={CustomIcon} />
                <LabelList
                  dataKey="emissions"
                  position="center"
                  formatter={(value) => `${value} kg`}
                  offset={10}
                  fontSize={12}
                  style={{ fill: 'white', fontWeight: 'medium' }}
                  className="text-sm"
                />
              </Bar>
            </BarChart>
          </ChartContainer>
        </div>
      </EmissionExplanation>

      <EmissionExplanation
        icon={TrainIcon}
        title={t('trainEmissions')}
        description={t.rich('trainEmissionsDescription', {
          strong: (chunks) => (
            <div className="inline-block text-wrap font-semibold">{chunks}</div>
          ),
          a: (chunks) => (
            <a
              href="https://lowtrip.fr/method"
              target="_blank"
              className="hover:text-orange font-semibold"
            >
              {chunks}
            </a>
          ),
        })}
      >
        <TrainEmissionsDisplay detailedEmissions={detailedTrainCarbonEmissions} t={t} />
      </EmissionExplanation>
      <EmissionExplanation
        icon={PlaneIcon}
        title={t('flightEmissions')}
        description={t.rich('flightEmissionsDescription', {
          strong: (chunks) => <div className="inline-block font-semibold">{chunks}</div>,
          a: (chunks) => (
            <a
              href="https://librairie.ademe.fr/mobilite-et-transport/4617-etat-de-l-art-de-la-recherche-scientifique-sur-l-impact-climatique-des-trainees-de-condensation-des-avions.html"
              target="_blank"
              className="hover:text-orange font-semibold"
            >
              {chunks}
            </a>
          ),
        })}
        children={null}
      />

      <div className="flex flex-col sm:flex-row gap-4 mt-6">
        <Button
          variant="outline"
          className="flex-1 text-gray-800 py-2 px-4 rounded-lg transition-colors"
          onClick={() => {
            const url = window.location.href;
            navigator.clipboard
              .writeText(url)
              .then(() => {
                toast({
                  title: t('toast.copiedSuccess'),
                  description: t('toast.copiedSuccessSubtitle'),
                  duration: 3000,
                });
              })
              .catch(() => {
                toast({
                  title: t('toast.copiedError'),
                  description: t('toast.copiedErrorSubtitle'),
                  duration: 3000,
                });
              });
          }}
        >
          <ShareIcon className="w-4 h-4 mr-2" />
          {t('shareComparison')}
        </Button>
        <Button
          variant="outline"
          className="flex-1 text-gray-800 py-2 px-4 rounded-lg transition-colors"
          onClick={() => window.open('https://nosgestesclimat.fr/', '_blank')}
        >
          <CalculatorIcon className="w-4 h-4 mr-2" />
          {t('calculatePersonalFootprint')}
        </Button>
      </div>
    </div>
  );
};

export const EmissionExplanation = ({
  icon: Icon,
  active = false,
  title,
  description,
  children,
}) => (
  <div
    className={` ${active ? 'bg-green-100' : 'bg-gray-100'} p-4 rounded-lg flex flex-col md:flex-row items-center space-x-4`}
  >
    <div
      className={`${active ? 'bg-[#2a966f]' : 'bg-gray-300'} p-2 rounded-full  h-fit w-fit`}
    >
      <Icon className={`w-6 h-6 ${active ? 'text-green-100' : 'text-gray-600'}`} />
    </div>
    <div className="flex flex-col w-full mt-1.5 gap-1">
      <h3
        className={`font-semibold text-xl ${active ? 'text-[#2a966f]' : 'text-gray-800'} font-prosto`}
      >
        {title}
      </h3>
      <p
        className={`text-sm ${active ? 'text-[#2a966f]/90' : 'text-gray-400'} font-light  mb-2 font-sans`}
      >
        {description}
      </p>
    </div>
    <div className="md:self-end">{children}</div>
  </div>
);

const TrainEmissionsDisplay = ({ detailedEmissions, t }) => {
  const locale = useLocale();

  if (!detailedEmissions) return null;

  return (
    <div className="flex">
      <ul className="space-y-1.5 pb-2 pr-5 w-48">
        {detailedEmissions.map((emission) => (
          <li
            key={emission.countryCode}
            className="flex flex-row items-center justify-between text-sm text-nowrap font-light text-gray-400"
          >
            <div className="flex items-center space-x-2">
              <ReactCountryFlag
                countryCode={emission.countryCode}
                svg
                style={{ width: '1em', height: '1em' }}
                className="rounded-sm"
              />
              <span>{getCountryName(locale, emission.countryCode)}</span>
            </div>
            <span>{formatCarbonData(emission.totalEmissionsKg)} kg</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CarbonChart;
