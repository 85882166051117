import Image from 'next/image';

import { Skeleton } from '../ui/skeleton';

const TicketCardLoading = ({ transportMode }) => {
  return (
    <div className="relative my-2.5 block h-auto overflow-hidden rounded-xl bg-white border-[1px] pt-3 pb-1 pl-3 pr-3 transition-all md:hover:translate-x-2">
      <div
        className={`card relative z-10 flex w-full cursor-pointer flex-row text-left peer-focus:block`}
      >
        <div className="relative -ml-12 -mr-6 flex w-52 items-center grayscale">
          <Image
            src={
              transportMode == 'bus'
                ? '/assets/logos/flix_bus.png'
                : '/assets/logos/TGV INOUI.png'
            }
            alt={transportMode}
            height={50}
            width={120}
            className={'animate-pulse'}
            placeholder="blur"
            blurDataURL="/assets/logos/TGV INOUI.png"
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </div>

        <div className="flex w-full flex-grow flex-row justify-between">
          <div className="items-left flex flex-col flex-nowrap justify-between gap-2 py-2 pr-2">
            <Skeleton className="w-30 h-6" />
            <div className="text-md h-5 w-24 animate-pulse rounded-md bg-gray-100 leading-tight text-transparent" />
          </div>
          <div className="items-right flex flex-col items-end justify-between py-2 px-1 text-right">
            <Skeleton className="w-8 h-6" />
            <div className="flex h-5 w-24 animate-pulse rounded-md bg-gray-100"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketCardLoading;
