'use client';

import { Clock, InfoIcon } from 'lucide-react';
import { useTranslations } from 'next-intl';
import { Button } from '@/components/ui/button';
import { RouteType } from 'utils/route';
import CarbonChart, { EmissionExplanation } from './CarbonChart';
import { ScrollArea } from '../ui/scroll-area';
import { Drawer, DrawerContent, DrawerOverlay, DrawerTrigger } from '../ui/drawer';
import { FLIGHT_DURATION_THRESHOLD } from '../atoms/SearchResult';
import { useDestinationName, useOriginName } from 'hooks/useQuerySearch';
interface Props {
  route: RouteType | null;
  transportMode?: string;
  trainCarbonEmissions: any;
  flightCarbonEmissions: any;
  flightDuration: number;
  detailedTrainCarbonEmissions: any;
}

export default function CarbonEmissionDialog({
  route,
  transportMode,
  trainCarbonEmissions,
  flightCarbonEmissions,
  flightDuration,
  detailedTrainCarbonEmissions,
}: Props) {
  const t = useTranslations('CarbonEmission');
  const [originName] = useOriginName();
  const [destinationName] = useDestinationName();

  if (transportMode === 'car') {
    return null;
  }
  if (!route || !trainCarbonEmissions || !flightCarbonEmissions) {
    return null;
  }

  const TrainFlightFactor = Math.round(flightCarbonEmissions / trainCarbonEmissions);

  if (flightDuration < FLIGHT_DURATION_THRESHOLD) {
    return null;
  }

  return (
    <Drawer>
      <DrawerTrigger asChild>
        <Button
          variant="outline"
          className="bg-transparent before:content-[''] before:absolute before:bottom-full sm:before:left-1/2 before:left-1/3 before:transform  before:border-8 before:border-b-slate-300 before:border-x-transparent before:border-t-transparent
               h-auto relative text-left whitespace-normal break-normal rounded-xl font-semibold mt-2 mb-2 hover:text-[#2a966f]"
        >
          {t('btnMessage', { factor: TrainFlightFactor })}
          <InfoIcon className="inline-block w-3.5 h-3.5 ml-2" />
        </Button>
      </DrawerTrigger>
      <DrawerContent className="md:ml-8 2xl:ml-20 p-0 sm:h-fit smh:max-w-3xl h-[90%]">
        <ScrollArea className="h-full w-full">
          <CarbonChart
            t={t}
            originName={originName}
            destinationName={destinationName}
            trainCarbonEmissions={trainCarbonEmissions}
            flightCarbonEmissions={flightCarbonEmissions}
            detailedTrainCarbonEmissions={detailedTrainCarbonEmissions}
          />
        </ScrollArea>
      </DrawerContent>

      {transportMode == 'plane' && (
        <div className="my-4">
          <div className="py-6 mb-4 text-gray-500 text-center block overflow-hidden  pl-6 pr-6">
            {t.rich('noResultsWithPlane', { br: () => <br /> })}
          </div>
          <div className="max-w-96 mx-auto">
            <EmissionExplanation
              icon={Clock}
              title={t('flightDuration')}
              description={t.rich('flightDurationDescription', {
                strong: (chunks) => (
                  <div className="inline-block font-semibold">{chunks}</div>
                ),
                a: (chunks) => (
                  <a href="" target="_blank" className="hover:text-orange font-semibold">
                    {chunks}
                  </a>
                ),
              })}
              children={null}
            />
          </div>
        </div>
      )}
    </Drawer>
  );
}
