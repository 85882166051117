import { DateTime } from 'luxon';

import { toStringPrice } from 'lib/utilsClientSide';

import { BikeAvailabilityType } from './journey';

//email travelservices@eurostar.com to get the latest list (automated answers)
//latest rules https://share.streak.com/qOOYVH235V5w3y4eetrssS
const eurostarLondonBrusselAcceptingBikes = [
  //Londres-Bruxelles
  //09:01
  '9116',
  '9132',
  '9142',
  //Bruxelles-Londres
  //07:56
  '9113',
  '9135',
  '9141',
  //17:56
  '9153',
];
const eurostarParisLondonAcceptingBikes = [
  //Londres-Paris
  //8:01
  '9008',
  '9014',
  '9018',
  '9022',
  '9024',
  '9028',
  '9032',
  //15:31
  '9036',
  //Paris-Londres
  //11:13
  '9023',
  '9027',
  '9031',
  '9033',
  '9035',
  '9037',
  '9039',
  '9043',
  '9047',
  //18:13
  '9051',
];

const TERWithRequiredReservation = [
  '17750',
  '17752',
  '17754',
  '17756',
  '17758',
  '17760',
  '17762',
  '17764',
  '17787',
  '17767',
  '17769',
  '17771',
  '17773',
  '17775',
  '17777',
  '17779',
  '17781',
  '892601',
  '892603',
  '892605',
  '892607',
  '892611',
  '892600',
  '892602',
  '892604',
  '892606',
  '892608',
  '892610',
  '892612',
  '892301',
  '892303',
  '892305',
  '892307',
  '892309',
  '892300',
  '892302',
  '892304',
  '892306',
  '892308',
  '891150',
  '891152',
  '891154',
  '891156',
  '891158',
  '891162',
  '891164',
  '891166',
  '891151',
  '891153',
  '891155',
  '891054',
  '891056',
  '892009',
  '891071',
  '891073',
  '891083',
];
const StrasbourgBaleUnavailablesAtPeakTime = [
  '96205',
  '96207',
  '96209',
  '96229',
  '96231',
  '831399',
  '96233',
  '96206',
  '96208',
  '831396',
  '96210',
  '96232',
  '96234',
];
const ParisBarcelonaTGVIds = ['9713', '9715', '9704', '9708'];

const isStrasbourgBaleUnavailableAtPeakTime = (
  trainReference: string | null,
  departureDate: string,
  commercial_mode: string
) => {
  return (
    commercial_mode.startsWith('TER') &&
    trainReference &&
    StrasbourgBaleUnavailablesAtPeakTime.includes(trainReference) &&
    ((DateTime.fromISO(departureDate).hour >= 6 &&
      DateTime.fromISO(departureDate).hour <= 9) ||
      (DateTime.fromISO(departureDate).hour > 16 &&
        DateTime.fromISO(departureDate).hour <= 18))
  );
};

export const bikePrice = (
  commercial_mode: string,
  departureDate: string,
  trainReference: string | null,
  distanceInMeters: number,
  DBpassengerFares: any
) => {
  let asLuggageCost: number | null = null;
  let mountedCost: number | null = null;
  let avoidPeaktimes = false;
  let ifSufficientSpace = false;
  let reservationRequired = false;
  let nothingAvailable = false;

  if (commercial_mode) {
    switch (true) {
      case commercial_mode === 'Lyria':
      case commercial_mode === 'TGV Lyria':
      case commercial_mode === 'FR':
      case commercial_mode === 'lumo':
      case commercial_mode.startsWith('Iryo'):
      case commercial_mode === 'Milano Centrale - Roma Termini':
      case commercial_mode === 'Torino Porta Nuova - Salerno':
        asLuggageCost = 0;
        break;

      //Alsace: Cartains Strasbourg-Bale interdit aux vélos de 6h à 8h30 et de 16h à 18h30:  https://mmt.vsct.fr/sites/default/files/swt/CGRE/2022-06/Liste%20des%20TER%20200%20interdits%20au%20v%C3%A9lo.pdf
      case isStrasbourgBaleUnavailableAtPeakTime(
        trainReference,
        departureDate,
        commercial_mode
      ):
        asLuggageCost = 0;
        break;

      //Bourgogne: certaines lignes soumises à réservations (gratuites) https://mmt.vsct.fr/sites/default/files/swt/CBFC/2023-12/Liste%20des%20TER%20soumis%20%C3%A0%20R%C3%A9servation%20v%C3%A9lo%20obligatoire%20SA23-24%20Actualis%C3%A9%20DEC23.pdf
      case commercial_mode.startsWith('TER') &&
        trainReference &&
        TERWithRequiredReservation.includes(trainReference):
        asLuggageCost = 0;
        mountedCost = 0;
        reservationRequired = true;
        break;

      //Aquitaine: Réservation payante obligatoire les samedis, dimanches j fériés
      case commercial_mode.startsWith('TER') &&
        trainReference?.startsWith('87') &&
        DateTime.fromISO(departureDate).weekday > 5:
        asLuggageCost = 0;
        mountedCost = 1;
        reservationRequired = true;
        break;

      //Bretagne: Réservation obligatoire de 1€ de Mai à Septembre
      case commercial_mode.startsWith('TER') &&
        trainReference?.startsWith('85') &&
        DateTime.fromISO(departureDate).month > 4 &&
        DateTime.fromISO(departureDate).month < 10:
        asLuggageCost = 0;
        mountedCost = 1;
        reservationRequired = true;
        break;

      //All SNCF rules here: https://www.sncf-voyageurs.com/fr/voyagez-avec-nous/train-et-velo/votre-velo-a-bord/
      //Scooters ok if folded under 130x90x30cm do we need a new variable ?
      //Auvergne RA: -> jamais de réservation
      //Default TER rules
      case commercial_mode === 'Aléop TER':
      case commercial_mode.startsWith('TER'):
      case commercial_mode === 'TER':
      case commercial_mode === 'Transilien':
        asLuggageCost = 0;
        mountedCost = 0;
        avoidPeaktimes = true;
        break;

      case commercial_mode === 'Corbeil-Essonnes':
      case commercial_mode === 'Massy - Palaiseau':
      case commercial_mode === 'Poissy':
      case commercial_mode.startsWith('RER'):
      case commercial_mode === 'CityJet':
      case commercial_mode === 'C1':
      case commercial_mode === 'C2':
      case commercial_mode === 'C4a':
      case commercial_mode.startsWith('Cercanías'):
      case commercial_mode === 'REGIONAL':
      case commercial_mode === 'TRENCELTA':
      case commercial_mode === 'REG.EXP.':
      case commercial_mode === 'E2':
      case commercial_mode === 'AVANT':
      case commercial_mode === 'ALVIA':
      case commercial_mode === 'AVE':
      case commercial_mode === 'EUROMED':
      case commercial_mode === 'AVE INT':
      case commercial_mode === 'Intercity':
      case commercial_mode === 'IC3':
        asLuggageCost = 0;
        mountedCost = 0;
        ifSufficientSpace = true;
        break;

      case commercial_mode === 'LNER':
      case commercial_mode === 'Grand Central':
      case commercial_mode === 'Hull Trains':
      case commercial_mode === 'ScotRail':
      case commercial_mode === 'TPE':
      case commercial_mode === 'CrossCountry':
      case commercial_mode === 'E M R':
      case commercial_mode === 'Chiltern Railways':
      case commercial_mode === 'West Midlands Railway':
      case commercial_mode === 'Avanti West Coast':
        asLuggageCost = 0;
        mountedCost = 0;
        reservationRequired = true;
        break;

      //15 % du prix flexible de la 2nd Classe et au moins 7,50 euros et un maximum de 12,90 euros.
      //cf point 8.4.1 https://assets.static-bahn.de/dam/jcr:bfa9dde8-35e2-41f2-85ae-9b7cdc1e508e/Bef%C3%B6rderungsbedingungen%20der%20DB%20AG%20-%20Stand%2025.01.2024.pdf
      case commercial_mode.startsWith('ICE'):
      case commercial_mode === 'EC8':
      case commercial_mode === 'EC191':
      case commercial_mode === 'EC219':
      case commercial_mode === 'EC220':
      case commercial_mode === 'EC179':
      case commercial_mode === 'EC177':
      case commercial_mode === 'EC459':
      case commercial_mode === 'IC145':
      case commercial_mode === 'IC2374':
        asLuggageCost = 0;
        if (DBpassengerFares && DBpassengerFares.secondClass) {
          const cost = DBpassengerFares.secondClass * 0.15;
          mountedCost = Math.max(7.5, Math.min(cost, 12.9));
        } else {
          mountedCost = 7.5;
        }
        break;
      case commercial_mode === 'RE7':
      case commercial_mode === 'RE6':
      case commercial_mode === 'RB66':
      case commercial_mode === 'BRB RE5':
      case commercial_mode === 'RE80':
        asLuggageCost = 0;
        mountedCost = 6.5;
        break;
      case trainReference && ParisBarcelonaTGVIds.includes(trainReference):
        asLuggageCost = 0;
        break;
      case commercial_mode.startsWith('IR90'):
      case commercial_mode.startsWith('TGV'):
      case commercial_mode.startsWith('Intercités'):
      case commercial_mode === 'AVLO':
      case commercial_mode.startsWith('Railjet'):
        mountedCost = 10;
        asLuggageCost = 0;
        break;
      case commercial_mode === 'Nightjet':
      case commercial_mode === 'RailJet':
      case commercial_mode.startsWith('RJ'):
      case commercial_mode === 'RJX':
      case commercial_mode === 'EN':
      case commercial_mode === 'NJ':
      case commercial_mode === 'EuroNight':
      case commercial_mode === 'EC':
      case commercial_mode.startsWith('EN'):
      case commercial_mode === '40467':
        mountedCost = 3;
        asLuggageCost = 0;
        break;

      //Regional trains in Italy
      case commercial_mode === 'REG':
        mountedCost = 3.5;
        asLuggageCost = 0;
        break;

      case commercial_mode === 'Eurostar':
        if (trainReference && departureDate) {
          if (eurostarParisLondonAcceptingBikes.includes(trainReference)) {
            asLuggageCost = 52.5;
            reservationRequired = true;
            if (DateTime.fromISO(departureDate).diffNow().as('hours') < 48) {
              asLuggageCost = 70;
            }
          } else if (eurostarLondonBrusselAcceptingBikes.includes(trainReference)) {
            asLuggageCost = 52.5;
            mountedCost = 70;
            reservationRequired = true;
            if (DateTime.fromISO(departureDate).diffNow().as('hours') < 48) {
              asLuggageCost = 70;
              mountedCost = 87.5;
            }
          } else {
            nothingAvailable = true;
          }
          // Add more conditions for other rules
        }
        break;

      case commercial_mode === 'OUIGO':
        asLuggageCost = 5;
        mountedCost = 10;
        break;
      //Mid-Distancia (MD) is a type of train in Spain
      case commercial_mode === 'MD':
        asLuggageCost = 0;
        // On routes of more than 100 km, this ticket costs 3 euros and on routes of less than 100 km it is free
        if (distanceInMeters / 1000 > 100) {
          mountedCost = 3;
        } else {
          mountedCost = 0;
        }
        break;
      default:
        break;
    }
  }

  const bike: BikeAvailabilityType = {
    unavailable: nothingAvailable,
    mounted: {
      available: mountedCost != null,
      price: mountedCost ? toStringPrice(mountedCost) : null,
      avoidPeaktimes: avoidPeaktimes,
      ifSufficientSpace: ifSufficientSpace,
      reservationRequired: reservationRequired,
    },
    asLuggage: {
      available: asLuggageCost != null,
      price: asLuggageCost ? toStringPrice(asLuggageCost) : null,
    },
  };
  return bike;
};
