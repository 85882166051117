'use client';

import { PropsWithChildren, useEffect, useState } from 'react';

import { DateTime } from 'luxon';
import { useLocale, useTranslations } from 'next-intl';

import SkiStations from 'config/skiStationsIds.json';
import { getCheapestJourney } from 'utils';
import { PricedStation, Station } from 'utils/stations';

import AppPlaceCard, { AppPlaceCardSkeleton } from './AppPlaceCard';
import ResultsFilters from './ResultsFilters';
import { Button } from '../ui/button';
import SearchResultHeader from './SearchResultHeader';
import { MAXIMUM_CONNECTIONS } from './TicketCardsList';

interface IAdviseSectionProps extends PropsWithChildren<any> {
  stations: PricedStation[];
  originName: string;
  originStation: Station;
  transportMode: string;
  checkIn: Date;
  priceRange: Array<number>;
  durationRange: Array<number>;
  nightTrainMode: boolean;
  setNightTrainMode: (value: boolean) => void;
  skiMode?: boolean;
  setDestinationName: (value: string) => Promise<URLSearchParams>;
  setTransportMode: (value: string) => void;
  setPriceRange: (value: Array<number>) => void;
  setDurationRange: (value: Array<number>) => void;
  setSkiMode: (value: boolean) => Promise<URLSearchParams>;
}

export const PRICE_RANGE = [0, 499];
export const DEFAULT_PRICE_RANGE = [0, 400];
export const DURATION_RANGE = [0, 48];

const AdvisedStations = ({
  stations,
  originName,
  originStation,
  transportMode,
  checkIn,
  priceRange,
  durationRange,
  nightTrainMode,
  setNightTrainMode,
  skiMode,
  setDestinationName,
  setTransportMode,
  setPriceRange,
  setDurationRange,
  setSkiMode,
}: IAdviseSectionProps) => {
  const t = useTranslations('Advises');
  const locale = useLocale();
  const [filteredResults, setFilteredResults] = useState<PricedStation[]>([]);
  const [numberOfCards, setNumberOfCards] = useState<number>(14);
  const [connectionCountFilter, setConnectionCountFilter] =
    useState<number>(MAXIMUM_CONNECTIONS);

  useEffect(() => {
    const filtered = stations.filter((station) => {
      const cheapest = getCheapestJourney(
        station,
        transportMode,
        nightTrainMode,
        connectionCountFilter
      );

      return (
        cheapest?.amount &&
        cheapest.amount < priceRange[1] &&
        (skiMode ? SkiStations.stations.includes(station.id) : true) &&
        DateTime.fromISO(cheapest.arrival).diff(
          DateTime.fromISO(cheapest.departure),
          'hours'
        ).hours < durationRange[1]
      );
    });
    //sorted based on equal to country of originStation or not
    filtered.sort((a, b) => {
      if (a.country === originStation.country) {
        return 1;
      }

      return -1;
    });

    setFilteredResults(filtered);
  }, [
    stations,
    priceRange,
    durationRange,
    transportMode,
    nightTrainMode,
    skiMode,
    connectionCountFilter,
  ]);

  return (
    <section>
      <SearchResultHeader
        originName={originName[`info_${locale}`] || decodeURIComponent(originName)}
        checkIn={checkIn}
        prefix={t('title')}
      />
      {/* filters */}
      {stations?.length > 0 && (
        <div className="mb-4 flex flex-row gap-4 items-center justify-between overflow-x-auto">
          <span className="hidden text-sm text-gray-400 sm:block flex-nowrap whitespace-nowrap">
            {t('filters.title')}
          </span>
          <ResultsFilters
            setDurationRange={setDurationRange}
            durationRange={durationRange}
            setPriceRange={setPriceRange}
            priceRange={priceRange}
            setTransportMode={setTransportMode}
            transportMode={transportMode}
            skiMode={skiMode}
            setSkiMode={setSkiMode}
            filterMaxConnectionCount={connectionCountFilter}
            setFilterMaxConnectionCount={setConnectionCountFilter}
            nightTrainMode={nightTrainMode}
            setNightTrainMode={setNightTrainMode}
          />
        </div>
      )}
      {/* cards list */}
      <StationsList
        filteredResults={filteredResults}
        transportMode={transportMode}
        nightTrainMode={nightTrainMode}
        locale={locale}
        setDestinationName={setDestinationName}
        numberOfCards={numberOfCards}
      />
      {/* loading skeleton */}
      {stations.length === 0 && (
        <div className="grid grid-cols-1 gap-y-2 gap-x-4 md:grid-cols-2">
          {[...Array(10)].map((_, index) => (
            <AppPlaceCardSkeleton key={index} />
          ))}
        </div>
      )}
      {/* no results message */}
      {filteredResults.length === 0 && (
        <div className="flex flex-col items-center justify-center h-[50vh]">
          <p className="text-gray-400 text-2xl font-semibold">{t('noResults')}</p>
          <p className="text-gray-400 text-xl text-center font-semibold">
            {t('noResultsSubtitle')} <span className="hidden md:block">👉</span>
          </p>
        </div>
      )}
      {/* show more button */}
      {filteredResults.length > numberOfCards && (
        <div className="flex justify-center mt-3">
          <Button
            variant="link"
            className="text-primary font-semibold text-lg self-center text-center"
            onClick={() => setNumberOfCards(numberOfCards + 10)}
          >
            {t('seeMore')}
          </Button>
        </div>
      )}
    </section>
  );
};

const StationsList = ({
  filteredResults,
  transportMode,
  nightTrainMode,
  locale,
  setDestinationName,
  numberOfCards,
}: {
  filteredResults: PricedStation[];
  transportMode: string;
  nightTrainMode: boolean;
  locale: string;
  setDestinationName: (value: string) => Promise<URLSearchParams>;
  numberOfCards: number;
}) => {
  const resultsToDisplay = filteredResults.length > 0;
  return (
    <div className="grid grid-cols-1 gap-y-2 gap-x-4 md:grid-cols-2">
      {resultsToDisplay &&
        filteredResults.slice(0, numberOfCards).map((station) => {
          return (
            <AppPlaceCard
              key={station.name}
              station={station}
              locale={locale}
              cheapest={getCheapestJourney(station, transportMode, nightTrainMode)}
              onClick={() => {
                setDestinationName(station.name);
              }}
            />
          );
        })}
    </div>
  );
};

export default AdvisedStations;
