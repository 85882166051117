import { PricedQuoteDetails } from 'pages/api/collectUserInputs';
import { Leg } from './journey';

async function sendPricedQuote(
  email: string,
  quote: PricedQuoteDetails,
  comment: string,
  journeyLegs: Leg[]
) {
  await fetch('/api/sendPricedQuoteEmail', {
    method: 'POST',
    body: JSON.stringify({
      email: email,
      quote: quote,
      comment: comment,
      journeyLegs: journeyLegs,
    }),
  });
}

export { sendPricedQuote };
