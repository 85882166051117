export const EF_plane_data = {
  short: {
    construction: 0.00038,
    upstream: 0.0242,
    combustion: 0.117,
    infra: 0.0003,
  },
  medium: {
    construction: 0.00036,
    upstream: 0.0176,
    combustion: 0.0848,
    infra: 0.0003,
  },
  long: {
    construction: 0.00026,
    upstream: 0.0143,
    combustion: 0.0687,
    infra: 0.0003,
  },
};
export const cont_coeff = 3;
export const hold = 3.81; //kg/p
export const detour_default = 1.076;

//Train
export const searchPerimeter = [0.2, 0.5];
export const valPerimeter = 100;
export const EF_train_data = {
  construction: 0.0006,
  infra: 0.0065,
};
