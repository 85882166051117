'use client';

import { useEffect, useState } from 'react';

import { DateTime } from 'luxon';
import moment from 'moment';
import useSWR from 'swr';

import DateButtons from '@/components/atoms/DateButtons';
import SearchResultHeader from '@/components/atoms/SearchResultHeader';
import TicketCardsList from '@/components/atoms/TicketCardsList';
import { useDestinationName, useOriginName } from 'hooks/useQuerySearch';
import { getPublicEnvVariable } from 'lib/utilsClientSide';
import { DEFAULT_SORTING, filterAndLabelJourneys, sortJourneys } from 'utils';
import { Journey } from 'utils/journey';
import { RouteType } from 'utils/route';
import { Station } from 'utils/stations';

import { GetYourGuideWidget } from './GetYourGuideWidget';
import LineCalendar from './LineCalendar';
import LokkiRentalCard from './LokkiRentalCard';
import TransportModeSelector from './TransportModeSelector';
import { useTranslations } from 'next-intl';

interface Props {
  locale: string;
  originStation: Station;
  destinationStation: Station;
  route: RouteType | null;
  checkIn: Date;
  nightTrainMode: boolean;
  transportMode: string;
  setDestinationName: (value: string | null) => Promise<URLSearchParams>;
  setOriginName: (value: string) => Promise<URLSearchParams>;
  setCheckIn: (value: Date) => Promise<URLSearchParams>;
  setNightTrainMode: (value: boolean) => Promise<URLSearchParams>;
  setTransportMode: (value: string) => void;
}
export const securityAndWaitingTime = 1.75;
export const FLIGHT_DURATION_THRESHOLD = 0.6 + securityAndWaitingTime;

const SearchResult = ({
  locale,
  originStation,
  destinationStation,
  route,
  checkIn,
  nightTrainMode,
  transportMode,
  setDestinationName,
  setOriginName,
  setCheckIn,
  setNightTrainMode,
  setTransportMode,
}: Props) => {
  //TODO: see were this would belong
  DateTime.local().setLocale(locale);
  const t = useTranslations('Advises');

  const [originName] = useOriginName();
  const [destinationName] = useDestinationName();

  //TODO: hacky needs to be done better
  const [completeTransportMode, setCompleteTransportMode] = useState<string>(
    nightTrainMode ? 'nightTrain' : transportMode
  );
  const [sorting, setSorting] = useState(DEFAULT_SORTING);
  const [filteredJourneys, setFilteredJourneys] = useState<Journey[]>([]);
  const [sortedOfferList, setSortedOfferList] = useState<Journey[]>([]);
  const [dateToPrice, setDateToPrice] = useState<Array<[Date, number]>>([]);

  const { data, error, isValidating } = useSWR(
    () => {
      const params = new URLSearchParams();
      params.append('o', originStation.id.toString());
      params.append('d', destinationStation.id.toString());
      params.append('date', checkIn?.toISOString());
      params.append('nightservices', nightTrainMode ? 'true' : 'false');

      return `/api/getEUResults?${params.toString()}`;
    },
    async (url) => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`This is an HTTP error: The status is ${response.status}`);
        }
        return await response.json();
      } catch (err) {
        console.error(err);
      }
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenHidden: false,
      refreshWhenOffline: true,
    }
  );

  useEffect(() => {
    if (!isValidating && data && !error) {
      const journeys = data.journeys as Journey[];

      setFilteredJourneys(
        filterAndLabelJourneys(journeys, transportMode, nightTrainMode)
      );
    } else {
      setFilteredJourneys([]);
    }
  }, [data, transportMode]);

  useEffect(() => {
    const newOfferList = sortJourneys(filteredJourneys, sorting);
    setSortedOfferList(newOfferList);
  }, [filteredJourneys, sorting]);

  const getLineCalendarRange = (currentCheckIn: Date): Date[] => {
    const isCheckInToday = currentCheckIn.toDateString() === new Date().toDateString();
    const start = isCheckInToday ? 0 : -1;
    const end = isCheckInToday ? 5 : 4;

    const startDate = new Date(currentCheckIn);
    startDate.setDate(currentCheckIn.getDate() + start);

    const endDate = new Date(currentCheckIn);
    endDate.setDate(currentCheckIn.getDate() + end);
    return [startDate, endDate];
  };

  const [lineCalendarRange, setLineCalendarRange] = useState<Date[]>(
    getLineCalendarRange(checkIn)
  );

  useEffect(() => {
    setLineCalendarRange(getLineCalendarRange(checkIn));
  }, [checkIn]);

  const {
    data: priceCalendar,
    error: errorCalendar,
    isValidating: isValidatingCalendar,
  } = useSWR(
    `/api/getDailyFares?startStationId=${originStation.id}&endStationId=${
      destinationStation.id
    }&firstdate=${lineCalendarRange[0].toISOString()}&lastdate=${lineCalendarRange[1].toISOString()}`,
    async (url) => {
      try {
        const response = await fetch(url, { method: 'GET' });
        if (!response.ok) {
          throw new Error(`This is an HTTP error: The status is ${response.status}`);
        }

        return await response.json();
      } catch (err) {
        console.error(err);
      }
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenHidden: false,
      refreshWhenOffline: false,
      revalidateOnMount: true,
      shouldRetryOnError: false,
      errorRetryCount: 2,
    }
  );

  useEffect(() => {
    if (!isValidatingCalendar && priceCalendar && !errorCalendar) {
      const typedPriceCalendar = priceCalendar as Record<string, Record<string, number>>;
      const [startDate, endDate] = lineCalendarRange;

      const newDateToPrice = [] as Array<[Date, number]>;
      const currentDate = new Date(startDate);

      while (currentDate < endDate) {
        const formattedDate = moment(currentDate).format('YYYY-MM-DD');
        const priceForDate: number | undefined =
          typedPriceCalendar[formattedDate]?.[completeTransportMode];
        newDateToPrice.push([new Date(currentDate), priceForDate ?? Infinity]);

        currentDate.setDate(currentDate.getDate() + 1);
      }

      setDateToPrice(newDateToPrice);
    }
  }, [
    completeTransportMode,
    priceCalendar,
    errorCalendar,
    isValidatingCalendar,
    lineCalendarRange,
    transportMode,
  ]);

  useEffect(() => {
    const newCompleteMode = nightTrainMode ? 'nightTrain' : transportMode;
    setCompleteTransportMode(newCompleteMode);
  }, [nightTrainMode, transportMode]);

  if (error) {
    return <div>failed to load</div>;
  }

  return (
    <>
      <SearchResultHeader
        originName={originStation[`info_${locale}`] || decodeURIComponent(originName)}
        destinationName={
          destinationStation[`info_${locale}`] || decodeURIComponent(destinationName)
        }
        handleOnBack={async () => {
          await setDestinationName(null);
        }}
        handleOnReverse={() => {
          setDestinationName(originName);
          setOriginName(destinationName);
        }}
        checkIn={checkIn}
        prefix={t('itineraries')}
        transportMode={transportMode}
      />

      <TransportModeSelector
        selectedTransportMode={completeTransportMode}
        setTransportMode={setTransportMode}
        isLoading={isValidating}
        journeys={data?.journeys}
        route={route}
      />

      <LineCalendar
        dateToPrice={dateToPrice}
        checkIn={checkIn}
        setCheckIn={setCheckIn}
        isLoading={isValidating}
        hide={transportMode == 'plane'}
      />

      <TicketCardsList
        originName={originName}
        destinationName={destinationName}
        sortedOfferList={sortedOfferList}
        sortBy={sorting}
        transportMode={transportMode}
        nightTrainMode={nightTrainMode}
        isLoading={isValidating}
        setSorting={setSorting}
        setNightTrainMode={setNightTrainMode}
      />

      <DateButtons setCheckIn={setCheckIn} checkIn={checkIn} />

      {sortedOfferList[0] &&
        getPublicEnvVariable(
          process.env.NEXT_PUBLIC_FEATURE_FLAG_LOKKI,
          'NEXT_PUBLIC_FEATURE_FLAG_LOKKI'
        ) == 'true' && (
          <LokkiRentalCard destinationStation={destinationStation} checkIn={checkIn} />
        )}
      {sortedOfferList.length !== 0 && (
        <GetYourGuideWidget destination={destinationName} locale={locale} />
      )}
    </>
  );
};

export default SearchResult;
