import { RouteType } from 'utils/route';
import { EF_train_data } from './params';

import { length as turfLength } from '@turf/turf';
import { annotateRouteWithCountriesEmissionFactor } from './utils';

export type TrainEmissions = {
  countryCode: string;
  distanceTravelledInCountry: number;
  countryFactor: number;
  totalEmissionsKg: number;
};

export default async function getTrainEmissionsEurope(
  route: RouteType
): Promise<TrainEmissions[] | null> {
  let fullRouteLength: number;
  try {
    fullRouteLength = turfLength(route, { units: 'kilometers' });
  } catch (e) {
    console.log('could not compute train route length');
    return null;
  }

  let routeWithEmissions = annotateRouteWithCountriesEmissionFactor(route, 'train');

  let annotatedRouteLength = routeWithEmissions
    .map((pair) => pair[1])
    .reduce((acc, current) => acc + current, 0);

  // Account for potentially missed segments because of sea crossing for example
  const rescalingFactor = fullRouteLength / annotatedRouteLength;

  let totalEmissions: TrainEmissions[] = [];

  // Compute propulsion related emissions
  routeWithEmissions.forEach(([countryData, distanceTravelledInCountry]) => {
    const globalCountryFactor = countryData!['EF_tot'] / 1000 + EF_train_data.infra;
    const adjustedDistance = rescalingFactor * distanceTravelledInCountry;

    const totalEmissionsKg = globalCountryFactor * adjustedDistance;
    const countryEmission = {
      countryCode: countryData!['ISO2'],
      distanceTravelledInCountry,
      countryFactor: globalCountryFactor,
      totalEmissionsKg: totalEmissionsKg,
    };

    totalEmissions.push(countryEmission);
  });

  return totalEmissions;
}
