import BlaBlaCarIcon from '../public/assets/icons/blablacar.svg';
import CaledonianSleeperIcon from '../public/assets/icons/caledonian-sleeper.svg';
import EuropeanSleeperIcon from '../public/assets/icons/European_Sleeper_Logo.svg';
import OUIGOIcon from '../public/assets/icons/logo-ouigo.svg';
import SNCFConnectIcon from '../public/assets/icons/sncf_connect.com.svg';

const operatorCTAName = {
  db: 'Deutsche Bahn',
  flix: 'FlixBus.com',
  FlixTrain: 'Flixbus.com',
  obb: 'Oebb.at',
  snalltaget: 'Snalltaget.com',
  eurolines: 'Eurolines.com',
  ecolines: 'Ecolines.com',
  comboios: 'Comboios.pt',
  elron: 'Elron.ee',
  lux: 'LuxExpress.com',
  blablabus: 'BlaBlaBus.com',
  BlaBlaCar: 'Blablacar.com',
  blablacar: 'Blablacar.com',
  mav: 'Mav',
  'LTG Link': 'LTG Link',
  google: 'Trainline.com',
  directionEngine: 'Trainline.com',
  SNCF: 'sncf-connect',
  sncf: 'sncf-connect',
  FlixBus: 'Flixbus.com',
  flixbus: 'Flixbus.com',
  'STARSHIPPER SAS': 'FlixBus.com',
  'Österreichische Bundesbahnen': 'Oebb.at',
  Trenitalia: 'Trenitalia.com',
  europeansleeper: 'Europeansleeper',
  caledoniansleeper: 'sleeper.scot',
  GWR: 'GWR.com',
  ouigo: 'ouigo.com',
};
const operatorWebsiteName = (source: string, link: string) => {
  if (source === 'sncf' && link.includes('ouigo.com')) {
    return operatorCTAName.ouigo;
  } else {
    return operatorCTAName[source];
  }
};
const operatorIcons = (source: string, link: string): JSX.Element | null => {
  if (source === 'sncf' && link.includes('ouigo.com')) {
    source = 'OUIGO';
  }

  switch (source) {
    case 'sncf':
      return <SNCFConnectIcon className="mr-3 h-3.5" />;
    case 'OUIGO':
      return <OUIGOIcon className="mr-3 h-5" />;
    case 'BlaBlaCar':
      return <BlaBlaCarIcon className="mr-3 h-4 w-4" />;
    case 'European Sleeper':
      return <EuropeanSleeperIcon className="mr-3 h-3 fill-white text-white" />;
    case 'Caledonian Sleeper':
      return <CaledonianSleeperIcon className="mr-3 h-4 -mt-1.5 fill-white text-white" />;
    default:
      return null;
  }
};

export { operatorCTAName, operatorIcons, operatorWebsiteName };
