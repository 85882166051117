'use client';

import { useEffect, useState } from 'react';

import { useTranslations } from 'next-intl';

import { Journey } from 'utils/journey';

import InterrailCard from './InterrailCard';
import JourneySubmissionCard from './JourneySubmissionCard';
import NightTrainToggle from './NightTrainToggle';
import SortByToogle from './SortByToogle';
import TicketCard from './TicketCard';
import TicketCardLoading from './TicketCardLoading';
import DirectTrainsToggle from './DirectTrainsToggle';
import { countTrainTravelLegs, SortingType } from 'utils';
import { ConnexionsFilter } from './ResultsFilters';
import { AnimatePresence, motion } from 'framer-motion';

export const MAXIMUM_CONNECTIONS = 11;
const CONNECTIONS_THRESHOLD_UI_DIRECT = 2;

interface Props {
  originName: string;
  destinationName: string;
  sortedOfferList: Journey[];
  sortBy: SortingType[];
  transportMode: string;
  nightTrainMode?: boolean;
  isLoading: boolean;
  setSorting: (value: SortingType[]) => void;
  setNightTrainMode: (value: boolean) => Promise<URLSearchParams>;
}

const TicketCardsList = ({
  originName,
  destinationName,
  sortedOfferList,
  sortBy,
  transportMode,
  nightTrainMode,
  isLoading,
  setSorting,
  setNightTrainMode,
}: Props) => {
  const [filterDirectJourneysOnly, setFilterDirectJourneysOnly] =
    useState<boolean>(false);
  const [hasNightTrainJourneys, setHasNightTrainJourneys] = useState<boolean>(false);
  const [connectionCountOptions, setConnectionCountOptions] = useState<Array<number>>([
    0,
    MAXIMUM_CONNECTIONS,
  ]);
  const [filterMaxConnectionCount, setFilterMaxConnectionCount] =
    useState<number>(MAXIMUM_CONNECTIONS);
  const [filteredJourneys, setFilteredJourneys] = useState<Journey[]>([]);
  const t = useTranslations('TicketList');

  const TrainFilters = () => {
    const displayDirectFilter =
      connectionCountOptions[0] === 0 &&
      connectionCountOptions[1] === 1 &&
      connectionCountOptions[1] < CONNECTIONS_THRESHOLD_UI_DIRECT;

    const displayFullConnexionsFilter =
      connectionCountOptions[1] >= CONNECTIONS_THRESHOLD_UI_DIRECT;

    const displayNightTrainFilter = hasNightTrainJourneys || nightTrainMode;

    const someFiltersDisplayed =
      displayDirectFilter || displayFullConnexionsFilter || displayNightTrainFilter;

    return (
      <div className="flex flex-row items-center gap-1.5 justify-start">
        {transportMode == 'train' && (
          <>
            {someFiltersDisplayed && (
              <span className="hidden text-sm mr-1 text-gray-400 sm:block flex-nowrap whitespace-nowrap">
                {t('filtering')}
              </span>
            )}
            {displayNightTrainFilter && (
              <NightTrainToggle
                onClick={() => setNightTrainMode(!nightTrainMode)}
                nightTrainMode={nightTrainMode}
              />
            )}
            {displayDirectFilter && (
              <DirectTrainsToggle
                directJourneys={filterDirectJourneysOnly}
                setDirectJourneys={setFilterDirectJourneysOnly}
                size="selector"
              />
            )}
            {displayFullConnexionsFilter && (
              <ConnexionsFilter
                filterMaxConnectionCount={filterMaxConnectionCount}
                setFilterMaxConnectionCount={setFilterMaxConnectionCount}
                connectionCountOptions={connectionCountOptions}
              />
            )}
          </>
        )}
      </div>
    );
  };

  useEffect(() => {
    const filteredOffers = sortedOfferList.filter((offer: any) => {
      if (transportMode !== 'train') return true;

      const trainLegsCount = countTrainTravelLegs(offer.legs);
      const isDirectJourney = trainLegsCount === 1;
      return !(
        (filterDirectJourneysOnly && !isDirectJourney) ||
        (filterMaxConnectionCount !== undefined &&
          trainLegsCount > filterMaxConnectionCount + 1)
      );
    });
    setFilteredJourneys(filteredOffers);
  }, [
    filterDirectJourneysOnly,
    sortedOfferList,
    transportMode,
    filterMaxConnectionCount,
  ]);

  useEffect(() => {
    // Reset filters when destination changes
    setFilterDirectJourneysOnly(false);
    setFilterMaxConnectionCount(MAXIMUM_CONNECTIONS);

    const hasNightTrainJourneys = sortedOfferList.some(
      (offer: Journey) => offer.nighttrain
    );
    setHasNightTrainJourneys(hasNightTrainJourneys);

    const connexionCounts = sortedOfferList.map(
      (offer: Journey) => countTrainTravelLegs(offer.legs) - 1
    );

    const minConnexions = Math.min(...connexionCounts);
    const maxConnexions = Math.max(...connexionCounts);

    setConnectionCountOptions([minConnexions, maxConnexions]);
    setFilterMaxConnectionCount(maxConnexions);
  }, [sortedOfferList, destinationName]);

  if (transportMode == 'plane') {
    return null;
  }
  if (!isLoading && sortedOfferList[0] == null) {
    return (
      <section className="pt-2">
        <div className="py-6 block overflow-hidden bg-orange/10 rounded-xl pl-6 pr-6">
          {t('noResultsDescription')}
        </div>
      </section>
    );
  }

  if (isLoading) {
    return (
      <section className="pt-2">
        <p className="mb-4 flex animate-pulse items-center justify-center">
          {t('loading')}
        </p>
        {[1, 2, 3, 4, 5].map((key: number) => (
          <TicketCardLoading transportMode={transportMode} key={key} />
        ))}
      </section>
    );
  } else {
    return (
      <>
        <div className="flex flex-row items-center scrollbar-none justify-between overflow-x-auto gap-2.5">
          <TrainFilters />

          <SortByToogle sorting={sortBy} setSorting={setSorting} />
        </div>

        {!isLoading && filteredJourneys.length == 0 && sortedOfferList[0] != null && (
          <p className="flex animate-pulse items-center justify-center py-5">
            {t('noResultsWithFilters')}
          </p>
        )}

        <section className="pt-2 @container">
          <AnimatePresence>
            {filteredJourneys.map((offer: Journey, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                transition={{ duration: 0.5 }}
                layout
              >
                <TicketCard key={index} offer={offer} />
              </motion.div>
            ))}
          </AnimatePresence>

          {sortedOfferList[0] &&
            transportMode == 'train' &&
            !nightTrainMode &&
            connectionCountOptions[0] > 0 &&
            !filterDirectJourneysOnly && (
              <InterrailCard
                key="interrail"
                transportMode={transportMode}
                passNeededDays={4}
              />
            )}

          {transportMode == 'train' && connectionCountOptions[0] > 0 && (
            <JourneySubmissionCard
              key="cooperative"
              originName={originName}
              destinationName={destinationName}
            />
          )}
        </section>
      </>
    );
  }
};

export default TicketCardsList;
