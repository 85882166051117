'use client';

import { useEffect, useState } from 'react';

import { DialogClose } from '@radix-ui/react-dialog';
import { format } from 'date-fns';
import { CalendarIcon, CrossIcon, UserIcon } from 'lucide-react';
import { useTranslations } from 'next-intl';

import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { cn } from 'lib/utilsClientSide';

import { Button } from '../ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog';
import { Label } from '../ui/label';
import { toast } from '../ui/use-toast';
import { SelectTravelers } from './AppSearchOption';
import {
  useGuestSeniors,
  useGuestAdults,
  useGuestYouths,
  useGuestChildren,
  useGuestBikes,
  useGuestInfants,
  useCheckIn,
} from 'hooks/useQuerySearch';
import { sendPricedQuote } from 'utils/sendPricedQuote';
import { Input } from '../ui/input';
import { Textarea } from '../ui/textarea';
import { Journey } from 'utils/journey';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import { filterAndLabelJourneys, priceWithCom } from 'utils/sortingUtils';
import { Session } from 'next-auth';
import { isAdmin } from 'utils/auth';
import TicketCardsList from './TicketCardsList';
import { getCabinDetails } from 'lib/nightTrainsCabinDetails';
import AppJourneyLegs from './AppJourneyLegs';

const DEFAULT_COMMISSION_RATE = 9;

const PricedQuoteDialogForAdmins = ({
  offer,
  session,
}: {
  offer: Journey;
  session: Session;
}) => {
  const [openQuoteDrawer, setOpenQuoteDrawer] = useState<boolean>(false);
  const [guestsSeniors] = useGuestSeniors();
  const [guestsAdults] = useGuestAdults();
  const [guestsYouths] = useGuestYouths();
  const [guestsChildren] = useGuestChildren();
  const [guestsInfants] = useGuestInfants();
  const [guestsBikes] = useGuestBikes();
  const [returnOffers, setReturnOffers] = useState<Journey[]>([]);
  const transportMode = 'train';
  const nightTrainMode = false;

  const [checkIn] = useCheckIn();
  const [showReturnDate, setShowReturnDate] = useState(false);
  const [openReturnDatePopover, setOpenReturnDatePopover] = useState(false);
  const [departureDate, setDepartureDate] = useState<Date | undefined>(checkIn);
  const [returnDate, setReturnDate] = useState<Date | undefined>(undefined);
  const t = useTranslations('Tickets');
  const travelersTranslation = useTranslations('Header.search.travelers');
  const cabins = offer.legs
    .map((leg) => {
      const { cabins } = getCabinDetails(leg.line);
      return cabins || [];
    })
    .flat()
    .filter(Boolean);
  const availableCabinTypes = cabins?.map((cabin) => cabin.type) || [];

  const personalMessageToCustomer = `Voici votre demande de réservation pour votre voyage ${
    offer.destination && 'à ' + offer.destination
  }. Vous pouvez la régler en ligne via le lien ci-dessous.`;

  const [cabinType, setCabinType] = useState<string>(availableCabinTypes[0]);
  const [price, setPrice] = useState<number | null>(offer.amount);
  const [commissionRate, setCommissionRate] = useState<number>(DEFAULT_COMMISSION_RATE);
  const [comment, setComment] = useState<string>(personalMessageToCustomer);
  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');

  const validateSubmission = () => {
    return email && departureDate && price != null && isAdmin(session);
  };

  const handleQuoteRequest = async () => {
    if (validateSubmission()) {
      await sendPricedQuote(
        email,
        {
          origin: offer.origin,
          destination: offer.destination,
          departureDate: departureDate || checkIn,
          returnDate: returnDate,
          adults: guestsAdults,
          youth: guestsYouths,
          children: guestsChildren,
          infant: guestsInfants,
          bike: guestsBikes,
          cabinType,
          commissionRateInPercent: commissionRate,
          price: price || 0,
          firstName,
        },
        comment,
        offer.legs
      );
      toast({
        title: 'Devis envoyé avec succès',
        description: 'Esperons que le client soit satisfait',
        duration: 5000,
      });
    } else {
      toast({
        title: t('Quote.Dialog.toastError.title'),
        description: t('Quote.Dialog.toastError.subtitle'),
        duration: 5000,
      });
    }
  };

  const computeTotalTravelers = () =>
    guestsAdults + guestsYouths + guestsChildren + guestsInfants + guestsSeniors;

  useEffect(() => {
    const fetchStations = async () => {
      if (openQuoteDrawer) {
        const originStationResponse = await fetch(
          `/api/getCachedStation?name=${offer.origin}`
        );
        const originStation = await originStationResponse.json();

        const destinationStationResponse = await fetch(
          `/api/getCachedStation?name=${offer.destination}`
        );
        const destinationStation = await destinationStationResponse.json();

        const euResultsResponse = await fetch(
          `/api/getEUResults?o=${destinationStation[0].id}&d=${originStation[0].id}&date=${checkIn?.toISOString()}&nightservices=${nightTrainMode ? 'true' : 'false'}`
        );
        const data = await euResultsResponse.json();
        console.log(data);
        if (data) {
          const journeys = data.journeys as Journey[];
          setReturnOffers(
            filterAndLabelJourneys(journeys, transportMode, nightTrainMode)
          );
        }

        return () => {
          setReturnOffers([]);
        };
      }
    };
    fetchStations();
  }, [openQuoteDrawer]);

  if (!isAdmin(session)) {
    return null;
  }

  return (
    <Dialog onOpenChange={() => setOpenQuoteDrawer(!openQuoteDrawer)}>
      <DialogTrigger asChild>
        <Button
          id="button-quote"
          size="lg"
          variant="confirmative"
          onClick={() => setOpenQuoteDrawer(true)}
          className="peer mb-1.5 max-sm:mt-2 w-fit font-bold"
        >
          Generate invoice
        </Button>
      </DialogTrigger>
      <DialogContent className="overflow-y-scroll scrollbar-thin border-2 border-primary w-fit !max-w-screen-xl max-h-[90vh]">
        <DialogHeader>
          <DialogTitle>
            Send an invoice to a customer
            <br />
          </DialogTitle>

          <DialogDescription className="pb-5">
            This will send an email to the customer with the invoice.
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col-reverse gap-y-3 md:flex-row overflow-hidden">
          <div>
            <div className="flex flex-col items-start md:items-start gap-4 md:justify-center">
              <div className="flex flex-col gap-1">
                <Label>{t('Quote.Dialog.travelers')}</Label>

                {/* TODO: merge this modal with the other component*/}
                <Popover modal>
                  <PopoverTrigger asChild>
                    <Button
                      name="panel"
                      value="passengers"
                      type="button"
                      variant="outline"
                      className="shadow-sm w-full"
                      size="selector"
                    >
                      <p className="flex flex-row items-center w-full">
                        <UserIcon className="w-[1.1rem] -mt-0.5 mr-1.5" />
                        {computeTotalTravelers()}{' '}
                        {travelersTranslation('title', {
                          count: computeTotalTravelers(),
                        })}
                      </p>
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto" align="start">
                    <SelectTravelers displayTitle={false} />
                  </PopoverContent>
                </Popover>
              </div>
              <div className="flex gap-3 flex-row  items-end md:flex-row">
                <div className="flex flex-col gap-1">
                  <Label>{t('Quote.Dialog.departureDate')}</Label>
                  <Popover modal>
                    <PopoverTrigger asChild>
                      <Button
                        variant={'outline'}
                        className={cn(
                          'w-[166px] pl-3 text-left font-normal rounded-full',
                          !departureDate && 'text-muted-foreground'
                        )}
                      >
                        {departureDate ? (
                          format(departureDate, 'dd MMM yyyy')
                        ) : (
                          <span>{t('Quote.Dialog.pickAdate')}</span>
                        )}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        selected={departureDate}
                        onSelect={setDepartureDate}
                        disabled={(date) => date < new Date()}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                </div>
                {showReturnDate ? (
                  <div className="flex flex-row gap-2 items-end">
                    <div className="flex flex-col gap-1">
                      <Label>{t('Quote.Dialog.returnDate')}</Label>
                      <Popover modal open={openReturnDatePopover}>
                        <PopoverTrigger
                          asChild
                          onClick={() => setOpenReturnDatePopover(true)}
                        >
                          <Button
                            variant={'outline'}
                            className={cn(
                              'w-[166px] pl-3 text-left font-normal rounded-full',
                              !returnDate && 'text-muted-foreground'
                            )}
                          >
                            {returnDate ? (
                              format(returnDate, 'dd MMM yyyy')
                            ) : (
                              <span>{t('Quote.Dialog.pickAdate')}</span>
                            )}
                            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                          <Calendar
                            mode="single"
                            selected={returnDate}
                            onSelect={(date) => {
                              setReturnDate(date);
                              setOpenReturnDatePopover(false);
                            }}
                            disabled={(date) => date < (departureDate || new Date())}
                            defaultMonth={departureDate}
                            fromDate={departureDate}
                          />
                        </PopoverContent>
                      </Popover>
                    </div>
                    <Button
                      variant="secondary"
                      size="icon"
                      onClick={() => setShowReturnDate(false)}
                    >
                      <CrossIcon className="h-4 w-4" />
                    </Button>
                  </div>
                ) : (
                  <Button
                    variant="link"
                    onClick={() => {
                      setShowReturnDate(true);
                      setOpenReturnDatePopover(true);
                    }}
                    className="mt-2 text-orange"
                  >
                    {t('Quote.Dialog.addReturnDate')}
                  </Button>
                )}
              </div>

              <div className="grid gap-4 w-full grid-cols-2 max-w-screen-xs">
                <div className="flex flex-col gap-1 min-w-32">
                  <Label>First Name</Label>
                  <Input
                    type="text"
                    placeholder="Customer's first name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                {availableCabinTypes.length > 0 && (
                  <div className="flex flex-col gap-1 min-w-32">
                    <Label>Cabin Type</Label>
                    <Select value={cabinType} onValueChange={setCabinType}>
                      <SelectTrigger>
                        <SelectValue placeholder="Cabin type" />
                      </SelectTrigger>
                      <SelectContent>
                        {availableCabinTypes.map((type) => (
                          <SelectItem key={type} value={type}>
                            {type}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                )}
                <div className="flex flex-col gap-1 min-w-32">
                  <Label>Price A/R for {computeTotalTravelers()}</Label>
                  <Input
                    type="number"
                    placeholder="price A/R"
                    value={price || ''}
                    onChange={(e) => setPrice(Number(e.target.value))}
                  />
                </div>

                <div className="flex flex-col gap-1 min-w-32">
                  <Label>Commission (%)</Label>
                  <Input
                    type="number"
                    placeholder="Commission"
                    value={commissionRate}
                    onChange={(e) => setCommissionRate(Number(e.target.value))}
                  />
                </div>
              </div>
              <p className="text-orange">
                Total price: {priceWithCom(price || 0, commissionRate)}€
              </p>

              <div className="flex flex-col gap-1 w-full">
                <Label>Personal message to the customer</Label>
                <Textarea
                  placeholder="message to the customer"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </div>
              <div className="flex flex-col gap-1 w-full">
                <Label>Customer Email</Label>
                <div className="flex items-center">
                  <Input
                    type="email"
                    placeholder="customer@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="flex-grow"
                  />
                  {email === '' && (
                    <Button
                      onClick={() => setEmail(session.user?.email || '')}
                      className="ml-2"
                      variant="link"
                    >
                      Send to me
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <DialogClose
              className="self-end mt-4"
              onClick={async () => await handleQuoteRequest()}
              disabled={!validateSubmission()}
            >
              <Button
                id="send-quote-btn"
                variant="confirmative"
                disabled={!validateSubmission()}
              >
                Send priced quote
              </Button>
            </DialogClose>
          </div>
          <div className="border-l ml-10 border-slate-500 h-full md:block hidden"></div>
          <div className="border-b my-3 border-slate-500 w-full md:hidden"></div>
          <div className="px-7 overflow-x-hidden w-full md:w-[420px] h-full">
            <Label className="font-semibold text-orange pb-8">
              {offer.destination}-&gt;{offer.origin}
            </Label>
            <AppJourneyLegs journeyLegs={offer.legs} />
            <Label className="font-semibold text-orange pb-8">
              Return : {offer.destination}-&gt;{offer.origin}
            </Label>
            <TicketCardsList
              originName={offer.origin}
              destinationName={offer.destination}
              sortedOfferList={returnOffers}
              sortBy={['departure', 'connections']}
              transportMode="train"
              nightTrainMode={false}
              isLoading={returnOffers === null}
              setSorting={() => null}
              setNightTrainMode={() => Promise.resolve(new URLSearchParams())}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default PricedQuoteDialogForAdmins;
