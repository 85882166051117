import { useTranslations } from 'next-intl';

import { Button } from '../ui/button';

interface Props {
  onClick: () => void;
  nightTrainMode?: boolean;
}

const NightTrainToggle = ({ onClick, nightTrainMode }: Props) => {
  const t = useTranslations('Tickets.filtering');

  return (
    <Button
      variant={nightTrainMode ? 'default' : 'outline'}
      className="min-w-fit"
      onClick={onClick}
      id="nightTrainMode"
    >
      {t('night-train.toogle')}
    </Button>
  );
};

export default NightTrainToggle;
