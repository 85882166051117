'use client';

import { useState } from 'react';

import { DialogClose } from '@radix-ui/react-dialog';
import { format } from 'date-fns';
import { CalendarIcon, ChevronRightIcon, UserIcon } from 'lucide-react';
import Image from 'next/image';
import { useSession } from 'next-auth/react';
import { useTranslations } from 'next-intl';

import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { cn } from 'lib/utilsClientSide';
import { collectQuote } from 'utils/collectUserInputs';

import { Button } from '../ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog';
import { Label } from '../ui/label';
import { toast } from '../ui/use-toast';
import { SelectTravelers } from './AppSearchOption';
import {
  useGuestSeniors,
  useGuestAdults,
  useGuestYouths,
  useGuestChildren,
  useGuestBikes,
  useGuestInfants,
  useCheckIn,
  useCheckOut,
} from 'hooks/useQuerySearch';
import { Session } from 'next-auth';

const QuoteDialog = ({
  origin,
  destination,
  journeyLegs,
  journeyId,
  session,
}: {
  origin: string;
  destination: string;
  journeyLegs: any;
  journeyId: string;
  session: Session;
}) => {
  const [openQuoteDrawer, setOpenQuoteDrawer] = useState<boolean>(false);
  const [guestsSeniors] = useGuestSeniors();
  const [guestsAdults] = useGuestAdults();
  const [guestsYouths] = useGuestYouths();
  const [guestsChildren] = useGuestChildren();
  const [guestsInfants] = useGuestInfants();
  const [guestsBikes] = useGuestBikes();
  const [cabinType, setCabinType] = useState<string>('couchette');

  const [checkIn] = useCheckIn();
  const [showReturnDate, setShowReturnDate] = useState(false);
  const [openReturnDatePopover, setOpenReturnDatePopover] = useState(false);

  const [departureDate, setDepartureDate] = useState<Date | undefined>(checkIn);
  const [returnDate, setReturnDate] = useState<Date | undefined>(undefined);
  const t = useTranslations('Tickets');
  const travelersTranslation = useTranslations('Header.search.travelers');

  const handleQuoteRequest = async () => {
    const email = session?.user?.email;
    if (email && departureDate) {
      await collectQuote(email, {
        origin,
        destination,
        journeyId,
        departureDate: departureDate,
        returnDate: returnDate,
        adults: guestsAdults,
        youth: guestsYouths,
        children: guestsChildren,
        infant: guestsInfants,
        bike: guestsBikes,
        cabinType,
      });
      toast({
        title: t('Quote.Dialog.toastSuccess.title'),
        description: t('Quote.Dialog.toastSuccess.subtitle'),
        duration: 5000,
      });
    } else {
      toast({
        title: t('Quote.Dialog.toastError.title'),
        description: t('Quote.Dialog.toastError.subtitle'),
        duration: 5000,
      });
    }
  };

  const computeTotalTravelers = () =>
    guestsAdults + guestsYouths + guestsChildren + guestsInfants + guestsSeniors;

  return (
    <Dialog onOpenChange={() => setOpenQuoteDrawer(!openQuoteDrawer)}>
      <DialogTrigger asChild>
        <button
          id="button-quote"
          onClick={() => setOpenQuoteDrawer(true)}
          className="peer mb-1.5 max-sm:mt-2 w-fit flex self-center text-nowrap group/button items-center text-sm justify-center cursor-pointer rounded-full bg-primary px-8 py-3 h-11 sm:py-2 font-bold text-white hover:opacity-90"
        >
          {t('Quote.btn')}
          <ChevronRightIcon className="h-5 w-5 ml-2 -mr-4 hidden md:group-hover/button:block" />
        </button>
      </DialogTrigger>
      <DialogContent className="border-2 border-primary w-fit">
        <DialogHeader>
          <DialogTitle>
            {t('Quote.Dialog.title', {
              departure: origin.toString(),
              arrival: destination.toString(),
            })}{' '}
            <br />
          </DialogTitle>
          <Image
            src="/assets/founders/Marc.jpg"
            alt="profile picture"
            width={44}
            height={44}
            className="rounded-full absolute -top-12 md:top-12 right-1/2 md:right-8 max-md:translate-x-1/2"
          />

          <DialogDescription className="pb-5">
            {t('Quote.Dialog.subtitle')} <br />
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col items-center md:items-start gap-3 md:justify-center">
          <div className="flex flex-col gap-1">
            <Label>{t('Quote.Dialog.travelers')}</Label>

            {/* TODO: merge this modal with the other component*/}
            <Popover modal>
              <PopoverTrigger asChild>
                <Button
                  name="panel"
                  value="passengers"
                  type="button"
                  variant="outline"
                  className="shadow-sm w-full"
                  size="selector"
                >
                  <p className="flex flex-row items-center w-full">
                    <UserIcon className="w-[1.1rem] -mt-0.5 mr-1.5" />
                    {computeTotalTravelers()}{' '}
                    {travelersTranslation('title', {
                      count: computeTotalTravelers(),
                    })}
                  </p>
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto" align="start">
                <SelectTravelers displayTitle={false} />
              </PopoverContent>
            </Popover>
          </div>
          <div className="flex gap-3 flex-col items-end md:flex-row">
            <div className="flex flex-col gap-1">
              <Label>{t('Quote.Dialog.departureDate')}</Label>
              <Popover modal>
                <PopoverTrigger asChild>
                  <Button
                    variant={'outline'}
                    className={cn(
                      'w-[200px] pl-3 text-left font-normal rounded-full',
                      !departureDate && 'text-muted-foreground'
                    )}
                  >
                    {departureDate ? (
                      format(departureDate, 'dd MMM yyyy')
                    ) : (
                      <span>{t('Quote.Dialog.pickAdate')}</span>
                    )}
                    <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  <Calendar
                    mode="single"
                    selected={departureDate}
                    onSelect={setDepartureDate}
                    disabled={(date) => date < new Date()}
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
            </div>
            {showReturnDate ? (
              <div className="flex flex-col gap-1">
                <Label>{t('Quote.Dialog.returnDate')}</Label>
                <Popover modal open={openReturnDatePopover}>
                  <PopoverTrigger asChild onClick={() => setOpenReturnDatePopover(true)}>
                    <Button
                      variant={'outline'}
                      className={cn(
                        'w-[220px] pl-3 text-left font-normal rounded-full',
                        !returnDate && 'text-muted-foreground'
                      )}
                    >
                      {returnDate ? (
                        format(returnDate, 'dd MMM yyyy')
                      ) : (
                        <span>{t('Quote.Dialog.pickAdate')}</span>
                      )}
                      <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      mode="single"
                      selected={returnDate}
                      onSelect={(date) => {
                        setReturnDate(date);
                        setOpenReturnDatePopover(false);
                      }}
                      disabled={(date) => date < (departureDate || new Date())}
                      defaultMonth={departureDate}
                      fromDate={departureDate}
                    />
                  </PopoverContent>
                </Popover>
              </div>
            ) : (
              <Button
                variant="ghost"
                onClick={() => {
                  setShowReturnDate(true);
                  setOpenReturnDatePopover(true);
                }}
                className="mt-2 mb-0 text-orange"
              >
                {t('Quote.Dialog.addReturnDate')}
              </Button>
            )}
          </div>
        </div>

        <DialogFooter className="items-center flex !flex-col justify-between gap-1.5">
          <DialogClose onClick={async () => await handleQuoteRequest()}>
            <Button id="send-quote-btn" disabled={!departureDate}>
              {t('Quote.Dialog.validateBtn')}
            </Button>
          </DialogClose>
          <p className="text-sm mt-1 text-center">
            {t('Quote.Dialog.emailConfirmation', { email: session?.user?.email })}
          </p>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
export default QuoteDialog;
