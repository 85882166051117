import { CashIcon, ClockIcon as ClockIconOutline } from '@heroicons/react/outline';
import { ArrowUpDownIcon, TrainIcon } from 'lucide-react';
import { useTranslations } from 'next-intl';
import { Popover, PopoverTrigger, PopoverContent } from '../ui/popover';
import { RadioGroup, RadioGroupItem } from '../ui/radio-group';
import { Label } from '../ui/label';

import { Button } from '../ui/button';
import { SortingType } from 'utils';

interface Props {
  sorting: SortingType[];
  setSorting: (value: SortingType[]) => void;
}

export default function SortByToogle({ sorting, setSorting }: Props) {
  const t = useTranslations('Tickets.sorting');

  if (!sorting) {
    return null;
  }

  function changeFirstSortDimension(value: string): void {
    setSorting([value as SortingType, ...sorting.slice(1)]);
  }

  return (
    <div>
      <div className="block">
        <Popover>
          <PopoverTrigger asChild>
            <Button variant="outline">
              <ArrowUpDownIcon className="w-4 h-4 mr-2" />
              <span className="inline-block">{t('title')}</span>
            </Button>
          </PopoverTrigger>
          <PopoverContent align="end" className="w-[220px]">
            <RadioGroup value={sorting[0]} onValueChange={changeFirstSortDimension}>
              <div className="flex items-center cursor-pointer space-x-1.5 hover:bg-secondary rounded-md px-2 py-2">
                <RadioGroupItem value="amount" id="amount" />
                <Label
                  htmlFor="amount"
                  className="flex flex-row items-center cursor-pointer w-full"
                >
                  <CashIcon className="h-6 rounded-full p-1 mr-1 hover:bg-opacity-100" />
                  {t('amount')}
                </Label>
              </div>
              <div className="flex items-center cursor-pointer space-x-1.5 hover:bg-secondary rounded-md px-2 py-2">
                <RadioGroupItem value="duration" id="duration" />
                <Label
                  htmlFor="duration"
                  className="flex flex-row items-center cursor-pointer w-full"
                >
                  <ClockIconOutline className="h-6 rounded-full p-1 mr-1 hover:bg-opacity-100" />
                  {t('duration')}
                </Label>
              </div>
              <div className="flex items-center cursor-pointer space-x-1.5 hover:bg-secondary rounded-md px-2 py-2">
                <RadioGroupItem value="departure" id="departure" />
                <Label
                  htmlFor="departure"
                  className="flex flex-row items-center cursor-pointer w-full"
                >
                  <TrainIcon className="h-6 rounded-full p-1 mr-1 hover:bg-opacity-100" />
                  {t('departureTime')}
                </Label>
              </div>
            </RadioGroup>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
}
