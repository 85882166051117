'use client';

import { useEffect, useState } from 'react';

import { BookOpenText, CameraIcon, CroissantIcon, MoveDiagonalIcon } from 'lucide-react';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslations } from 'next-intl';

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/components/ui/carousel';
import { getCabinDetails } from 'lib/nightTrainsCabinDetails';
import { getPublicEnvVariable, toStringPriceNoCents } from 'lib/utilsClientSide';
import { Leg } from 'utils/journey';

import { Button } from '../ui/button';
import { CardHeader, CardTitle, CardContent, Card } from '../ui/card';
import { Label } from '../ui/label';
import { RadioGroup, RadioGroupItem } from '../ui/radio-group';
import { ScrollArea } from '../ui/scroll-area';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '../ui/sheet';

import PriceHistory from './PriceHistory';

interface Props {
  leg: Leg;
  //This is needed because of a bug in shadcn that doesn't allow to use the same key across different components
  //See: https://github.com/shadcn-ui/ui/issues/3745
  keyForValues: string;
  setCabinType?: (cabinType: string) => void;
}

const showYield =
  getPublicEnvVariable(
    process.env.NEXT_PUBLIC_FEATURE_FLAG_YIELD,
    'NEXT_PUBLIC_FEATURE_FLAG_YIELD'
  ) === 'true';

const SEPARATOR = '|';

const NightTrainOffers = ({ leg, keyForValues, setCabinType }: Props) => {
  const t = useTranslations('Tickets');
  const { operatorKey, cabins } = getCabinDetails(leg.line);
  const amount = null as number | null;
  const [offer, setOffer] = useState<string>('couchette');

  useEffect(() => {
    if (setCabinType !== undefined) {
      setCabinType(offer);
    }
  }, [offer]);

  if (!cabins) {
    return null;
  }

  return (
    <div className="flex pt-1 flex-col items-center justify-end gap-2 w-full">
      <Carousel
        opts={{
          align: 'start',
        }}
        className="w-full mb-3 max-w-sm pl-3.5"
      >
        <RadioGroup className="w-full" onValueChange={setOffer}>
          <CarouselContent>
            {cabins.map((cabin) => (
              <CarouselItem
                key={keyForValues + SEPARATOR + cabin.type}
                className="min-w-48 basis-1/2"
              >
                <RadioGroupItem
                  value={cabin.type}
                  id={keyForValues + SEPARATOR + cabin.type}
                  className="peer sr-only"
                />
                <Label
                  htmlFor={keyForValues + SEPARATOR + cabin.type}
                  className="h-full flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover pb-0 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary overflow-hidden"
                >
                  <CardHeader>
                    <CardTitle className="text-xl text-center !leading-6 sm:text-2xl">
                      {t(`nightTrain.${operatorKey}.${cabin.type}.title`)}
                    </CardTitle>
                  </CardHeader>

                  <CardContent className="flex flex-col">
                    <div className="flex flex-col items-center justify-center">
                      {amount && (
                        <>
                          <p className="text-xs text-center text-slate-500">
                            {t('nightTrain.from')}
                          </p>
                          <h3 className="text-xl font-semibold text-center">
                            {toStringPriceNoCents(amount * cabin.price_ratio)}€
                          </h3>
                        </>
                      )}
                      <p className="text-xs sm:text:sm text-center">
                        {t(`nightTrain.${operatorKey}.${cabin.type}.desc`)}
                      </p>
                    </div>
                  </CardContent>
                  <div className="relative group">
                    <Image
                      src={cabin.pictures[0]}
                      alt={`${cabin.type}-${operatorKey}`}
                      height={256}
                      width={384}
                      className="w-full aspect-video object-cover object-center"
                    />

                    <Sheet>
                      <SheetTrigger className="absolute bottom-0 right-0 p-1 rounded-tl-lg cursor-pointer bg-orange/30 flex items-center justify-center group-hover:inset-0 transition-transform group-hover:text-lg group-hover:rounded-none duration-300">
                        <span className="text-white">
                          {t('nightTrain.seeMore')}
                          <MoveDiagonalIcon className="w-3.5 h-3.5 ml-0.5 text-white inline-block" />
                        </span>
                      </SheetTrigger>
                      <SheetContent
                        side={window.innerWidth < 640 ? 'bottom' : 'right'}
                        className="-mr-2 sm:-mb-2 h-full"
                      >
                        <ScrollArea className="pr-4 h-full">
                          <SheetHeader>
                            <SheetTitle>
                              {' '}
                              {t(`nightTrain.${operatorKey}.${cabin.type}.title`)}
                            </SheetTitle>
                            <SheetDescription>
                              {t(`nightTrain.${operatorKey}.${cabin.type}.desc`)}
                              <br></br>
                              <br></br>
                              {cabin?.description}
                            </SheetDescription>
                          </SheetHeader>
                          {cabin.pictures.map((picture, index) => (
                            <div
                              className="relative w-full rounded-md overflow-hidden border-3 mt-3 cursor-pointer transition-[height] duration-300 h-full"
                              style={{
                                transition: 'max-height 0.3s',
                                height: '100%',
                                maxHeight: '13rem',
                              }}
                              onMouseEnter={(e) =>
                                (e.currentTarget.style.maxHeight = '30rem')
                              }
                              onMouseLeave={(e) =>
                                (e.currentTarget.style.maxHeight = '13rem')
                              }
                            >
                              <Image
                                src={picture}
                                alt={`${cabin.type}-${operatorKey}`}
                                height={350}
                                width={550}
                                className="w-full object-cover object-center"
                              />
                              {cabin?.pictures_author && (
                                <span className="absolute text-xs bottom-0 rounded-br-md right-0 p-0.5 text-white bg-black bg-opacity-50 items-center backdrop-blur-md">
                                  <p className="inline-block">{cabin.pictures_author}</p>
                                  <CameraIcon className="w-3 h-3 inline-block ml-0.5 mb-0.5" />
                                </span>
                              )}
                            </div>
                          ))}
                          <div className="bg-secondary rounded-md mt-3">
                            <Card>
                              <CardHeader>
                                <CardTitle className="text-lg">
                                  {t('nightTrain.amenities.title')}
                                </CardTitle>
                              </CardHeader>
                              <CardContent>
                                <ul className="list-check list-inside fill-orange">
                                  {' '}
                                  {cabin?.amenities?.map((amenity) => (
                                    <li key={amenity}>
                                      {t(`nightTrain.amenities.${amenity}`)}
                                    </li>
                                  ))}
                                </ul>
                              </CardContent>
                            </Card>
                          </div>

                          {cabin?.breakfastMenu && (
                            <Link href={cabin.breakfastMenu} passHref>
                              <Button variant="link" size="xs" className="mt-4">
                                <CroissantIcon className="w-4 h-4 inline-block mr-1" />
                                Included breakfast Menu
                              </Button>
                            </Link>
                          )}
                          {cabin?.menu && (
                            <Link href={cabin.menu} passHref>
                              <Button variant="link" size="xs" className="mt-4">
                                <BookOpenText className="w-4 h-4 inline-block mr-1" />
                                Take away Menu
                              </Button>
                            </Link>
                          )}
                          <SheetClose asChild className="sm:hidden">
                            <Button className="w-full mt-4">
                              {t('nightTrain.close')}
                            </Button>
                          </SheetClose>
                        </ScrollArea>
                      </SheetContent>
                    </Sheet>
                  </div>
                </Label>
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious />
          <CarouselNext />
        </RadioGroup>
      </Carousel>

      {showYield && cabins.every((cabin) => cabin.min_price && cabin.max_price) && (
        <PriceHistory amount={amount} selectedCabinType={offer} cabins={cabins} />
      )}
    </div>
  );
};
export default NightTrainOffers;
