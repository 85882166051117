'use client';

import { useEffect, useState } from 'react';

import { useTranslations } from 'next-intl';

import { getUnsplashApi } from 'lib/utilsClientSide';
import { formatPrice } from 'utils';
import { getDiffHoursFromDates } from 'utils/datesUtils';
import { utf8_to_latin1 } from 'utils/stationsUtils';

import { Card, CardContent } from '../ui/card';
import { Skeleton } from '../ui/skeleton';

import { TransportIcon } from './TransportModeSelector';
import { NightTrainBadge } from './TicketCard';
import { PricedStation } from 'utils/stations';
import { Basic } from 'unsplash-js/dist/methods/photos/types';

const widthImage = 300;
const heightImage = 200;

const citiesToOverride = [
  'Barcelona',
  'Berlin',
  'Brussels',
  'Budapest',
  'Lyon',
  'Brest',
  'Bordeaux',
  'London',
  'Vilnius',
  'Montargis',
  'Montpellier',
  'Venezia',
  'Wien',
  'Zürich',
  'Basel',
  'Bari',
  'Hamburg',
  'München Hbf',
  'Ljubljana',
  'Praha',
  'Vilnius',
  'Stuttgart',
  'Salzburg',
  'Genève',
  'Zagreb',
  'Torino',
  'Toulouse',
  'Glasgow',
  'Kraków',
  'Pau',
  'Nice',
  'Edinburgh Waverley',
  'Milano Centrale',
  'Angers',
  'Warszawa',
  'Aberdeen',
  'Stockholm',
  'Inverness',
];

interface AppPlaceCardProps {
  station: PricedStation;
  onClick: () => void;
  cheapest?: any;
  locale: string;
}
const splash = getUnsplashApi();

const AppPlaceCard = ({ station, cheapest, onClick, locale }: AppPlaceCardProps) => {
  const arrival = cheapest?.arrival;
  const departure = cheapest?.departure;
  const duration = getDiffHoursFromDates(arrival, departure);
  const [imageUrl, setImageUrl] = useState<Basic | string | null>(null);
  const t = useTranslations('Advises');
  const t2 = useTranslations('Tickets');

  useEffect(() => {
    if (citiesToOverride.includes(station.name)) {
      const imagePath = `/assets/live-anywhere/${station.name}.jpg`;

      setImageUrl(imagePath);
    } else {
      // We choose 'landspace' because 'panorama' works less well
      splash.search
        .getPhotos({ query: `${station.name} landscape`, perPage: 1 })
        .then((result) => {
          if (result.type === 'success') {
            const firstPhoto = result.response.results[0];
            return firstPhoto;
          }
        })
        .then((firstPhoto) => {
          if (firstPhoto) {
            setImageUrl(firstPhoto);
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    }
  }, [station.name]);

  return (
    <Card
      onClick={onClick}
      className="mb-2 cursor-pointer transition-transform hover:translate-x-1 hover:border-slate-300 rounded-2xl overflow-hidden"
    >
      <CardContent className="flex flex-row gap-x-3 p-0">
        {/* left - image */}
        <div className="relative basis-1/2 h-[120px]">
          {!imageUrl ? (
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="h-10 w-10 animate-spin rounded-full border-2 border-gray-200"></div>
            </div>
          ) : (
            <div className="group">
              <img
                src={(imageUrl as Basic).urls?.small ?? imageUrl}
                alt={station.name}
                className="w-full h-[120px] object-cover"
                width={widthImage}
                height={heightImage}
                sizes="100vw"
                onClick={async (e) => {
                  e.preventDefault();
                  if ((imageUrl as Basic).links?.download_location) {
                    await splash.photos
                      .trackDownload({
                        downloadLocation: (imageUrl as Basic).links.download_location,
                      })
                      .catch(() => console.warn('Error tracking unsplash download'));
                  }
                }}
              />

              {(imageUrl as Basic).user && (
                <div className="absolute bottom-0 left-0 w-full rounded-bl-lg bg-gradient-to-t from-black bg-opacity-80 p-2 text-white group-hover:opacity-100  opacity-0 transition-opacity">
                  <span className="block pt-1 text-xxs font-light">
                    Photo by{' '}
                    <a
                      href={
                        (imageUrl as Basic).user.links.html +
                        '?utm_source=trainscanner&utm_medium=referral'
                      }
                      className="hover:underline underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {(imageUrl as Basic).user.first_name}
                    </a>{' '}
                    on{' '}
                    <a
                      href="https://unsplash.com/?utm_source=trainscanner&utm_medium=referral"
                      className="hover:underline underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Unsplash
                    </a>
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
        {/* right - detail */}
        <div className="flex basis-1/2 flex-col overflow-hidden py-3 pr-3">
          {cheapest?.mode == 'nightTrain' && (
            <div className="w-fit">
              <NightTrainBadge offer={{ nighttrain: true, mode: 'train' }} t={t2} />
            </div>
          )}

          {/* detail top */}
          <div className="flex justify-between items-end flex-grow">
            <div className="flex items-center">
              <TransportIcon
                mode={cheapest?.mode || 'train'}
                className="fill-black h-5 w-5 mb-0.5"
              />
              <span className="mx-1 text-xs sm:text-sm font-light">
                {duration || 'Unknown'}
              </span>
            </div>
            <span className="mr-1 text-lg font-semibold align-bottom text-orange">
              {cheapest ? (
                <div className="flex -mb-0.5 flex-row gap-0.5 items-baseline">
                  <p className="text-xs">{t('price')}</p>
                  {formatPrice(cheapest.amount)}
                </div>
              ) : (
                'Search >'
              )}
            </span>
          </div>

          {/* detail bottom */}
          <div className="order-first">
            <div className="flew-row flex items-center justify-between gap-2 overflow-hidden whitespace-nowrap">
              <h3 className="overflow-hidden text-ellipsis whitespace-nowrap text-lg">
                {station[`info_${locale}`] || utf8_to_latin1(station.name)}
              </h3>
            </div>
            <hr className="mt-3 mb-1 hidden w-10 border-b border-gray-200 border-opacity-60 sm:block" />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export const AppPlaceCardSkeleton = () => (
  <Card className="mb-2 rounded-2xl overflow-hidden">
    <CardContent className="flex flex-row gap-x-3 p-0">
      <Skeleton className="basis-1/2 h-[120px]" />
      <div className="flex basis-1/2 flex-col py-3 pr-3">
        <Skeleton className="h-4 w-20 mb-2" />
        <Skeleton className="h-6 w-full mb-2" />
        <Skeleton className="h-4 w-24" />
      </div>
    </CardContent>
  </Card>
);

export default AppPlaceCard;
