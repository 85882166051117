import { DateTime } from 'luxon';
import { useLocale } from 'next-intl';

import { formatPrice } from 'utils';
import { formatCheckDate } from 'utils/datesUtils';

interface Props {
  date: Date;
  price: number;
  isCheckIn: boolean;
  setCheckIn: (value: Date) => Promise<URLSearchParams>;
  isLoading: boolean;
  noPriceAvailable: boolean;
}

const DateButtonWithPrice = ({
  date,
  price,
  isCheckIn,
  setCheckIn,
  isLoading,
  noPriceAvailable,
}: Props) => {
  const locale = useLocale();

  const PriceSpan = ({ children, additionalClasses = '' }) => (
    <span className={`text-base font-semibold ${additionalClasses}`}>{children}</span>
  );

  const renderPrice = (priceToRender) => {
    if (noPriceAvailable) {
      return null;
    }
    if (priceToRender) {
      return <PriceSpan>{formatPrice(priceToRender, false, true)}</PriceSpan>;
    }
    if (isLoading) {
      return (
        <PriceSpan additionalClasses="m-0 animate-pulse rounded-lg text-gray-200">
          Loading
        </PriceSpan>
      );
    }

    return null;
  };

  return (
    <div
      className={`mr-4 inline-flex min-w-[4.75rem] min-h-14 flex-grow snap-center overflow-hidden rounded-xl shadow-none transition-shadow last:mr-0
            ${isCheckIn ? 'bg-primary text-white' : 'bg-white/80 hover:bg-secondary/80 box-border border '}`}
      role="listitem"
    >
      <button
        id="button-date-bottom"
        className="relative box-border h-full w-full cursor-pointer items-center justify-center bg-transparent align-middle "
        type="button"
        aria-label={`Results for ${DateTime.fromISO(date).toFormat('d D MMMM')}`}
        data-dashlane-label="true"
        data-form-type="other"
        onClick={async () => {
          await setCheckIn(date);
        }}
      >
        <div className="flex cursor-pointer flex-col items-center justify-center py-2 px-4">
          <abbr className="m-0 whitespace-nowrap text-sm font-semibold leading-4 no-underline">
            {formatCheckDate(date, locale, {
              weekday: 'short',
              day: 'numeric',
            })}
          </abbr>
          {renderPrice(price)}
        </div>
      </button>
    </div>
  );
};

export default DateButtonWithPrice;
