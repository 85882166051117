import { DialogClose } from '@radix-ui/react-dialog';
import { BikeIcon, CheckCircle } from 'lucide-react';
import { useTranslations } from 'next-intl';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { useGuestBikes } from 'hooks/useQuerySearch';
import PackedBike from 'public/assets/stickers/packed-bike.svg';
import { BikeAvailabilityType, Leg } from 'utils/journey';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../ui/accordion';
import { Button } from '../ui/button';
import { CardHeader } from '../ui/card';
import {
  DialogDescription,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogHeader,
  DialogFooter,
} from '../ui/dialog';
import { Icons } from '../ui/icons';

import AppFeedback from './AppFeedback';

interface IBikeAvailabilityProps {
  legs?: Leg[];
  leg?: Leg;
}

const BikeAvailability = ({ legs, leg }: IBikeAvailabilityProps) => {
  const t = useTranslations('Tickets.amenities');
  const [guestBikes] = useGuestBikes();

  if (guestBikes <= 0) {
    return null;
  }

  enum BikeStatusColor {
    'fully accepted' = 'blue-500',
    'partially accepted' = 'grey-300',
    'not accepted' = 'orange/80',
  }
  let status = 'partially accepted';

  //if there are multiple legs
  if (legs && legs[0].amenities?.bike) {
    //if all legs have bike mounted available (even if it's not free) then it's fully accepted
    if (legs.every((l) => l.amenities?.bike.mounted.available === true)) {
      status = 'fully accepted';
      //if some legs have bike mounted unvailable, then it's not accepted
    } else if (legs.some((l) => l.amenities?.bike.mounted.available === false)) {
      status = 'not accepted';
    }

    const color = BikeStatusColor[status];

    return (
      <div className="flex">
        <BikeIcon className={`w-4 h-4 ml-1.5 text-${color}`} />
      </div>
    );
  }

  //if there is only one leg
  else if (leg && leg.amenities?.bike) {
    const bikeMountedPrice = leg.amenities.bike.mounted.price
      ? parseFloat(leg.amenities.bike.mounted.price)
      : 0;
    const bikeAsLuggagePrice = leg.amenities.bike.asLuggage.price
      ? parseFloat(leg.amenities.bike.asLuggage.price)
      : 0;
    return (
      <Dialog>
        <AcceptanceDetailsModal bike={leg.amenities.bike} t={t} />
        <TooltipProvider>
          {leg.amenities.bike.mounted.available ? (
            <Tooltip>
              <DialogTrigger asChild>
                <TooltipTrigger asChild>
                  <div className="flex items-center text-blue-500 hover:text-primary gap-1 mb-0.5 cursor-help">
                    <BikeIcon className="w-4 h-4 ml-2" />
                    {bikeMountedPrice > 0 && (
                      <p className="text-xs">{bikeMountedPrice}€</p>
                    )}
                  </div>
                </TooltipTrigger>
              </DialogTrigger>
              <TooltipContent side="bottom" className="w-fit max-w-64">
                <p className="text-sm">
                  {bikeMountedPrice > 0
                    ? t('bikes.tooltip.mounted.paid', { price: bikeMountedPrice })
                    : t('bikes.tooltip.mounted.free')}{' '}
                </p>
                {bikeMountedPrice != 0 && leg.amenities.bike.asLuggage.available && (
                  <p className="text-sm">
                    {bikeAsLuggagePrice > 0
                      ? t('bikes.tooltip.asLuggage.paid', { price: bikeAsLuggagePrice })
                      : t('bikes.tooltip.asLuggage.free')}
                  </p>
                )}
              </TooltipContent>
            </Tooltip>
          ) : leg.amenities.bike.asLuggage.available ? (
            <Tooltip>
              <DialogTrigger asChild>
                <TooltipTrigger asChild>
                  <div
                    className={`flex items-center ${bikeAsLuggagePrice <= 0 ? 'text-gray-300' : 'text-orange/80'} group hover:text-primary gap-1 mb-0.5 cursor-help`}
                  >
                    <PackedBike
                      className={`w-4 h-4 ml-2 fill-orange/80 group-hover:fill-primary`}
                    />
                    {bikeAsLuggagePrice > 0 && (
                      <p className="text-xs">{bikeAsLuggagePrice}€</p>
                    )}
                  </div>
                </TooltipTrigger>
              </DialogTrigger>

              <TooltipContent side="bottom" className="w-fit max-w-64">
                <p className="text-sm">
                  {' '}
                  {bikeAsLuggagePrice > 0
                    ? t('bikes.tooltip.asLuggage.paid', { price: bikeAsLuggagePrice })
                    : t('bikes.tooltip.asLuggage.free') +
                      ' ' +
                      t('bikes.tooltip.asLuggage.additionalInfos')}{' '}
                </p>
              </TooltipContent>
            </Tooltip>
          ) : (
            leg.amenities.bike.unavailable && (
              <Tooltip>
                <TooltipTrigger asChild>
                  <div
                    className={`flex items-center text-orange hover:text-primary gap-1 mb-0.5 cursor-help`}
                  >
                    <BikeIcon className={`w-4 h-4 ml-2`} />
                  </div>
                </TooltipTrigger>

                <TooltipContent side="bottom" className="w-fit max-w-64">
                  <p className="text-sm"> {t('bikes.tooltip.unavailable')} </p>
                </TooltipContent>
              </Tooltip>
            )
          )}
        </TooltipProvider>
      </Dialog>
    );
  }
  return null;
};

const AcceptanceDetailsModal = ({ bike, t }) => {
  const formatBikePrice = (price) => {
    return parseFloat(price) > 0 ? price + '€' : t('bikes.dialog.free');
  };
  if (bike) {
    return (
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('bikes.dialog.title')}</DialogTitle>
          <DialogDescription>{t('bikes.dialog.subtitle')}</DialogDescription>
        </DialogHeader>

        <div className="grid grid-cols-1 gap-y-4">
          <Accordion collapsible type="single">
            <AccordionItem
              value="mounted-bike"
              disabled={bike.mounted?.available == false}
            >
              <AccordionTrigger>
                <div className="flex items-center w-full justify-between gap-2">
                  <div className="flex items-center gap-2">
                    <BikeIcon className="w-4 h-4" aria-hidden="true" />
                    <div className="font-medium">{t('bikes.dialog.mounted.title')}</div>
                  </div>
                  {bike.mounted?.available ? (
                    <div className="flex items-center gap-1 text-blue-500 mr-3">
                      <CheckCircle className="w-4 h-4 text-blue-500" aria-hidden="true" />
                      <p className="text-xs">{formatBikePrice(bike.mounted?.price)}</p>
                    </div>
                  ) : (
                    <div className="flex items-center gap-1 text-orange mr-3">
                      <p className="text-xs">{t('bikes.dialog.unavailable')}</p>
                    </div>
                  )}
                </div>
              </AccordionTrigger>
              <AccordionContent>
                <p>
                  {mountedBikeReservationDetails(bike.mounted.price, bike.mounted, t)}
                </p>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem
              value="asLuggage"
              disabled={bike.asLuggage?.available == false}
            >
              <AccordionTrigger>
                <div className="flex flex-row w-full justify-between items-center gap-2">
                  <div className="flex items-center gap-1">
                    <Icons.packedBike className="w-5 h-5 mr-0.5" aria-hidden="true" />
                    <div className="font-medium">{t('bikes.dialog.asLuggage.title')}</div>
                  </div>
                  {bike.asLuggage?.available ? (
                    <div className="flex items-center gap-1 text-blue-500 mr-3">
                      <CheckCircle className="w-4 h-4 text-blue-500" aria-hidden="true" />
                      <p className="text-xs">{formatBikePrice(bike.asLuggage?.price)}</p>
                    </div>
                  ) : (
                    <div className="flex items-center gap-1 text-orange mr-3">
                      <p className="text-xs">{t('bikes.dialog.unavailable')}</p>
                    </div>
                  )}
                </div>
              </AccordionTrigger>
              <AccordionContent>
                <Button
                  variant="secondary"
                  className="MuiButtonBase-root MuiCardActionArea-root css-14crgti w-full rounded-md relative p-0 h-fit"
                  tabIndex={0}
                  type="button"
                  aria-label="Plus d'informations"
                  id="button-foldedBikeDimension-message"
                >
                  <CardHeader className="MuiCardContent-root css-zj844c px-4 py-3 items-center flex-1 flex flex-row">
                    <span className="items-center flex grow">
                      <div className="flex items-center">
                        <Icons.packedBikeDimensions className="w-14" aria-hidden="true" />
                        <p className="MuiTypography-root MuiTypography-subtitle1 css-1ljtdiw text-sm ml-2">
                          130x90cm, max 30kg
                        </p>
                      </div>
                    </span>
                    <Icons.info className="w-6 h-6" aria-hidden="true" />
                  </CardHeader>
                  <span className="MuiCardActionArea-focusHighlight css-hrotyx"></span>
                </Button>
                <p className="text-xs mt-1">
                  {t('bikes.dialog.asLuggage.additionalInfos')}
                </p>
                <p className="text-xs">
                  {bike.asLuggage?.price > 0
                    ? t('bikes.dialog.asLuggage.paid', { price: bike.asLuggage?.price })
                    : t('bikes.dialog.asLuggage.free')}
                </p>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem
              value="scooter"
              className="hidden"
              disabled={bike.mounted?.available == false}
            >
              <AccordionTrigger>
                <div className="flex flex-row items-center w-full justify-between gap-2">
                  <div className="flex items-center gap-1">
                    <Icons.scooter className="w-4 h-4" aria-hidden="true" />
                    <div className="font-medium">{t('bikes.dialog.scooter.title')}</div>
                  </div>
                  {bike.mounted?.available ? (
                    <div className="flex items-center gap-1 text-blue-500 mr-3">
                      <CheckCircle className="w-4 h-4 text-blue-500" aria-hidden="true" />
                      <p className="text-xs">{formatBikePrice(bike.mounted.price)}</p>
                    </div>
                  ) : (
                    <div className="flex items-center gap-1 text-orange mr-3">
                      <p className="text-xs">{t('bikes.dialog.unavailable')}</p>
                    </div>
                  )}
                </div>
              </AccordionTrigger>
              <AccordionContent>
                <p>{t('bikes.dialog.scooter.additionalInfos')}</p>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>

        <DialogFooter className="!justify-between">
          <div className="hidden md:block">
            <AppFeedback onSubmit={() => {}} text={t('bikes.dialog.reportBtn')} />
          </div>
          <DialogClose>
            <Button>{t('bikes.dialog.closeBtn')}</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    );
  }
  return null;
};

const mountedBikeReservationDetails = (
  price: string,
  mounted: BikeAvailabilityType['mounted'],
  t: any
) => {
  if (parseFloat(price) > 0) {
    return <p> {t('bikes.dialog.mounted.paid', { price: price })} </p>;
  } else {
    if (mounted?.ifSufficientSpace) {
      return <p> {t('bikes.dialog.mounted.ifSufficientSpace')} </p>;
    } else if (mounted.avoidPeaktimes) {
      return <p>{t('bikes.dialog.mounted.avoidPeakTimes')} </p>;
    } else if (mounted.reservationRequired) {
      return <p>{t('bikes.dialog.mounted.reservationRequired')} </p>;
    } else {
      return <p>{t('bikes.dialog.mounted.free')} </p>;
    }
  }
};

export default BikeAvailability;
