import { is } from 'date-fns/locale';
import moment, { Moment } from 'moment';

import { PRICE_UNKNOWN_CONST } from 'pages/api/getDailyFares';
import { bikePrice } from 'utils/bike';
import { Journey, Leg } from 'utils/journey';

function ParisBerlinPrice(departureDay: Moment): number {
  // Check if the train is unavailable
  if (isNightJetParisBerlinUnavailable(departureDay).yes) {
    return PRICE_UNKNOWN_CONST;
  }
  const dayOfWeek = departureDay.day();
  if (![2, 4, 6].includes(dayOfWeek)) {
    return PRICE_UNKNOWN_CONST; // Return unknown price for non-operating days
  }

  const priceMap: { [key: string]: number } = {
    '2024-10-29': 99, // Tuesday
    '2024-10-31': 99, // Tuesday
    '2024-11-02': PRICE_UNKNOWN_CONST, // Thursday
    '2024-11-05': 64, // Saturday
    '2024-11-07': 119, // Tuesday
    '2024-11-09': PRICE_UNKNOWN_CONST, // Thursday
    '2024-11-12': 54, // Saturday
    '2024-11-14': PRICE_UNKNOWN_CONST, // Tuesday
    '2024-11-16': PRICE_UNKNOWN_CONST, // Thursday
    '2024-11-19': 79, // Saturday
    '2024-11-21': 69, // Tuesday
    '2024-11-23': PRICE_UNKNOWN_CONST, // Thursday
    '2024-11-26': 69, // Saturday
    '2024-11-28': 154, // Tuesday
    '2024-11-30': PRICE_UNKNOWN_CONST, // Thursday
  };

  const dateString = departureDay.format('YYYY-MM-DD');
  return priceMap[dateString] || 84; // Default to 84 if no specific price is set
}

const ParisWienPrice = (departureDay: Moment): number => {
  if (isNightJetParisWienUnavailable(departureDay).yes) {
    return PRICE_UNKNOWN_CONST;
  }
  const dayOfWeek = departureDay.day();
  if (![2, 4, 6].includes(dayOfWeek)) {
    return PRICE_UNKNOWN_CONST; // Return unknown price for non-operating days
  }

  const priceMap: { [key: string]: number } = {
    '2024-10-29': 99, // Tuesday
    '2024-10-31': 99, // Thursday
    '2024-11-02': PRICE_UNKNOWN_CONST, // Saturday
    '2024-11-05': 54, // Tuesday
    '2024-11-07': 54, // Thursday
    '2024-11-09': PRICE_UNKNOWN_CONST, // Saturday
    '2024-11-12': 84, // Tuesday
    '2024-11-14': PRICE_UNKNOWN_CONST, // Thursday
    '2024-11-16': PRICE_UNKNOWN_CONST, // Saturday
    '2024-11-19': 49, // Tuesday
    '2024-11-21': 54, // Thursday
    '2024-11-23': PRICE_UNKNOWN_CONST, // Saturday
    '2024-11-26': 84, // Tuesday
    '2024-11-28': 54, // Thursday
  };

  const dateString = departureDay.format('YYYY-MM-DD');
  return priceMap[dateString] || 84; // Default to 84 if no specific price is set
};

const isNightJetParisWienFullyBooked = (date: Moment) => {
  const fullyBookedDates = ['2024-07-20']; //exemple date
  const isFullyBooked = fullyBookedDates.includes(moment(date).format('YYYY-MM-DD'));
  return {
    yes: isFullyBooked,
    reason: 'fullyBooked',
  };
};
const isNightJetParisBerlinFullyBooked = (date: Moment) => {
  const fullyBookedDates = [
    '2024-11-02',
    '2024-11-09',
    '2024-11-14',
    '2024-11-16',
    '2024-11-23',
    '2024-11-30',
  ]; //exemple date
  const isFullyBooked = fullyBookedDates.includes(moment(date).format('YYYY-MM-DD'));
  return {
    yes: isFullyBooked,
    reason: 'fullyBooked',
  };
};
const isNightJetBerlinParisFullyBooked = (date: Moment) => {
  return {
    yes: moment(date).isBetween('2024-07-27', '2024-07-28'),
    reason: 'fullyBooked',
  };
};
const ParisNightjetWienParisFullyBooked = (date: Moment) => {
  return {
    yes: moment(date).isBetween('2024-08-08', '2024-08-09'),
    reason: 'fullyBooked',
  };
};
const ParisNightjetAugustToOctoberPerturbations = (date: Moment) => {
  return {
    yes: moment(date).isAfter('2024-08-11') && moment(date).isBefore('2024-10-28'),
    reason: 'maintenanceUntilOctober',
  };
};
const isNightJetMunichRomaUnavailable = (date: Moment) => {
  return {
    yes: moment(date).isBefore('2024-09-10'),
    reason: 'startingOnSeptember10th',
  };
};
const isNightJetRomaMunichUnavailable = (date: Moment) => {
  return {
    yes: moment(date).isBefore('2024-09-11'),
    reason: 'startingOnSeptember11th',
  };
};

const isNightJetRomaWienUnavailable = (date: Moment) => {
  return {
    yes: moment(date).isBefore('2024-09-11'),
    reason: 'startingOnSeptember11th',
  };
};
const isNightJetWienRomaUnavailable = (date: Moment) => {
  return {
    yes: moment(date).isBefore('2024-09-10'),
    reason: 'startingOnSeptember10th',
  };
};
const ParisWienNightjetSaturdaysPerturbations = (date: Moment) => {
  const departureDay = moment(date);

  return {
    yes:
      departureDay.isBetween('2024-10-26', '2024-11-30', null, '[]') &&
      departureDay.day() === 6,
    reason: 'saturdayPerturbations',
  };
};
const isNightJetParisBerlinUnavailable = (date: Moment) => {
  const departureDay = date.clone();
  const fullyBooked = isNightJetParisBerlinFullyBooked(date);
  const notYetBookable = {
    yes: departureDay.isAfter('2024-11-29'),
    reason: 'notYetBookable',
  };
  const perturbations = ParisNightjetAugustToOctoberPerturbations(date);
  return {
    yes: notYetBookable.yes || perturbations.yes || fullyBooked.yes,
    reason: perturbations.yes
      ? perturbations.reason
      : fullyBooked.reason
        ? fullyBooked.reason
        : notYetBookable.reason,
  };
};
const isNightJetBerlinParisUnavailable = (date: Moment) => {
  const fullyBooked = isNightJetBerlinParisFullyBooked(date);
  const notYetBookable = {
    yes: moment(date).isAfter('2024-11-29'),
    reason: 'notYetBookable',
  };
  const perturbations = ParisNightjetAugustToOctoberPerturbations(date);
  return {
    yes: notYetBookable.yes || perturbations.yes || fullyBooked.yes,
    reason: perturbations.yes
      ? perturbations.reason
      : fullyBooked.reason
        ? fullyBooked.reason
        : notYetBookable.reason,
  };
};
const isNightJetParisWienUnavailable = (date: Moment) => {
  const fullyBooked = isNightJetParisWienFullyBooked(date);
  const notYetBookable = {
    yes: moment(date).isAfter('2024-11-28'),
    reason: 'notYetBookable',
  };
  const perturbations1 = ParisWienNightjetSaturdaysPerturbations(date);
  const perturbations2 = ParisNightjetAugustToOctoberPerturbations(date);
  return {
    yes:
      notYetBookable.yes || perturbations1.yes || perturbations2.yes || fullyBooked.yes,
    reason: perturbations1.yes
      ? perturbations1.reason
      : perturbations2.yes
        ? perturbations2.reason
        : fullyBooked.yes
          ? fullyBooked.reason
          : notYetBookable.reason,
  };
};
const isNightJetWienParisUnavailable = (date: Moment) => {
  const fullyBooked = ParisNightjetWienParisFullyBooked(date);
  const notYetBookable = {
    yes: moment(date).isAfter('2024-11-28'),
    reason: 'notYetBookable',
  };
  const perturbations = ParisNightjetAugustToOctoberPerturbations(date);
  return {
    yes: notYetBookable.yes || perturbations.yes || fullyBooked.yes,
    reason: perturbations.yes
      ? perturbations.reason
      : fullyBooked.reason
        ? fullyBooked.reason
        : notYetBookable.reason,
  };
};
const isNightJetStuttgartVeniceUnavailable = (date: Moment) => {
  return {
    yes:
      moment(date).isBefore('2024-10-06') ||
      moment(date).isBetween('2024-10-17', '2024-10-27'),
    reason: 'default',
  };
};

export const getUnavailableDates = (
  origin: { name: string },
  destination: { name: string },
  dates: Date[],
  id: string
): Date[] => {
  return dates.filter((dateStr) => {
    const date = moment(dateStr);
    const availability = hardcodedJourneys(origin, destination, date, id)[0].legs.some(
      (leg) => {
        return leg.unavailable?.yes;
      }
    );
    return availability;
  });
};

export const ParisWienDirectNightjet = (departureDay: Moment): Leg => {
  const nextDay = departureDay.clone().add(1, 'days');
  const NightjetDetails: Leg = {
    origin: { name: "Gare de l'Est" },
    destination: { name: 'Wien Hbf' },
    departure: departureDay.clone().hour(19).minute(12).format('YYYY-MM-DDTHH:mm:ssZ'),
    arrival: nextDay.clone().hour(10).minute(13).format('YYYY-MM-DDTHH:mm:ssZ'),
    mode: 'train',
    operator: { name: 'OBB' },
    amenities: {
      bike: bikePrice('Nightjet', '2024-01-06T19:12:00+01:00', 'NJ 469', 0, null),
    },
    line: {
      id: 'NJ 469',
      type: 'train',
      name: 'Nightjet',
    },
    unavailable: isNightJetParisWienUnavailable(departureDay),
  };
  return NightjetDetails;
};
export const ParisBerlinDirectNightjet = (date: Moment): Leg => {
  const departureDay = moment(date);
  const nextDay = moment(date).add(1, 'days');
  const NightjetDetails: Leg = {
    origin: { name: "Paris Gare de l'Est" },
    destination: { name: 'Berlin Hbf' },
    departure: departureDay.clone().hour(19).minute(12).format('YYYY-MM-DDTHH:mm:ssZ'),
    arrival: nextDay.clone().hour(7).minute(0).format('YYYY-MM-DDTHH:mm:ssZ'),
    mode: 'train',
    operator: { name: 'obb' },
    amenities: {
      bike: bikePrice('Nightjet', '2024-01-06T19:12:00+01:00', '', 0, null),
    },
    line: {
      id: 'NJ 40469',
      type: 'train',
      name: 'Nightjet',
      reference: '40469',
    },
    unavailable: isNightJetParisBerlinUnavailable(moment(date)),
  };
  return NightjetDetails;
};
export const BerlinParisDirectNightjet = (date: Moment): Leg => {
  const departureDay = moment(date);
  const nextDay = moment(date).add(1, 'days');
  const DirectNightjet: Leg = {
    origin: { name: 'Berlin Hbf' },
    destination: { name: 'Paris' },
    departure: departureDay.clone().hour(20).minute(18).format('YYYY-MM-DDTHH:mm:ssZ'),
    arrival: nextDay.clone().hour(6).minute(58).format('YYYY-MM-DDTHH:mm:ssZ'),
    mode: 'train',
    operator: { name: 'obb' },
    amenities: {
      bike: bikePrice('Nightjet', '2024-01-06T20:18:00+01:00', '', 0, null),
    },
    line: {
      id: 'NJ 40424',
      name: 'Nightjet',
      type: 'train',
    },
    unavailable: isNightJetBerlinParisUnavailable(date),
  };
  return DirectNightjet;
};
export const WienParisDirectNightjet = (date: Moment): Leg => {
  const departureDay = moment(date);
  const nextDay = moment(date).add(1, 'days');
  const DirectNightjet: Leg = {
    origin: { name: 'Wien Hbf' },
    destination: { name: 'Paris Est' },
    departure: departureDay.clone().hour(19).minute(38).format('YYYY-MM-DDTHH:mm:ssZ'),
    arrival: nextDay.clone().hour(10).minute(24).format('YYYY-MM-DDTHH:mm:ssZ'),
    mode: 'train',
    operator: { name: 'obb' },
    amenities: {
      bike: bikePrice('Nightjet', '2024-08-01T19:38:00+01:00', '', 0, null),
    },
    line: {
      id: 'NJ 468',
      name: 'Nightjet',
      type: 'train',
    },
    unavailable: isNightJetWienParisUnavailable(date),
  };
  return DirectNightjet;
};

export const MunchenRomaDirectNightjet = (date: Moment) => {
  const departureDay = moment(date);
  const nextDay = moment(date).add(1, 'days');
  const DirectNightjet: Leg = {
    origin: { name: 'München Hbf' },
    destination: { name: 'Roma Termini' },
    departure: departureDay.clone().hour(20).minute(10).format('YYYY-MM-DDTHH:mm:ssZ'),
    arrival: nextDay.clone().hour(11).minute(5).format('YYYY-MM-DDTHH:mm:ssZ'),
    mode: 'train',
    operator: { name: 'OBB' },
    amenities: {
      bike: bikePrice('Nightjet', '2024-01-06T20:10:00+01:00', 'NJ 295', 0, null),
    },
    line: {
      id: 'NJ 295',
      name: 'NJ 295',
      type: 'train',
    },
    unavailable: isNightJetMunichRomaUnavailable(date),
  };
  return DirectNightjet;
};
const StuttgartVeniceDirectNightjet = (date: Moment) => {
  const departureDay = moment(date);
  const nextDay = moment(date).add(1, 'days');
  return {
    origin: { name: 'Stuttgart' },
    destination: { name: 'Venice' },
    departure: departureDay.clone().hour(20).minute(29).format('YYYY-MM-DDTHH:mm:ssZ'),
    arrival: nextDay.clone().hour(9).minute(2).format('YYYY-MM-DDTHH:mm:ssZ'),
    mode: 'train',
    operator: { name: 'obb' },
    amenities: {
      bike: bikePrice('Nightjet', '2024-01-06T20:29:00+01:00', null, 0, null),
    },
    line: {
      id: 'NJ 237',
      type: 'train',
      name: 'Nightjet',
    },
    unavailable: isNightJetStuttgartVeniceUnavailable(date),
  };
};
const RomaMunchenDirectNightjet = (date: Moment) => {
  const departureDay = moment(date);
  const nextDay = moment(date).add(1, 'days');
  const DirectNightjet: Leg = {
    origin: { name: 'Roma Termini' },
    destination: { name: 'München Hbf' },
    departure: departureDay.clone().hour(17).minute(25).format('YYYY-MM-DDTHH:mm:ssZ'),
    arrival: nextDay.clone().hour(9).minute(2).format('YYYY-MM-DDTHH:mm:ssZ'),
    mode: 'train',
    operator: { name: 'OBB' },
    amenities: {
      bike: bikePrice('Nightjet', '2024-01-06T17:25:00+01:00', 'NJ 294', 0, null),
    },
    line: {
      id: 'NJ 294',
      name: 'NJ 294',
      type: 'train',
    },
    unavailable: isNightJetRomaMunichUnavailable(date),
  };
  return DirectNightjet;
};

const WienRomaDirectNightjet = (date: Moment) => {
  const departureDay = moment(date);
  const nextDay = moment(date).add(1, 'days');
  const DirectNightjet: Leg = {
    origin: { name: 'Wien Hbf' },
    destination: { name: 'Roma Termini' },
    departure: departureDay.clone().hour(18).minute(18).format('YYYY-MM-DDTHH:mm:ssZ'),
    arrival: nextDay.clone().hour(11).minute(5).format('YYYY-MM-DDTHH:mm:ssZ'),
    mode: 'train',
    operator: { name: 'OBB' },
    amenities: {
      bike: bikePrice('Nightjet', '2024-08-01T19:38:00+01:00', 'NJ 468', 0, null),
    },
    line: {
      id: 'NJ 40233',
      name: 'NJ 40233',
      type: 'train',
    },
    unavailable: isNightJetWienRomaUnavailable(date),
  };
  return DirectNightjet;
};

const RomaWienDirectNightjet = (date: Moment) => {
  const departureDay = moment(date);
  const nextDay = moment(date).add(1, 'days');
  const DirectNightjet: Leg = {
    origin: { name: 'Roma Termini' },
    destination: { name: 'Wien Hbf' },
    departure: departureDay.clone().hour(17).minute(25).format('YYYY-MM-DDTHH:mm:ssZ'),
    arrival: nextDay.clone().hour(9).minute(4).format('YYYY-MM-DDTHH:mm:ssZ'),
    mode: 'train',
    operator: { name: 'OBB' },
    amenities: {
      bike: bikePrice('Nightjet', '2024-01-06T17:25:00+01:00', 'NJ 294', 0, null),
    },
    line: {
      id: 'NJ 40294',
      name: 'NJ 40294',
      type: 'train',
    },
    unavailable: isNightJetRomaWienUnavailable(date),
  };
  return DirectNightjet;
};

const MunchenLaSpeziaDirectNightjet = (date: Moment) => {
  const departureDay = moment(date);
  const nextDay = moment(date).add(1, 'days');
  const DirectNightjet: Leg = {
    origin: { name: 'München Hbf' },
    destination: { name: 'La Spezia Centrale' },
    departure: departureDay.clone().hour(20).minute(10).format('YYYY-MM-DDTHH:mm:ssZ'),
    arrival: nextDay.clone().hour(11).minute(13).format('YYYY-MM-DDTHH:mm:ssZ'),
    mode: 'train',
    operator: { name: 'OBB' },
    amenities: {
      bike: bikePrice('Nightjet', '2024-01-06T20:10:00+01:00', 'NJ 40295', 0, null),
    },
    line: {
      id: 'NJ 40295',
      name: 'NJ 40295',
      type: 'train',
    },
    unavailable: isNightJetMunichRomaUnavailable(date),
  };
  return DirectNightjet;
};

const LaSpeziaMunchenDirectNightjet = (date: Moment) => {
  const departureDay = moment(date);
  const nextDay = moment(date).add(1, 'days');
  const DirectNightjet: Leg = {
    origin: { name: 'La Spezia Centrale' },
    destination: { name: 'München Hbf' },
    departure: departureDay.clone().hour(17).minute(10).format('YYYY-MM-DDTHH:mm:ssZ'),
    arrival: nextDay.clone().hour(9).minute(22).format('YYYY-MM-DDTHH:mm:ssZ'),
    mode: 'train',
    operator: { name: 'OBB' },
    amenities: {
      bike: bikePrice('Nightjet', '2024-01-06T17:10:00+01:00', 'NJ 40235', 0, null),
    },
    line: {
      id: 'NJ 40235',
      name: 'NJ 40235',
      type: 'train',
    },
    unavailable: isNightJetMunichRomaUnavailable(date),
  };
  return DirectNightjet;
};

const LondonInvernessDirectHighLander = (date: Moment) => {
  const departureDay = moment(date);
  const nextDay = moment(date).add(1, 'days');
  const DirectCaledonian: Leg = {
    origin: { name: 'London Euston' },
    destination: { name: 'Inverness' },
    departure: departureDay.clone().hour(21).minute(15).format('YYYY-MM-DDTHH:mm:ssZ'),
    arrival: nextDay.clone().hour(8).minute(45).format('YYYY-MM-DDTHH:mm:ssZ'),
    mode: 'train',
    operator: { name: 'Caledonian Sleeper' },
    line: {
      id: 'Highlander',
      type: 'train',
      name: 'Highlander',
    },
  };
  return DirectCaledonian;
};
const LondonEdinburghDirectLowLander = (date: Moment) => {
  const departureDay = moment(date);
  const nextDay = moment(date).add(1, 'days');
  const DirectCaledonian: Leg = {
    origin: { name: 'London Euston' },
    destination: { name: 'Edinburgh Waverley' },
    departure: departureDay.clone().hour(23).minute(45).format('YYYY-MM-DDTHH:mm:ssZ'),
    arrival: nextDay.clone().hour(7).minute(30).format('YYYY-MM-DDTHH:mm:ssZ'),
    mode: 'train',
    operator: { name: 'Caledonian Sleeper' },
    line: {
      id: 'Lowlander',
      type: 'train',
      name: 'Lowlander',
    },
  };
  return DirectCaledonian;
};
const LondonGlasgowDirectLowLander = (date: Moment) => {
  const departureDay = moment(date);
  const nextDay = moment(date).add(1, 'days');
  const DirectCaledonian: Leg = {
    origin: { name: 'London Euston' },
    destination: { name: 'Glasgow Central' },
    departure: departureDay.clone().hour(23).minute(45).format('YYYY-MM-DDTHH:mm'),
    arrival: nextDay.clone().hour(7).minute(30).format('YYYY-MM-DDTHH:mm'),
    mode: 'train',
    operator: { name: 'Caledonian Sleeper' },
    line: {
      id: 'Lowlander',
      type: 'train',
      name: 'Lowlander',
    },
  };
  return DirectCaledonian;
};
const LondonAberdeenDirectHighLander = (date: Moment) => {
  const departureDay = moment(date);
  const nextDay = moment(date).add(1, 'days');
  const DirectCaledonian: Leg = {
    origin: { name: 'London Euston' },
    destination: { name: 'Aberdeen' },
    departure: departureDay.clone().hour(21).minute(15).format('YYYY-MM-DDTHH:mm'),
    arrival: nextDay.clone().hour(7).minute(40).format('YYYY-MM-DDTHH:mm'),
    mode: 'train',
    operator: { name: 'Caledonian Sleeper' },
    line: {
      id: 'Highlander',
      type: 'train',
      name: 'Highlander',
    },
  };
  return DirectCaledonian;
};
const LondonFortWilliamDirectHighLander = (date: Moment) => {
  const departureDay = moment(date);
  const nextDay = moment(date).add(1, 'days');
  const DirectCaledonian: Leg = {
    origin: { name: 'London Euston' },
    destination: { name: 'Fort William' },
    departure: departureDay.clone().hour(21).minute(15).format('YYYY-MM-DDTHH:mm'),
    arrival: nextDay.clone().hour(10).minute(0).format('YYYY-MM-DDTHH:mm'),
    mode: 'train',
    operator: { name: 'Caledonian Sleeper' },
    line: {
      id: 'Highlander',
      type: 'train',
      name: 'Highlander',
    },
  };
  return DirectCaledonian;
};

const SnalltagetBerlinStockholmAvailable = (date: string) => {
  //Every saturdays
  const departureDay = moment(date);
  const unavailableDates = new Set(['2024-09-29']);

  if (departureDay.isBetween(moment('2024-03-24'), moment('2024-11-03'))) {
    if (
      departureDay.day() === 6 ||
      unavailableDates.has(departureDay.format('YYYY-MM-DD'))
    ) {
      return false;
    }
    return true;
  }
  return false;
};

const hardcodedJourneys: (
  origin: { name: string },
  destination: { name: string },
  date: Moment,
  id?: string
) => Journey[] = (origin, destination, date, id) => {
  const manualJourneysToReturn: Journey[] = [];
  const departureDay = moment(date);
  const nextDay = moment(date).add(1, 'days');
  const nextNextDay = moment(date).add(2, 'days');

  if (SnalltagetBerlinStockholmAvailable(departureDay.toISOString())) {
    //Berlin to Stockholm
    if (origin.name === 'Berlin' && destination.name === 'Stockholm') {
      manualJourneysToReturn.push({
        id: 'manual-berlin-stockholm',
        amount: 105,
        link: `https://snalltaget.se/en/`,
        departure: '2024-01-07T21:11:00+01:00',
        arrival: '2024-01-08T13:20:00+01:00',
        duration: 45000000,
        origin: 'Berlin',
        destination: 'Stockholm',
        legs: [
          {
            origin: { name: 'Berlin Hbf' },
            destination: { name: 'Stockholm' },
            departure: '2024-01-07T21:11:00+01:00',
            arrival: '2024-01-08T13:20:00+01:00',
            mode: 'train',
            operator: { name: 'Snälltåget' },
            line: {
              id: '10300',
              type: 'train',
              name: '10300',
            },
          },
        ],
        mode: 'train',
        operator: 'Snälltåget',
        source: 'snalltaget',
        nighttrain: true,
      });

      //Hamburg to Stockholm
    } else if (origin.name === 'Hamburg' && destination.name === 'Stockholm') {
      manualJourneysToReturn.push({
        id: 'manual-hamburg-stockholm',
        amount: 49,
        link: `https://snalltaget.se/en/`,
        departure: '2024-01-07T23:59:00+01:00',
        arrival: '2024-01-08T13:20:00+01:00',
        duration: 45000000,
        origin: 'Hamburg',
        destination: 'Stockholm',
        legs: [
          {
            origin: { name: 'Hamburg' },
            destination: { name: 'Stockholm' },
            departure: '2024-01-07T23:59:00+01:00',
            arrival: '2024-01-08T13:20:00+01:00',
            mode: 'train',
            operator: { name: 'Snälltåget' },
            line: {
              id: '10300',
              type: 'train',
              name: '10300',
            },
          },
        ],
        mode: 'train',
        operator: 'Snälltåget',
        source: 'snalltaget',
        nighttrain: true,
      });
    } else if (origin.name === 'Berlin' && destination.name === 'Malmö') {
      //Berlin to Malmo
      manualJourneysToReturn.push({
        id: 'manual-berlin-malmo',
        amount: 89,
        link: `https://www.snalltaget.se/en/`,
        departure: '2024-01-07T21:10:00+01:00',
        arrival: '2024-01-08T07:25:00+01:00',
        duration: 45000000,
        origin: 'Berlin',
        destination: 'Malmo',
        legs: [
          {
            origin: { name: 'Berlin Hbf' },
            destination: { name: 'Malmo' },
            departure: '2024-01-07T21:11:00+01:00',
            arrival: '2024-01-08T07:25:00+01:00',
            mode: 'train',
            operator: { name: 'Snälltåget' },
            line: {
              id: '10300',
              type: 'train',
              name: '10300',
            },
          },
        ],
        mode: 'train',
        operator: 'Snälltåget',
        source: 'snalltaget',
        nighttrain: true,
      });
    } else if (origin.name === 'Hamburg' && destination.name === 'Malmö') {
      //Hamburg to Malmo
      manualJourneysToReturn.push({
        id: 'manual-hamburg-malmo',
        amount: 99,
        link: `https://www.snalltaget.se/en/`,
        departure: '2024-01-07T23:59:00+01:00',
        arrival: '2024-01-08T07:25:00+01:00',
        duration: 45000000,
        origin: 'Hamburg',
        destination: 'Malmo',
        legs: [
          {
            origin: { name: 'Hamburg' },
            destination: { name: 'Malmo' },
            departure: '2024-01-07T23:59:00+01:00',
            arrival: '2024-01-08T07:25:00+01:00',
            mode: 'train',
            operator: { name: 'Snälltåget' },
            line: {
              id: '10300',
              type: 'train',
              name: '10300',
            },
          },
        ],
        mode: 'train',
        operator: 'Snälltåget',
        source: 'snalltaget',
        nighttrain: true,
      });
    }
  }

  //Paris - Garda (TGV + Nightjet) 15:55 - 10:13, 139€
  if (origin.name === 'Paris' && destination.name === 'Garda') {
    manualJourneysToReturn.push({
      id: 'manual-paris-garda',
      amount: 139,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=Paris&stationDestName=Garda&outwardDateTime=${departureDay.clone().hour(13).minute(51).format('YYYY-MM-DDTHH:mm:ssZ')}`,
      departure: departureDay.clone().hour(13).minute(51).format('YYYY-MM-DDTHH:mm:ssZ'),
      arrival: nextDay.clone().hour(10).minute(13).format('YYYY-MM-DDTHH:mm:ssZ'),
      duration: 54000000,
      origin: "Gare de L'Est",
      destination: 'Garda',
      legs: [
        {
          origin: { name: "Gare de l'Est" },
          destination: { name: 'Stuttgart Hbf' },
          departure: departureDay
            .clone()
            .hour(13)
            .minute(51)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: departureDay.clone().hour(17).minute(4).format('YYYY-MM-DDTHH:mm:ssZ'),
          mode: 'train',
          operator: { name: 'SNCF' },
          amenities: {
            bike: bikePrice('TGV', '2024-01-06T13:51:00+01:00', 'TGV 9575', 0, null),
          },
          line: {
            id: 'TGV 9575',
            type: 'train',
            name: 'TGV INOUI',
          },
        },
        {
          origin: { name: 'Stuttgart Hbf' },
          destination: { name: 'München Hbf' },
          departure: departureDay
            .clone()
            .hour(17)
            .minute(14)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: departureDay
            .clone()
            .hour(19)
            .minute(12)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          mode: 'train',
          operator: { name: 'OBB' },
          amenities: {
            bike: bikePrice('DB', '2024-01-06T17:14:00+01:00', 'ICE 599', 0, null),
          },
          line: {
            id: 'ICE 599',
            type: 'train',
            name: 'ICE 599',
          },
        },
        {
          origin: { name: 'München Hbf' },
          destination: { name: 'Desenzano del Garda' },
          departure: departureDay
            .clone()
            .hour(20)
            .minute(10)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: nextDay.clone().hour(6).minute(18).format('YYYY-MM-DDTHH:mm:ssZ'),
          mode: 'train',
          operator: { name: 'OBB' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T20:10:00+01:00', 'NJ 40295', 0, null),
          },
          line: {
            id: 'NJ 40295',
            type: 'train',
            name: 'Nightjet',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  }
  // La Spezia - Munchen (Nightjet) 17:10 - 09:22, 139€
  else if (origin.name === 'La Spezia Centrale' && destination.name === 'München Hbf') {
    manualJourneysToReturn.push({
      id: 'manual-la-spezia-munich',
      amount: 139,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=La Spezia&stationDestName=München&outwardDateTime=${departureDay.clone().hour(17).minute(10).format('YYYY-MM-DDTHH:mm:ssZ')}`,
      departure: departureDay.clone().hour(17).minute(10).format('YYYY-MM-DDTHH:mm:ssZ'),
      arrival: nextDay.clone().hour(9).minute(22).format('YYYY-MM-DDTHH:mm:ssZ'),
      duration: 54000000,
      origin: 'La Spezia Centrale',
      destination: 'München Hbf',
      legs: [LaSpeziaMunchenDirectNightjet(date)],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  }
  //Munchen - La Spezia (Nightjet) 20:10 - 11:13, 119€
  else if (origin.name === 'München Hbf' && destination.name === 'La Spezia Centrale') {
    manualJourneysToReturn.push({
      id: 'manual-munich-la-spezia',
      amount: 119,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=München&stationDestName=La Spezia&outwardDateTime=${departureDay.clone().hour(20).minute(10).format('YYYY-MM-DDTHH:mm:ss')}`,
      departure: departureDay.clone().hour(20).minute(10).format('YYYY-MM-DDTHH:mm:ssZ'),
      arrival: nextDay.clone().hour(11).minute(13).format('YYYY-MM-DDTHH:mm:ssZ'),
      duration: 54000000,
      origin: 'München Hbf',
      destination: 'La Spezia Centrale',
      legs: [MunchenLaSpeziaDirectNightjet(date)],
      mode: 'train',
      operator: 'OBB',
      source: 'obb',
      nighttrain: true,
    });
  }
  //if not Saturday and not Friday Paris - Munchen Hbf (TGV INOUI 9577) 15:55 - 21:36, 79€
  else if (
    origin.name === 'Paris' &&
    destination.name === 'München Hbf' &&
    departureDay.day() !== 6 &&
    departureDay.day() !== 5
  ) {
    manualJourneysToReturn.push({
      id: 'manual-paris-munich-direct',
      amount: 79,
      link: `https://www.sncf-connect.com/app/home/search?userInput=Paris+Nice+${departureDay.format('DD/MM/YYYY')}+15h`,
      departure: departureDay.clone().hour(15).minute(55).format('YYYY-MM-DDTHH:mm:ssZ'),
      arrival: departureDay.clone().hour(21).minute(36).format('YYYY-MM-DDTHH:mm:ssZ'),
      duration: 23400000,
      origin: "Gare de L'Est",
      destination: 'München Hbf',
      legs: [
        {
          origin: { name: "Gare de l'Est" },
          destination: { name: 'München Hbf' },
          departure: departureDay
            .clone()
            .hour(15)
            .minute(55)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: departureDay
            .clone()
            .hour(21)
            .minute(36)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          mode: 'train',
          operator: { name: 'SNCF' },
          amenities: {
            bike: bikePrice('TGV', '2024-01-06T15:55:00+01:00', 'TGV 9577', 0, null),
          },
          line: {
            id: 'TGV 9577',
            type: 'train',
            reference: '9577',
            name: 'TGV INOUI',
          },
        },
      ],
      mode: 'train',
      operator: 'SNCF',
      source: 'sncf',
      nighttrain: false,
    });
  } else if (
    origin.name === 'München Hbf' &&
    destination.name === 'Paris' &&
    departureDay.day() !== 5 &&
    departureDay.day() !== 6
  ) {
    manualJourneysToReturn.push({
      id: 'manual-munich-paris-direct',
      amount: 66,
      link: 'https://bahn.de',
      departure: departureDay.clone().hour(6).minute(49).format('YYYY-MM-DDTHH:mm:ssZ'),
      arrival: departureDay.clone().hour(12).minute(33).format('YYYY-MM-DDTHH:mm:ssZ'),
      duration: 23400000,
      origin: 'München Hbf',
      destination: "Gare de L'Est",
      legs: [
        {
          origin: { name: 'München Hbf' },
          destination: { name: "Gare de l'Est" },
          departure: departureDay
            .clone()
            .hour(6)
            .minute(49)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: departureDay
            .clone()
            .hour(12)
            .minute(33)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          mode: 'train',
          operator: { name: 'SNCF' },
          amenities: {
            bike: bikePrice('TGV', '2024-01-06T06:49:00+01:00', 'TGV 9576', 0, null),
          },
          line: {
            id: 'TGV 9576',
            type: 'train',
            reference: '9576',
            name: 'TGV INOUI',
          },
        },
      ],
      mode: 'train',
      operator: 'SNCF',
      source: 'sncf',
      nighttrain: false,
    });
  }

  //Wien - Roma (Nightjet) 19:12 - 10:13, 139€
  else if (origin.name === 'Wien' && destination.name === 'Roma') {
    manualJourneysToReturn.push({
      id: 'manual-nightjet-wien-roma',
      amount: isNightJetWienRomaUnavailable(date) ? PRICE_UNKNOWN_CONST : 89,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=Wien&stationDestName=Roma&outwardDateTime=${departureDay.clone().hour(19).minute(18).format('YYYY-MM-DDTHH:mm:ssZ')}`,
      departure: departureDay.clone().hour(19).minute(18).format('YYYY-MM-DDTHH:mm:ssZ'),
      arrival: nextDay.clone().hour(11).minute(5).format('YYYY-MM-DDTHH:mm:ssZ'),
      duration: 54000000,
      origin: 'Wien',
      destination: 'Roma',
      legs: [WienRomaDirectNightjet(date)],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  }
  //Roma - Wien (Nightjet)
  else if (
    origin.name === 'Roma' &&
    (destination.name === 'Wien' || destination.name === 'Wien Hbf')
  ) {
    manualJourneysToReturn.push({
      id: 'manual-nightjet-roma-wien',
      amount: isNightJetRomaWienUnavailable(date) ? PRICE_UNKNOWN_CONST : 89,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=Roma&stationDestName=Wien&outwardDateTime=${departureDay.clone().hour(17).minute(25).format('YYYY-MM-DDTHH:mm:ssZ')}`,
      departure: departureDay.clone().hour(17).minute(25).format('YYYY-MM-DDTHH:mm:ssZ'),
      arrival: nextDay.clone().hour(9).minute(4).format('YYYY-MM-DDTHH:mm:ssZ'),
      duration: 54000000,
      origin: 'Roma',
      destination: 'Wien',
      legs: [RomaWienDirectNightjet(date)],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  }

  //Munchen - Roma (Nightjet) 20:10 - 11:05, 139€
  else if (origin.name === 'München Hbf' && destination.name === 'Roma') {
    manualJourneysToReturn.push({
      id: 'manual-munich-roma',
      amount: isNightJetMunichRomaUnavailable(date) ? PRICE_UNKNOWN_CONST : 159,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=München&stationDestName=Roma&outwardDateTime=${departureDay.clone().hour(20).minute(10).format('YYYY-MM-DDTHH:mm:ssZ')}`,
      departure: departureDay.clone().hour(20).minute(10).format('YYYY-MM-DDTHH:mm:ssZ'),
      arrival: nextDay.clone().hour(11).minute(5).format('YYYY-MM-DDTHH:mm:ssZ'),
      duration: 54000000,
      origin: 'München Hbf',
      destination: 'Roma',
      legs: [MunchenRomaDirectNightjet(date)],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  }
  //Roma -> Munchen (Nightjet) 17:25 - 09:02, 139€, NJ 294
  else if (origin.name === 'Roma' && destination.name === 'München Hbf') {
    manualJourneysToReturn.push({
      id: 'manual-roma-munich',
      amount: isNightJetRomaMunichUnavailable(date) ? PRICE_UNKNOWN_CONST : 139,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=Roma&stationDestName=München&outwardDateTime=${departureDay.clone().hour(17).minute(25).format('YYYY-MM-DDTHH:mm:ssZ')}`,
      departure: departureDay.clone().hour(17).minute(25).format('YYYY-MM-DDTHH:mm:ssZ'),
      arrival: nextDay.clone().hour(9).minute(2).format('YYYY-MM-DDTHH:mm:ssZ'),
      duration: 54000000,
      origin: 'Roma Tiburtina',
      destination: 'München Hbf',
      legs: [RomaMunchenDirectNightjet(date)],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  }

  //Paris - Stuttgart, Stuttgart - Munchen, Munchen - Roma (Nightjet) 20:10 - 11:05
  if (origin.name === 'Paris' && destination.name === 'Roma') {
    manualJourneysToReturn.push({
      id: 'manual-paris-munich-roma',
      amount: isNightJetMunichRomaUnavailable(date) ? PRICE_UNKNOWN_CONST : 198,
      link: 'https://bahn.de',
      departure: departureDay.clone().hour(13).minute(51).format('YYYY-MM-DDTHH:mm:ssZ'),
      arrival: nextDay.clone().hour(11).minute(5).format('YYYY-MM-DDTHH:mm:ssZ'),
      duration: 54000000,
      origin: 'Paris',
      destination: 'Roma',
      legs: [
        {
          origin: { name: "Gare de l'Est" },
          destination: { name: 'Stuttgart Hbf' },
          departure: departureDay
            .clone()
            .hour(13)
            .minute(51)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: departureDay.clone().hour(17).minute(4).format('YYYY-MM-DDTHH:mm:ssZ'),
          mode: 'train',
          operator: { name: 'SNCF' },
          amenities: {
            bike: bikePrice('TGV', '2024-01-06T13:51:00+01:00', 'TGV 9575', 0, null),
          },
          line: {
            id: 'TGV 9575',
            type: 'train',
            name: 'TGV INOUI',
          },
        },
        {
          origin: { name: 'Stuttgart Hbf' },
          destination: { name: 'München Hbf' },
          departure: departureDay
            .clone()
            .hour(17)
            .minute(14)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: departureDay
            .clone()
            .hour(19)
            .minute(12)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          mode: 'train',
          operator: { name: 'OBB' },
          amenities: {
            bike: bikePrice('DB', '2024-01-06T17:14:00+01:00', 'ICE 599', 0, null),
          },
          line: {
            id: 'ICE 599',
            type: 'train',
            name: 'ICE 599',
          },
        },
        MunchenRomaDirectNightjet(date),
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  }
  //Roma - Munchen, Munchen - Stuttgart, Stuttgart - Paris (Nightjet) 20:10 - 11:05
  else if (origin.name === 'Roma' && destination.name === 'Paris') {
    manualJourneysToReturn.push({
      id: 'manual-roma-munich-paris',
      amount: PRICE_UNKNOWN_CONST,
      link: 'https://bahn.de',
      departure: departureDay.clone().hour(20).minute(10).format('YYYY-MM-DDTHH:mm:ssZ'),
      arrival: nextDay.clone().hour(13).minute(51).format('YYYY-MM-DDTHH:mm:ssZ'),
      duration: 54000000,
      origin: 'Roma',
      destination: 'Paris',
      legs: [
        RomaMunchenDirectNightjet(date),
        {
          origin: { name: 'München Hbf' },
          destination: { name: 'Mannheim Hbf' },
          departure: nextDay.clone().hour(10).minute(46).format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: nextDay.clone().hour(13).minute(28).format('YYYY-MM-DDTHH:mm:ssZ'),
          mode: 'train',
          operator: { name: 'DB' },
          amenities: {
            bike: bikePrice('DB', '2024-01-06T20:10:00+01:00', 'ICE 1218', 0, null),
          },
          line: {
            id: 'ICE 1218',
            type: 'train',
            name: 'ICE 1218',
          },
        },
        {
          origin: { name: 'Mannheim Hbf' },
          destination: { name: "Gare de l'Est" },
          departure: nextDay.clone().hour(13).minute(42).format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: nextDay.clone().hour(16).minute(52).format('YYYY-MM-DDTHH:mm:ssZ'),
          mode: 'train',
          operator: { name: 'SNCF' },
          amenities: {
            bike: bikePrice('TGV', '2024-01-06T13:48:00+01:00', 'TGV 9552', 0, null),
          },
          line: {
            id: 'TGV 9552',
            type: 'train',
            name: 'TGV INOUI',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  } else if (origin.name === 'Paris' && destination.name === 'Monterosso') {
    manualJourneysToReturn.push({
      id: 'manual-paris-monterosso',
      amount: 149,
      link: `https://www.sncf-connect.com/app/home/search?userInput=Paris+Nice+${departureDay.format('DD/MM/YYYY')}+20h`,
      departure: departureDay.clone().hour(20).minute(51).format('YYYY-MM-DDTHH:mm:ssZ'),
      arrival: nextDay.clone().hour(15).minute(32).format('YYYY-MM-DDTHH:mm:ssZ'),
      duration: 68460000,
      origin: 'Paris Austerlitz',
      destination: 'Monterosso',
      legs: [
        {
          id: 'D 5781',
          departure: departureDay
            .clone()
            .hour(20)
            .minute(51)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: nextDay.clone().hour(9).minute(25).format('YYYY-MM-DDTHH:mm:ssZ'),
          origin: {
            name: 'Paris Austerlitz',
          },
          destination: {
            name: 'Nice Ville',
          },
          operator: { name: 'SNCF' },
          mode: 'train',
          line: {
            name: 'Intercités de nuit',
            reference: '5781',
            id: 'D 5781',
          },
        },

        {
          id: 'TER86029',
          departure: nextDay.clone().hour(9).minute(49).format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: nextDay.clone().hour(10).minute(41).format('YYYY-MM-DDTHH:mm:ssZ'),
          origin: {
            name: 'Nice Ville',
          },
          destination: {
            name: 'Ventimiglia',
          },
          operator: { name: 'SNCF' },
          mode: 'train',
          line: {
            name: 'TER',
            reference: '86029',
            id: 'TER86029',
          },
        },

        {
          id: 'IC 745',
          departure: nextDay.clone().hour(10).minute(59).format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: nextDay.clone().hour(13).minute(5).format('YYYY-MM-DDTHH:mm:ssZ'),
          origin: {
            name: 'Ventimiglia',
          },
          destination: {
            name: 'Genova Piazza Principe',
          },
          operator: { name: 'Trenitalia' },
          mode: 'Train',
          line: {
            name: 'InterCity',
            reference: '745',
            id: 'IC 745',
          },
        },

        {
          id: 'R 12381',
          departure: nextDay.clone().hour(13).minute(47).format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: nextDay.clone().hour(15).minute(5).format('YYYY-MM-DDTHH:mm:ssZ'),
          origin: {
            name: 'Genova Piazza Principe',
          },
          destination: {
            name: 'Monterosso',
          },
          operator: { name: 'Trenitalia' },
          mode: 'train',
          line: {
            name: 'Regional',
            reference: '12381',
            id: 'R 12381',
          },
        },
      ],
      mode: 'train',
      operator: 'SNCF',
      source: 'manual',
      nighttrain: true,
    });
  }
  //Paris -> Riomaggiore (Intercités de nuit to Nice) 20:51 - 09:25 Nice
  else if (origin.name === 'Paris' && destination.name === 'Riomaggiore') {
    manualJourneysToReturn.push({
      id: 'manual-paris-riomaggiore',
      amount: 149,
      link: `https://www.sncf-connect.com/app/home/search?userInput=Paris+Nice+${departureDay.format('DD/MM/YYYY')}+20h`,
      departure: departureDay.clone().hour(20).minute(51).format('YYYY-MM-DDTHH:mm:ssZ'),
      arrival: nextDay.clone().hour(15).minute(32).format('YYYY-MM-DDTHH:mm:ssZ'),
      duration: 68460000,
      origin: 'Paris Austerlitz',
      destination: 'Riomaggiore',
      legs: [
        {
          id: 'D 5781',
          departure: departureDay
            .clone()
            .hour(20)
            .minute(51)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: nextDay.clone().hour(9).minute(25).format('YYYY-MM-DDTHH:mm:ssZ'),
          origin: {
            name: 'Paris Austerlitz',
          },
          destination: {
            name: 'Nice Ville',
          },
          operator: { name: 'SNCF' },
          mode: 'train',
          line: {
            name: 'Intercités de nuit',
            reference: '5781',
            id: 'D 5781',
          },
        },

        {
          id: 'TER86029',
          departure: nextDay.clone().hour(9).minute(49).format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: nextDay.clone().hour(10).minute(41).format('YYYY-MM-DDTHH:mm:ssZ'),
          origin: {
            name: 'Nice Ville',
          },
          destination: {
            name: 'Ventimiglia',
          },
          operator: { name: 'SNCF' },
          mode: 'train',
          line: {
            name: 'TER',
            reference: '86029',
            id: 'TER86029',
          },
        },

        {
          id: 'IC 745',
          departure: nextDay.clone().hour(10).minute(59).format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: nextDay.clone().hour(13).minute(5).format('YYYY-MM-DDTHH:mm:ssZ'),
          origin: {
            name: 'Ventimiglia',
          },
          destination: {
            name: 'Genova Piazza Principe',
          },
          operator: { name: 'Trenitalia' },
          mode: 'Train',
          line: {
            name: 'InterCity',
            reference: '745',
            id: 'IC 745',
          },
        },

        {
          id: 'R 12381',
          departure: nextDay.clone().hour(13).minute(13).format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: nextDay.clone().hour(15).minute(32).format('YYYY-MM-DDTHH:mm:ssZ'),
          origin: {
            name: 'Genova Piazza Principe',
          },
          destination: {
            name: 'Riomaggiore',
          },
          operator: { name: 'Trenitalia' },
          mode: 'train',
          line: {
            name: 'Regional',
            reference: '12381',
            id: 'R 12381',
          },
        },
      ],
      mode: 'train',
      operator: 'SNCF',
      source: 'manual',
      nighttrain: true,
    });
  }
  //Zurich -> Lesce Bled (EN 40465) 19:40 - 07:13
  if (
    origin.name === 'Zürich' &&
    (destination.name === 'Lesce Bled' || destination.name === 'Bled')
  ) {
    manualJourneysToReturn.push({
      id: 'manual-zurich-bled',
      amount: 74,
      link: 'https://tickets.oebb.at/en/ticket?stationOrigName=Zürich&stationDestName=Lesce Bled&outwardDateTime=2024-01-06T19:40',
      departure: departureDay.clone().hour(19).minute(40).format('YYYY-MM-DDTHH:mm:ssZ'),
      arrival: nextDay.clone().hour(7).minute(13).format('YYYY-MM-DDTHH:mm:ssZ'),
      duration: 45000000,
      origin: 'Zurich',
      destination: 'Bled',
      legs: [
        {
          origin: { name: 'Zurich HB' },
          destination: { name: 'Lesce Bled' },
          departure: departureDay
            .clone()
            .hour(19)
            .minute(40)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: nextDay.clone().hour(7).minute(13).format('YYYY-MM-DDTHH:mm:ssZ'),
          mode: 'train',
          operator: { name: 'OBB' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T19:40:00+01:00', 'NJ 40465', 0, null),
          },
          line: {
            id: 'EN 40465',
            type: 'train',
            name: 'EN 40465',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  }
  //Paris -> Stuttgart (TGV 9577) 15:55 - 19:04 Stuttgart -> Bled (EN 40237) 20:29 - 07:13
  if (
    origin.name === 'Paris' &&
    (destination.name === 'Lesce Bled' || destination.name === 'Bled')
  ) {
    manualJourneysToReturn.push({
      id: 'manual-paris-stuttgart-bled',
      amount: PRICE_UNKNOWN_CONST,
      link: 'https://bahn.de',
      departure: departureDay.clone().hour(15).minute(55).format('YYYY-MM-DDTHH:mm:ssZ'),
      arrival: nextDay.clone().hour(7).minute(13).format('YYYY-MM-DDTHH:mm:ssZ'),
      duration: 45000000,
      origin: 'Paris',
      destination: 'Bled',
      legs: [
        {
          origin: { name: "Gare de l'Est" },
          destination: { name: 'Stuttgart Hbf' },
          departure: departureDay
            .clone()
            .hour(15)
            .minute(55)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: departureDay.clone().hour(19).minute(4).format('YYYY-MM-DDTHH:mm:ssZ'),
          mode: 'train',
          operator: { name: 'SNCF' },
          amenities: {
            bike: bikePrice('TGV', '2024-01-06T15:55:00+01:00', 'TGV 9577', 0, null),
          },
          line: {
            id: 'TGV 9577',
            type: 'train',
            name: 'TGV INOUI',
          },
        },
        {
          origin: { name: 'Stuttgart Hbf' },
          destination: { name: 'Lesce Bled' },
          departure: departureDay
            .clone()
            .hour(20)
            .minute(29)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: nextDay.clone().hour(7).minute(13).format('YYYY-MM-DDTHH:mm:ssZ'),
          mode: 'train',
          operator: { name: 'OBB' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T20:29:00+01:00', 'NJ 40237', 0, null),
          },
          line: {
            id: 'EN 40237',
            type: 'train',
            name: 'EN 40237',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  }
  //Paris -> Zurich (TGV 9243) 14:22 - 18:26 Zurich -> Bled (EN 40237) 19:40 - 07:13
  if (
    origin.name === 'Paris' &&
    (destination.name === 'Lesce Bled' || destination.name === 'Bled')
  ) {
    manualJourneysToReturn.push({
      id: 'manual-paris-zurich-bled',
      amount: PRICE_UNKNOWN_CONST,
      link: 'https://bahn.de',
      departure: '2024-01-06T14:22:00+01:00',
      arrival: '2024-01-07T07:13:00+01:00',
      duration: 45000000,
      origin: 'Paris',
      destination: 'Bled',
      legs: [
        {
          origin: { name: "Gare de l'Est" },
          destination: { name: 'Zurich HB' },
          departure: '2024-01-06T14:22:00+01:00',
          arrival: '2024-01-06T18:26:00+01:00',
          mode: 'train',
          operator: { name: 'SNCF' },
          amenities: {
            bike: bikePrice('TGV', '2024-01-06T14:22:00+01:00', 'TGV 9243', 0, null),
          },
          line: {
            id: 'TGV 9215',
            type: 'train',
            reference: '9215',
            name: 'TGV INOUI',
          },
        },
        {
          origin: { name: 'Zurich HB' },
          destination: { name: 'Lesce Bled' },
          departure: '2024-01-06T19:40:00+01:00',
          arrival: '2024-01-07T07:13:00+01:00',
          mode: 'train',
          operator: { name: 'OBB' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T19:40:00+01:00', 'NJ 40237', 0, null),
          },
          line: {
            id: 'EN 40237',
            type: 'train',
            name: 'EN 40237',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  }
  //Paris -> Saragosse via Barcelona
  if (origin.name === 'Paris' && destination.name === 'Zaragoza') {
    manualJourneysToReturn.push({
      id: 'manual-paris-saragosse',
      amount: PRICE_UNKNOWN_CONST,
      link: `https://www.sncf-connect.com/app/home/search?userInput=Paris+Barcelone+${departureDay.format('DD/MM/YYYY')}+9h42`,
      departure: '2024-01-07T09:42:00+01:00',
      arrival: '2024-01-07T21:07:00+01:00',
      duration: 45000000,
      origin: 'Paris',
      destination: 'Saragosse',
      legs: [
        {
          origin: { name: 'Gare de Lyon' },
          destination: { name: 'Barcelona Sants' },
          departure: '2024-01-07T09:42:00+01:00',
          arrival: '2024-01-07T16:31:00+01:00',
          mode: 'train',
          operator: { name: 'SNCF' },
          line: {
            id: 'TGV 9713',
            type: 'train',
            reference: '9713',
            name: 'TGV INOUI',
          },
        },
        {
          origin: { name: 'Barcelona Sants' },
          destination: { name: 'Saragosse' },
          departure: '2024-01-07T17:00:00+01:00',
          arrival: '2024-01-07T18:23:00+01:00',
          mode: 'train',
          operator: { name: 'Renfe' },
          line: {
            id: 'EM 1181',
            type: 'train',
            reference: '1181',
            name: 'Euromed',
          },
        },
      ],
      mode: 'train',
      operator: 'sncf',
      source: 'manual',
      nighttrain: false,
    });
  }
  //Paris -> Barcelone (TGV 9713) 09:42 - 16:31 Barcelone -> Valencia (EM 1181) 18:15 - 21:07 , source sncf, link sncf-connect, PRICE_UNKNOWN_CONST
  else if (origin.name === 'Paris' && destination.name === 'Valencia') {
    manualJourneysToReturn.push({
      id: 'manual-paris-barcelone-valencia',
      amount: PRICE_UNKNOWN_CONST,
      link: `https://www.sncf-connect.com/app/home/search?userInput=Paris+Barcelone+${departureDay.format('DD/MM/YYYY')}+9h42`,
      departure: '2024-01-07T09:42:00+01:00',
      arrival: '2024-01-07T21:07:00+01:00',
      duration: 45000000,
      origin: 'Paris',
      destination: 'Valencia',
      legs: [
        {
          origin: { name: 'Gare de Lyon' },
          destination: { name: 'Barcelona Sants' },
          departure: '2024-01-07T09:42:00+01:00',
          arrival: '2024-01-07T16:31:00+01:00',
          mode: 'train',
          operator: { name: 'SNCF' },
          line: {
            id: 'TGV 9713',
            type: 'train',
            reference: '9713',
            name: 'TGV INOUI',
          },
        },
        {
          origin: { name: 'Barcelona Sants' },
          destination: { name: 'Valencia' },
          departure: '2024-01-07T18:15:00+01:00',
          arrival: '2024-01-07T21:07:00+01:00',
          mode: 'train',
          operator: { name: 'Renfe' },
          line: {
            id: 'EM 1181',
            type: 'train',
            reference: '1181',
            name: 'Euromed',
          },
        },
      ],
      mode: 'train',
      operator: 'sncf',
      source: 'manual',
      nighttrain: false,
    });
  }

  //Zurich-Hamburg departure 20:59 arrival 07:53
  if (origin.name === 'Zürich' && destination.name === 'Hamburg') {
    manualJourneysToReturn.push({
      id: 'manual-zurich-hamburg',
      amount: PRICE_UNKNOWN_CONST,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(20)
        .minute(59)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: '2024-01-06T20:59:00+01:00',
      arrival: '2024-01-07T07:53:00+01:00',
      duration: 45000000,
      origin: 'Zurich',
      destination: 'Hamburg',
      legs: [
        {
          origin: { name: 'Zurich HB' },
          destination: { name: 'Hamburg Hbf' },
          departure: '2024-01-06T20:59:00+01:00',
          arrival: '2024-01-07T07:53:00+01:00',
          mode: 'train',
          operator: { name: 'OBB' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T20:59:00+01:00', 'NJ 470', 0, null),
          },
          line: {
            id: 'NJ 470',
            type: 'train',
            name: 'NJ 470',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'obb',
      nighttrain: true,
    });
    // Hamburg-Zurich departure 22:07 arrival 10:05
  } else if (origin.name === 'Hamburg' && destination.name === 'Zürich') {
    manualJourneysToReturn.push({
      id: 'manual-hamburg-zurich',
      amount: PRICE_UNKNOWN_CONST,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(22)
        .minute(7)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: departureDay.clone().hour(22).minute(7).format('YYYY-MM-DDTHH:mm'),
      arrival: nextDay.clone().hour(10).minute(5).format('YYYY-MM-DDTHH:mm'),
      duration: 54000000,
      origin: 'Hamburg',
      destination: 'Zurich',
      legs: [
        {
          origin: { name: 'Hamburg Hbf' },
          destination: { name: 'Zurich HB' },
          departure: '2024-01-06T22:07:00+01:00',
          arrival: '2024-01-07T10:05:00+01:00',
          mode: 'train',
          operator: { name: 'OBB' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T22:07:00+01:00', 'NJ 40468', 0, null),
          },
          line: {
            id: 'NJ 401',
            type: 'train',
            name: 'NJ 401',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'obb',
      nighttrain: true,
    });
  }
  //Zurich-Amsterdam Nightjet departure 21:34 arrival 09:14
  if (origin.name === 'Zürich' && destination.name === 'Amsterdam') {
    manualJourneysToReturn.push({
      id: 'manual-zurich-amsterdam',
      amount: 99,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(21)
        .minute(34)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: '2024-01-06T21:34:00+01:00',
      arrival: '2024-01-07T09:14:00+01:00',
      duration: 45000000,
      origin: 'Zurich',
      destination: 'Amsterdam',
      legs: [
        {
          origin: { name: 'Zurich HB' },
          destination: { name: 'Amsterdam Centraal' },
          departure: '2024-01-06T21:34:00+01:00',
          arrival: '2024-01-07T09:14:00+01:00',
          mode: 'train',
          operator: { name: 'OBB' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T21:34:00+01:00', 'NJ 40469', 0, null),
          },
          line: {
            id: 'NJ 409',
            type: 'train',
            name: 'Nightjet',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'obb',
      nighttrain: true,
    });
    // Amsterdam-Zurich Nightjet departure 20:28 arrival 08:05
  } else if (origin.name === 'Amsterdam' && destination.name === 'Zürich') {
    manualJourneysToReturn.push({
      id: 'manual-amsterdam-zurich',
      amount: 79,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(20)
        .minute(28)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: '2024-01-06T20:28:00+01:00',
      arrival: '2024-01-07T08:05:00+01:00',
      duration: 54000000,
      origin: 'Amsterdam',
      destination: 'Zurich',
      legs: [
        {
          origin: { name: 'Amsterdam Centraal' },
          destination: { name: 'Zurich HB' },
          departure: '2024-01-06T20:28:00+01:00',
          arrival: '2024-01-07T08:05:00+01:00',
          mode: 'train',
          operator: { name: 'OBB' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T20:28:00+01:00', 'NJ 40468', 0, null),
          },
          line: {
            id: 'NJ 408',
            type: 'train',
            name: 'Nightjet',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'obb',
      nighttrain: true,
    });
  }
  //Zurich-Berlin Nightjet
  if (origin.name === 'Zürich' && destination.name === 'Berlin') {
    manualJourneysToReturn.push({
      id: 'manual-zurich-berlin',
      amount: PRICE_UNKNOWN_CONST,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(19)
        .minute(59)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: '2024-01-06T19:59:00+01:00',
      arrival: '2024-01-07T07:20:00+01:00',
      duration: 45000000,
      origin: 'Zurich',
      destination: 'Berlin',
      legs: [
        {
          origin: { name: 'Zurich HB' },
          destination: { name: 'Berlin Hbf' },
          departure: '2024-01-06T19:59:00+01:00',
          arrival: '2024-01-07T07:20:00+01:00',
          mode: 'train',
          operator: { name: 'OBB' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T19:59:00+01:00', 'NJ 409', 0, null),
          },
          line: {
            id: 'NJ 409',
            type: 'train',
            name: 'Nightjet',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'obb',
      nighttrain: true,
    });
  } else if (origin.name === 'Berlin' && destination.name === 'Zürich') {
    manualJourneysToReturn.push({
      id: 'manual-berlin-zurich',
      amount: PRICE_UNKNOWN_CONST,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(21)
        .minute(0o1)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: '2024-01-06T21:01:00+01:00',
      arrival: '2024-01-07T09:05:00+01:00',
      duration: 54000000,
      origin: 'Berlin',
      destination: 'Zurich',
      legs: [
        {
          origin: { name: 'Berlin Hbf' },
          destination: { name: 'Zurich HB' },
          departure: '2024-01-06T21:01:00+01:00',
          arrival: '2024-01-07T09:05:00+01:00',
          mode: 'train',
          operator: { name: 'OBB' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T21:01:00+01:00', 'NJ 40468', 0, null),
          },
          line: {
            id: 'NJ 408',
            type: 'train',
            name: 'Nightjet',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'obb',
      nighttrain: true,
    });
  }

  //Paris to Ljubljana via Stuttgart
  if (origin.name === 'Paris' && destination.name === 'Ljubljana') {
    manualJourneysToReturn.push({
      id: 'manual-paris-stuttgart-ljubljana',
      amount: 109,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(15)
        .minute(55)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: departureDay.clone().hour(15).minute(55).format('YYYY-MM-DDTHH:mm'),
      arrival: nextDay.clone().hour(8).minute(9).format('YYYY-MM-DDTHH:mm'),
      duration: 45000000,
      origin: 'Paris',
      destination: 'Ljubljana',
      legs: [
        {
          origin: { name: "Gare de l'Est" },
          destination: { name: 'Stuttgart Hbf' },
          departure: departureDay.clone().hour(15).minute(55).format('YYYY-MM-DDTHH:mm'),
          arrival: departureDay.clone().hour(19).minute(4).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'SNCF' },
          amenities: {
            bike: bikePrice('TGV', '2024-01-06T15:55:00+01:00', 'TGV 9577', 0, null),
          },
          line: {
            id: 'TGV 9577',
            type: 'train',
            reference: '9577',
            name: 'TGV INOUI',
          },
        },
        {
          origin: { name: 'Stuttgart Hbf' },
          destination: { name: 'Ljubljana' },
          departure: departureDay.clone().hour(20).minute(29).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(8).minute(9).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'OBB' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T20:29:00+01:00', 'NJ 40237', 0, null),
          },
          line: {
            id: 'EN 40237',
            type: 'train',
            name: 'EN 40237',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  } else if (origin.name === 'Ljubljana' && destination.name === 'Paris') {
    manualJourneysToReturn.push({
      id: 'manual-ljubljana-stuttgart-paris',
      amount: PRICE_UNKNOWN_CONST,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(22)
        .minute(0o5)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: departureDay.clone().hour(22).minute(8).format('YYYY-MM-DDTHH:mm'),
      arrival: nextDay.clone().hour(14).minute(13).format('YYYY-MM-DDTHH:mm'),
      duration: 54000000,
      origin: 'Ljubljana',
      destination: 'Paris',
      legs: [
        {
          origin: { name: 'Ljubljana' },
          destination: { name: 'Stuttgart' },
          departure: departureDay.clone().hour(22).minute(8).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(8).minute(38).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'OBB' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T22:08:00+01:00', 'NJ 40236', 0, null),
          },
          line: {
            id: 'EN 414',
            type: 'train',
            name: 'Nightjet',
          },
        },
        {
          origin: { name: 'Stuttgart' },
          destination: { name: 'Paris' },
          departure: nextDay.clone().hour(10).minute(52).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(14).minute(13).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'DB' },
          amenities: {
            bike: bikePrice('ICE', '2024-01-07T10:52:00+01:00', 'ICE 9574', 0, null),
          },
          line: {
            id: 'ICE 9574',
            type: 'train',
            name: 'ICE 9574',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  }
  // Paris to Vilnius
  if (origin.name === 'Paris' && destination.name === 'Vilnius') {
    manualJourneysToReturn.push(
      {
        id: 'manual-paris-berlin-warszawa-vilnius',
        amount: PRICE_UNKNOWN_CONST,
        link: 'https://bilet.intercity.pl/zakup_biletu.jsp?ref=infop&rezerwacja_0=1&miejsce_0=2&ref=1',
        departure: departureDay
          .clone()
          .hour(19)
          .minute(12)
          .format('YYYY-MM-DDTHH:mm:ssZ'),
        arrival: nextNextDay.clone().hour(17).minute(34).format('YYYY-MM-DDTHH:mm:ssZ'),
        duration: 45000000,
        origin: 'Warszawa',
        destination: 'Vilnius',
        legs: [
          ParisBerlinDirectNightjet(date),
          {
            origin: { name: 'Berlin Hbf' },
            destination: { name: 'Warszawa Centralna' },
            departure: nextDay.clone().hour(11).minute(52).format('YYYY-MM-DDTHH:mm:ssZ'),
            arrival: nextDay.clone().hour(17).minute(14).format('YYYY-MM-DDTHH:mm:ssZ'),
            mode: 'train',
            operator: { name: 'db' },
            line: {
              id: 'EC 45',
              type: 'train',
              name: 'EC 45',
            },
          },
          {
            origin: { name: 'Warszawa Centralna' },
            destination: { name: 'Vilnius' },
            departure: nextNextDay
              .clone()
              .hour(7)
              .minute(45)
              .format('YYYY-MM-DDTHH:mm:ssZ'),
            arrival: nextNextDay
              .clone()
              .hour(17)
              .minute(34)
              .format('YYYY-MM-DDTHH:mm:ssZ'),
            mode: 'train',
            operator: { name: 'LTG Link' },
            line: {
              id: 'LTG 144/24',
              type: 'train',
              name: '144/24',
            },
          },
        ],
        mode: 'train',
        operator: 'LTG Link',
        source: 'LTG',
        nighttrain: false,
      },
      {
        id: 'manual-paris-wien-warszawa-vilnius',
        amount: PRICE_UNKNOWN_CONST,
        link: 'https://bilet.intercity.pl/zakup_biletu.jsp?ref=infop&rezerwacja_0=1&miejsce_0=2&ref=1',
        departure: '2024-01-06T19:12:00+01:00',
        arrival: '2024-01-08T17:34:00+01:00',
        duration: 45000000,
        origin: 'Warszawa',
        destination: 'Vilnius',
        legs: [
          ParisWienDirectNightjet(date),
          {
            origin: { name: 'Wien Hbf' },
            destination: { name: 'Warszawa Centralna' },
            departure: nextDay.clone().hour(14).minute(10).format('YYYY-MM-DDTHH:mm:ssZ'),
            arrival: nextDay.clone().hour(21).minute(34).format('YYYY-MM-DDTHH:mm:ssZ'),
            mode: 'train',
            operator: { name: 'obb' },
            line: {
              id: 'EC 45',
              type: 'train',
              name: 'EC 45',
            },
          },
          {
            origin: { name: 'Warszawa Centralna' },
            destination: { name: 'Vilnius' },
            departure: nextNextDay
              .clone()
              .hour(7)
              .minute(45)
              .format('YYYY-MM-DDTHH:mm:ssZ'),
            arrival: nextNextDay
              .clone()
              .hour(17)
              .minute(34)
              .format('YYYY-MM-DDTHH:mm:ssZ'),
            mode: 'train',
            operator: { name: 'LTG Link' },
            line: {
              id: 'LTG 144/24',
              type: 'train',
              name: '144/24',
            },
          },
        ],
        mode: 'train',
        operator: 'LTG Link',
        source: 'manual',
        nighttrain: false,
      }
    );
  }

  //Berlin to Stockholm departure 18:37 arrival 09:57, EN 345 run by SJ
  if (origin.name === 'Berlin' && destination.name === 'Stockholm') {
    manualJourneysToReturn.push({
      id: 'manual-berlin-stockholm',
      amount: 135,
      link: `https://www.sj.se/en/home.html#/tidtabell/berlin-c/stockholm-c/enkel/avgang/2024-01-06-18:37/retur/2024-01-07-09:57`,
      departure: departureDay.clone().hour(18).minute(37).format('YYYY-MM-DDTHH:mm'),
      arrival: nextDay.clone().hour(9).minute(57).format('YYYY-MM-DDTHH:mm'),
      duration: 54000000,
      origin: 'Berlin',
      destination: 'Stockholm',
      legs: [
        {
          origin: { name: 'Berlin Hbf' },
          destination: { name: 'Stockholm Central' },
          departure: departureDay.clone().hour(18).minute(37).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(9).minute(57).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'SJ' },
          line: {
            id: 'EN 345',
            type: 'train',
            name: 'EN 345',
          },
        },
      ],
      mode: 'train',
      operator: 'SJ',
      source: 'manual',
      nighttrain: true,
    });
  } else if (origin.name === 'Stockholm' && destination.name === 'Berlin') {
    manualJourneysToReturn.push({
      id: 'manual-stockholm-berlin',
      amount: 135,
      link: `https://www.sj.se/en/home.html#/tidtabell/stockholm-c/berlin-c/enkel/avgang/2024-01-06-18:37/retur/2024-01-07-09:57`,
      departure: departureDay.clone().hour(17).minute(34).format('YYYY-MM-DDTHH:mm'),
      arrival: nextDay.clone().hour(9).minute(25).format('YYYY-MM-DDTHH:mm'),
      duration: 54000000,
      origin: 'Stockholm',
      destination: 'Berlin',
      legs: [
        {
          origin: { name: 'Stockholm Central' },
          destination: { name: 'Berlin Hbf' },
          departure: departureDay.clone().hour(17).minute(34).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(9).minute(25).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'SJ' },
          line: {
            id: 'EN 346',
            type: 'train',
            name: 'EN 346',
          },
        },
      ],
      mode: 'train',
      operator: 'SJ',
      source: 'manual',
      nighttrain: true,
    });
  }
  //Paris to Warszawa via Berlin (using departureDay & ParisBerlinDirectNightjet)
  if (origin.name === 'Paris' && destination.name === 'Warszawa') {
    manualJourneysToReturn.push({
      id: 'manual-paris-berlin-warszawa',
      amount: PRICE_UNKNOWN_CONST,
      link: 'https://bilet.intercity.pl/zakup_biletu.jsp?ref=infop&rezerwacja_0=1&miejsce_0=2&ref=1',
      departure: departureDay.clone().hour(19).minute(12).format('YYYY-MM-DDTHH:mm:ssZ'),
      arrival: nextDay.clone().hour(15).minute(10).format('YYYY-MM-DDTHH:mm:ssZ'),
      duration: 45000000,
      origin: 'Paris',
      destination: 'Warszawa',
      legs: [
        ParisBerlinDirectNightjet(date),
        {
          origin: { name: 'Berlin Hbf' },
          destination: { name: 'Warszawa Centralna' },
          departure: nextDay.clone().hour(10).minute(3).format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: nextDay.clone().hour(15).minute(10).format('YYYY-MM-DDTHH:mm:ssZ'),
          mode: 'train',
          operator: { name: 'db' },
          line: {
            id: 'EC 45',
            type: 'train',
            name: 'EC 45',
          },
        },
      ],
      mode: 'train',
      operator: 'db',
      source: 'manual',
      nighttrain: true,
    });
  } else if (origin.name === 'Warszawa' && destination.name === 'Paris') {
    manualJourneysToReturn.push({
      id: 'manual-warszawa-berlin-paris',
      amount: PRICE_UNKNOWN_CONST,
      link: 'https://bilet.intercity.pl/zakup_biletu.jsp?ref=infop&rezerwacja_0=1&miejsce_0=2&ref=1',
      departure: departureDay.clone().hour(10).minute(51).format('YYYY-MM-DDTHH:mm:ssZ'),
      arrival: nextDay.clone().hour(6).minute(58).format('YYYY-MM-DDTHH:mm:ssZ'),
      duration: 45000000,
      origin: 'Warszawa',
      destination: 'Paris',
      legs: [
        {
          origin: { name: 'Warszawa Centralna' },
          destination: { name: 'Berlin Hbf' },
          departure: departureDay
            .clone()
            .hour(12)
            .minute(51)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: departureDay
            .clone()
            .hour(17)
            .minute(55)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          mode: 'train',
          operator: { name: 'db' },
          line: {
            id: 'EC 44',
            type: 'train',
            name: 'EC 44',
          },
        },
        BerlinParisDirectNightjet(date),
      ],
      mode: 'train',
      operator: 'db',
      source: 'manual',
      nighttrain: true,
    });
  }
  //Munchen to Warszawa Direct Nightjet, Departure 18h45, Arrival 09h08, EN 60406 run by PKP
  if (
    origin.name === 'München' ||
    (origin.name === 'München Hbf' && destination.name === 'Warszawa')
  ) {
    manualJourneysToReturn.push({
      id: 'manual-munchen-warszawa',
      amount: 145,
      link: `https://bilet.intercity.pl/zakup_biletu.jsp?ref=infop&rezerwacja_0=1&miejsce_0=2&ref=1`,
      departure: departureDay.clone().hour(18).minute(35).format('YYYY-MM-DDTHH:mm:ssZ'),
      arrival: nextDay.clone().hour(9).minute(28).format('YYYY-MM-DDTHH:mm:ssZ'),
      duration: 45000000,
      origin: 'München Hbf',
      destination: 'Warszawa Centralna',
      legs: [
        {
          origin: { name: 'München Hbf' },
          destination: { name: 'Warszawa Centralna' },
          departure: departureDay
            .clone()
            .hour(18)
            .minute(35)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: nextDay.clone().hour(9).minute(28).format('YYYY-MM-DDTHH:mm:ssZ'),
          mode: 'train',
          operator: { name: 'PKP' },
          line: {
            id: 'EN 406',
            type: 'train',
            name: 'EN 406',
          },
        },
      ],
      mode: 'train',
      operator: 'PKP',
      source: 'manual',
      nighttrain: true,
    });
  } else if (
    origin.name === 'Warszawa' &&
    (destination.name === 'München' || destination.name === 'München Hbf')
  ) {
    manualJourneysToReturn.push({
      id: 'manual-warszawa-munchen',
      amount: 145,
      link: `https://bilet.intercity.pl/zakup_biletu.jsp?ref=infop&rezerwacja_0=1&miejsce_0=2&ref=1`,
      departure: departureDay.clone().hour(19).minute(46).format('YYYY-MM-DDTHH:mm:ssZ'),
      arrival: nextDay.clone().hour(10).minute(1).format('YYYY-MM-DDTHH:mm:ssZ'),
      duration: 45000000,
      origin: 'Warszawa Centralna',
      destination: 'München Hbf',
      legs: [
        {
          origin: { name: 'Warszawa Centralna' },
          destination: { name: 'München Hbf' },
          departure: departureDay
            .clone()
            .hour(19)
            .minute(46)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: nextDay.clone().hour(10).minute(1).format('YYYY-MM-DDTHH:mm:ssZ'),
          mode: 'train',
          operator: { name: 'PKP' },
          line: {
            id: 'EN 407',
            type: 'train',
            name: 'EN 407',
          },
        },
      ],
      mode: 'train',
      operator: 'PKP',
      source: 'manual',
      nighttrain: true,
    });
  }
  //Paris to Warszawa via Munchen
  else if (origin.name === 'Paris' && destination.name === 'Warszawa') {
    manualJourneysToReturn.push({
      id: 'manual-paris-munchen-warszawa',
      amount: 229,
      link: `https://bilet.intercity.pl/zakup_biletu.jsp?ref=infop&rezerwacja_0=1&miejsce_0=2&ref=1`,
      departure: departureDay.clone().hour(10).minute(52).format('YYYY-MM-DDTHH:mm:ssZ'),
      arrival: nextDay.clone().hour(9).minute(28).format('YYYY-MM-DDTHH:mm:ssZ'),
      duration: 45000000,
      origin: 'Paris',
      destination: 'Warszawa',
      legs: [
        {
          origin: { name: 'Paris Est' },
          destination: { name: 'Stuttgart Hbf' },
          departure: departureDay
            .clone()
            .hour(10)
            .minute(35)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: departureDay.clone().hour(14).minute(4).format('YYYY-MM-DDTHH:mm:ssZ'),
          mode: 'train',
          operator: { name: 'DB' },
          line: {
            id: 'ICE 9573',
            type: 'train',
            name: 'ICE 9573',
          },
        },
        {
          origin: { name: 'Stuttgart Hbf' },
          destination: { name: 'München Hbf' },
          departure: departureDay
            .clone()
            .hour(14)
            .minute(14)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: departureDay
            .clone()
            .hour(16)
            .minute(26)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          mode: 'train',
          operator: { name: 'DB' },
          line: {
            id: 'ICE 517',
            type: 'train',
            name: 'ICE 517',
          },
        },

        {
          origin: { name: 'München Hbf' },
          destination: { name: 'Warszawa Centralna' },
          departure: departureDay
            .clone()
            .hour(18)
            .minute(35)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: nextDay.clone().hour(9).minute(28).format('YYYY-MM-DDTHH:mm:ssZ'),
          mode: 'train',
          operator: { name: 'PKP' },
          line: {
            id: 'EN 406',
            type: 'train',
            name: 'EN 406',
          },
        },
      ],
      mode: 'train',
      operator: 'PKP',
      source: 'manual',
      nighttrain: true,
    });
  } else if (origin.name === 'Warszawa' && destination.name === 'Paris') {
    manualJourneysToReturn.push({
      id: 'manual-warszawa-munchen-paris',
      amount: 209,
      link: `https://bilet.intercity.pl/zakup_biletu.jsp?ref=infop&rezerwacja_0=1&miejsce_0=2&ref=1`,
      departure: departureDay.clone().hour(19).minute(46).format('YYYY-MM-DDTHH:mm:ssZ'),
      arrival: nextDay.clone().hour(10).minute(1).format('YYYY-MM-DDTHH:mm:ssZ'),
      duration: 45000000,
      origin: 'Warszawa',
      destination: 'Paris',
      legs: [
        {
          origin: { name: 'Warszawa Centralna' },
          destination: { name: 'München Hbf' },
          departure: departureDay
            .clone()
            .hour(19)
            .minute(46)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: nextDay.clone().hour(10).minute(1).format('YYYY-MM-DDTHH:mm:ssZ'),
          mode: 'train',
          operator: { name: 'PKP' },
          line: {
            id: 'EN 407',
            type: 'train',
            name: 'EN 407',
          },
        },
        {
          origin: { name: 'München Hbf' },
          destination: { name: 'Stuttgart Hbf' },
          departure: nextDay.clone().hour(11).minute(28).format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: nextDay.clone().hour(13).minute(34).format('YYYY-MM-DDTHH:mm:ssZ'),
          mode: 'train',
          operator: { name: 'DB' },
          line: {
            id: 'ICE 518',
            type: 'train',
            name: 'ICE 518',
          },
        },
        {
          origin: { name: 'Stuttgart Hbf' },
          destination: { name: 'Paris Est' },
          departure: nextDay.clone().hour(14).minute(52).format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: nextDay.clone().hour(18).minute(16).format('YYYY-MM-DDTHH:mm:ssZ'),
          mode: 'train',
          operator: { name: 'DB' },
          line: {
            id: 'ICE 9572',
            type: 'train',
            name: 'ICE 9572',
          },
        },
      ],
      mode: 'train',
      operator: 'PKP',
      source: 'manual',
      nighttrain: true,
    });
  }
  // Warszawa to Vilnius
  if (origin.name === 'Warszawa' && destination.name === 'Vilnius') {
    manualJourneysToReturn.push({
      id: 'manual-warszawa-vilnius',
      amount: 25,
      link: 'https://bilet.intercity.pl/zakup_biletu.jsp?ref=infop&rezerwacja_0=1&miejsce_0=2&ref=1',
      departure: '2024-01-06T07:45:00+01:00',
      arrival: '2024-01-06T17:34:00+01:00',
      duration: 45000000,
      origin: 'Warszawa',
      destination: 'Vilnius',
      legs: [
        {
          origin: { name: 'Warszawa Central' },
          destination: { name: 'Vilnius' },
          departure: '2024-01-06T07:45:00+01:00',
          arrival: '2024-01-06T17:34:00+01:00',
          mode: 'train',
          operator: { name: 'LTG Link' },
          line: {
            id: 'LTG 144/24',
            type: 'train',
            name: '144/24',
          },
        },
      ],
      mode: 'train',
      operator: 'LTG Link',
      source: 'LTG',
      nighttrain: false,
    });
  }
  //if Paris to Praha via Brussels
  if (origin.name === 'Paris' && destination.name === 'Praha') {
    manualJourneysToReturn.push({
      id: 'manual-paris-brussels-prague',
      amount: 159,
      link: `https://booking.europeansleeper.eu/en?departureStation=8727100&arrivalStation=5400001&departureDate=${departureDay.format('YYYY-MM-DD')}&passengerTypes=72&bicycleCount=0`,
      departure: departureDay.clone().hour(19).minute(22).format('YYYY-MM-DDTHH:mm'),
      arrival: nextDay.clone().hour(10).minute(56).format('YYYY-MM-DDTHH:mm'),
      duration: 45000000,
      origin: 'Paris',
      destination: 'Praha',
      legs: [
        {
          origin: { name: 'Paris Nord' },
          destination: { name: 'Brussels Midi' },
          departure: departureDay.clone().hour(17).minute(25).format('YYYY-MM-DDTHH:mm'),
          arrival: departureDay.clone().hour(18).minute(47).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'Eurostar' },
          line: {
            id: 'ES 9369',
            type: 'train',
            name: 'ES 9369',
          },
        },
        {
          origin: { name: 'Brussels Midi' },
          destination: { name: 'Praha hl.n.' },
          departure: departureDay.clone().hour(19).minute(22).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(10).minute(56).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'European Sleeper' },
          line: {
            id: 'ES453',
            type: 'train',
            name: 'ES453',
          },
        },
      ],
      mode: 'train',
      operator: 'European Sleeper',
      source: 'europeansleeper',
      nighttrain: true,
    });
  }
  if (
    origin.name === 'Paris' &&
    (destination.name === 'Bruxelles' || destination.name === 'Bruxelles-Midi')
  ) {
    manualJourneysToReturn.push({
      id: 'manual-paris-cdg-brussels',
      amount: PRICE_UNKNOWN_CONST,
      link: `https://www.sncf-connect.com/app/home/search?userInput=Paris+Bruxelles+${departureDay.format('DD/MM/YYYY')}+10h`,
      departure: departureDay.clone().hour(10).minute(35).format('YYYY-MM-DDTHH:mm'),
      arrival: departureDay.clone().hour(12).minute(12).format('YYYY-MM-DDTHH:mm'),
      duration: 45000000,
      origin: 'Paris Aeroport Charles De Gaulle 2 Tgv',
      destination: 'Brussels South/Midi Train Station',
      legs: [
        {
          origin: { name: 'Paris Aeroport Charles De Gaulle 2 Tgv' },
          destination: { name: 'Brussels South/Midi Train Station' },
          departure: departureDay.clone().hour(10).minute(35).format('YYYY-MM-DDTHH:mm'),
          arrival: departureDay.clone().hour(12).minute(12).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'sncf' },
          line: {
            id: 'TGV 9854',
            type: 'train',
            name: 'TGV 9854',
          },
        },
      ],
      mode: 'train',
      operator: 'sncf',
      source: 'manual',
      nighttrain: false,
    });

    manualJourneysToReturn.push({
      id: 'manual-paris-cdg-brussels',
      amount: PRICE_UNKNOWN_CONST,
      link: `https://www.sncf-connect.com/app/home/search?userInput=Paris+Bruxelles+${departureDay.format('DD/MM/YYYY')}+13h07`,
      departure: departureDay.clone().hour(13).minute(7).format('YYYY-MM-DDTHH:mm'),
      arrival: departureDay.clone().hour(15).minute(25).format('YYYY-MM-DDTHH:mm'),
      duration: 45000000,
      origin: 'Paris Aeroport Charles De Gaulle 2 Tgv',
      destination: 'Brussels South/Midi Train Station',
      legs: [
        {
          origin: { name: 'Paris Aeroport Charles De Gaulle 2 Tgv' },
          destination: { name: 'Brussels South/Midi Train Station' },
          departure: departureDay.clone().hour(13).minute(7).format('YYYY-MM-DDTHH:mm'),
          arrival: departureDay.clone().hour(15).minute(25).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'sncf' },
          line: {
            id: 'TGV 9854',
            type: 'train',
            name: 'TGV 9854',
          },
        },
      ],
      mode: 'train',
      operator: 'sncf',
      source: 'manual',
      nighttrain: false,
    });
  }
  //if Brussels to Praha
  else if (origin.name === 'Bruxelles' && destination.name === 'Praha') {
    manualJourneysToReturn.push({
      id: 'manual-brussels-prague',
      amount: 79,
      link: `https://booking.europeansleeper.eu/en?departureStation=8800104&arrivalStation=5457076&departureDate=${departureDay.format('YYYY-MM-DD')}&passengerTypes=72&bicycleCount=0`,
      departure: departureDay.clone().hour(19).minute(22).format('YYYY-MM-DDTHH:mm'),
      arrival: nextDay.clone().hour(10).minute(56).format('YYYY-MM-DDTHH:mm'),
      duration: 45000000,
      origin: 'Brussels',
      destination: 'Praha',
      legs: [
        {
          origin: { name: 'Brussels Midi' },
          destination: { name: 'Praha' },
          departure: departureDay.clone().hour(19).minute(22).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(10).minute(56).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'European Sleeper' },
          line: {
            id: 'ES453',
            type: 'train',
            name: 'ES453',
          },
        },
      ],
      mode: 'train',
      operator: 'European Sleeper',
      source: 'europeansleeper',
      nighttrain: true,
    });
  } else if (origin.name === 'Praha' && destination.name === 'Bruxelles') {
    manualJourneysToReturn.push({
      id: 'manual-prague-brussels',
      amount: 79,
      link: `https://booking.europeansleeper.eu/en?departureStation=5457076&arrivalStation=8800104&departureDate=${departureDay.format('YYYY-MM-DD')}&passengerTypes=72&bicycleCount=0`,
      departure: departureDay.clone().hour(18).minute(4).format('YYYY-MM-DDTHH:mm'),
      arrival: nextDay.clone().hour(9).minute(27).format('YYYY-MM-DDTHH:mm'),
      duration: 45000000,
      origin: 'Praha',
      destination: 'Brussels',
      legs: [
        {
          origin: { name: 'Praha' },
          destination: { name: 'Brussels Midi' },
          departure: departureDay.clone().hour(18).minute(4).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(9).minute(27).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'European Sleeper' },
          line: {
            id: 'ES452',
            type: 'train',
            name: 'ES452',
          },
        },
      ],
      mode: 'train',
      operator: 'European Sleeper',
      source: 'europeansleeper',
      nighttrain: true,
    });
  }

  // Venezia Santa Lucia to Wien Hbf
  if (origin.name === 'Venezia' && destination.name === 'Wien') {
    manualJourneysToReturn.push({
      id: 'manual-venezia-santa-lucia-wien-hbf',
      amount: PRICE_UNKNOWN_CONST,
      link: 'https://www.nightjet.com/de/',
      departure: '2024-01-06T21:05:00+01:00',
      arrival: '2024-01-07T08:17:00+01:00',
      duration: 40320000,
      origin: 'Venezia Santa Lucia',
      destination: 'Wien Hbf',
      legs: [
        {
          origin: { name: 'Venezia Santa Lucia' },
          destination: { name: 'Wien Hbf' },
          departure: '2024-01-06T21:05:00+01:00',
          arrival: '2024-01-07T08:17:00+01:00',
          mode: 'train',
          operator: { name: 'ÖBB' },
          line: {
            id: 'NJ 40236',
            type: 'train',
            name: 'NJ 40236',
          },
        },
      ],
      mode: 'train',
      operator: 'ÖBB',
      source: 'nightjet',
      nighttrain: true,
    });
  }

  // Wien Hbf to Venezia Santa Lucia
  else if (origin.name === 'Wien' && destination.name === 'Venezia') {
    manualJourneysToReturn.push({
      id: 'manual-wien-hbf-venezia-santa-lucia',
      amount: PRICE_UNKNOWN_CONST,
      link: 'https://www.nightjet.com/de/',
      departure: '2024-01-06T21:39:00+01:00',
      arrival: '2024-01-07T08:34:00+01:00',
      duration: 39900000,
      origin: 'Wien Hbf',
      destination: 'Venezia Santa Lucia',
      legs: [
        {
          origin: { name: 'Wien Hbf' },
          destination: { name: 'Venezia Santa Lucia' },
          departure: '2024-01-06T21:39:00+01:00',
          arrival: '2024-01-07T08:34:00+01:00',
          mode: 'train',
          operator: { name: 'ÖBB' },
          line: {
            id: 'NJ 40466',
            type: 'train',
            name: 'NJ 40466',
          },
        },
      ],
      mode: 'train',
      operator: 'ÖBB',
      source: 'nightjet',
      nighttrain: true,
    });
  }
  //Brussels to Berlin
  if (origin.name === 'Bruxelles' && destination.name === 'Berlin') {
    manualJourneysToReturn.push({
      id: 'manual-brussels-berlin',
      amount: 79,
      link: `https://booking.europeansleeper.eu/en?departureStation=8800104&arrivalStation=8010100&departureDate=${departureDay.format('YYYY-MM-DD')}&passengerTypes=72&bicycleCount=0`,
      departure: departureDay.clone().hour(19).minute(22).format('YYYY-MM-DDTHH:mm'),
      arrival: nextDay.clone().hour(6).minute(18).format('YYYY-MM-DDTHH:mm'),
      duration: 45000000,
      origin: 'Brussels',
      destination: 'Berlin',
      legs: [
        {
          origin: { name: 'Brussels Midi' },
          destination: { name: 'Berlin Hbf' },
          departure: departureDay.clone().hour(19).minute(22).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(6).minute(18).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'European Sleeper' },
          line: {
            id: 'ES453',
            type: 'train',
            name: 'ES453',
          },
        },
      ],
      mode: 'train',
      operator: 'European Sleeper',
      source: 'europeansleeper',
      nighttrain: true,
    });
  } else if (origin.name === 'Berlin' && destination.name === 'Bruxelles') {
    manualJourneysToReturn.push({
      id: 'manual-berlin-brussels',
      amount: 79,
      link: `https://booking.europeansleeper.eu/en?departureStation=8010100&arrivalStation=8800104&departureDate=${departureDay.format('YYYY-MM-DD')}&passengerTypes=72&bicycleCount=0`,
      departure: departureDay.clone().hour(22).minute(56).format('YYYY-MM-DDTHH:mm'),
      arrival: nextDay.clone().hour(9).minute(27).format('YYYY-MM-DDTHH:mm'),
      duration: 45000000,
      origin: 'Berlin',
      destination: 'Brussels',
      legs: [
        {
          origin: { name: 'Berlin Hbf' },
          destination: { name: 'Brussels Midi' },
          departure: departureDay.clone().hour(22).minute(56).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(9).minute(27).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'European Sleeper' },
          line: {
            id: 'ES452',
            type: 'train',
            name: 'ES452',
          },
        },
      ],
      mode: 'train',
      operator: 'European Sleeper',
      source: 'europeansleeper',
      nighttrain: true,
    });
  }
  if (origin.name === 'Paris' && destination.name === 'Venezia') {
    manualJourneysToReturn.push({
      id: 'manual-paris-stuttgart-venice',
      amount: 165,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(15)
        .minute(55)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: departureDay.clone().hour(15).minute(55).format('YYYY-MM-DDTHH:mm'),
      arrival: nextDay.clone().hour(8).minute(34).format('YYYY-MM-DDTHH:mm'),
      duration: 45000000,
      origin: 'Paris',
      destination: 'Venezia',
      legs: [
        {
          origin: { name: "Gare de l'Est" },
          destination: { name: 'Stuttgart Hbf' },
          departure: departureDay.clone().hour(15).minute(55).format('YYYY-MM-DDTHH:mm'),
          arrival: departureDay.clone().hour(19).minute(4).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'SNCF' },
          amenities: {
            bike: bikePrice('TGV', '2024-01-06T15:55:00+01:00', '', 0, null),
          },
          line: {
            id: 'TGV 9577',
            type: 'train',
            name: 'TGV INOUI',
          },
        },
        StuttgartVeniceDirectNightjet(date),
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  } else if (origin.name === 'Venezia' && destination.name === 'Paris') {
    manualJourneysToReturn.push({
      id: 'manual-venice-stuttgart-paris',
      amount: PRICE_UNKNOWN_CONST,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(21)
        .minute(0o5)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: departureDay.clone().hour(21).minute(0o5).format('YYYY-MM-DDTHH:mm'),
      arrival: nextDay.clone().hour(8).minute(38).format('YYYY-MM-DDTHH:mm'),
      duration: 54000000,
      origin: 'Venice',
      destination: 'Paris',
      legs: [
        {
          origin: { name: 'Venice' },
          destination: { name: 'Stuttgart' },
          departure: departureDay.clone().hour(21).minute(0o5).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(8).minute(38).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'OBB' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T21:05:00+01:00', null, 0, null),
          },
          line: {
            id: 'NJ 236',
            type: 'train',
            name: 'Nightjet',
          },
        },
        {
          origin: { name: 'Stuttgart' },
          destination: { name: 'Paris' },
          departure: nextDay.clone().hour(9).minute(10).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(12).minute(33).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'SNCF' },
          amenities: {
            bike: bikePrice('TGV', '2024-01-07T09:10:00+01:00', '', 0, null),
          },
          line: {
            id: 'TGV 9576',
            type: 'train',
            name: 'TGV INOUI',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  }
  if (origin.name === 'Nantes' && destination.name === 'Venezia') {
    manualJourneysToReturn.push({
      id: 'manual-nantes-venice',
      amount: PRICE_UNKNOWN_CONST,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(10)
        .minute(45)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: departureDay.clone().hour(10).minute(50).format('YYYY-MM-DDTHH:mm'),
      arrival: nextDay.clone().hour(8).minute(34).format('YYYY-MM-DDTHH:mm'),
      duration: 54000000,
      origin: 'Nantes',
      destination: 'Venezia',
      legs: [
        {
          origin: { name: 'Nantes' },
          destination: { name: 'Paris Montparnasse' },
          departure: departureDay.clone().hour(10).minute(50).format('YYYY-MM-DDTHH:mm'),
          arrival: departureDay.clone().hour(12).minute(24).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'SNCF' },
          line: {
            id: 'OUIGO 7622',
            type: 'train',
            name: 'OUIGO',
            reference: '7622',
          },
        },
        {
          origin: { name: 'Paris Gare de Lyon' },
          destination: { name: 'Stuttgart Hbf' },
          departure: departureDay.clone().hour(13).minute(51).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(17).minute(4).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'SNCF' },
          line: {
            id: 'TGV 9575',
            type: 'train',
            name: 'TGV INOUI',
            reference: '9575',
          },
        },
        StuttgartVeniceDirectNightjet(date),
      ],
      mode: 'train',
      operator: 'SNCF',
      source: 'manual',
      nighttrain: false,
    });
  }
  //Stuttgart to Rijeka 20:29 10:39
  if (origin.name === 'Stuttgart' && destination.name === 'Rijeka') {
    manualJourneysToReturn.push({
      id: 'manual-stuttgart-rijeka',
      amount: 89,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(20)
        .minute(29)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: departureDay.clone().hour(20).minute(29).format('YYYY-MM-DDTHH:mm'),
      arrival: nextDay.clone().hour(10).minute(39).format('YYYY-MM-DDTHH:mm'),
      duration: 45000000,
      origin: 'Stuttgart',
      destination: 'Rijeka',
      legs: [
        {
          origin: { name: 'Stuttgart' },
          destination: { name: 'Rijeka' },
          departure: departureDay.clone().hour(20).minute(29).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(10).minute(39).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'obb' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T20:29:00+01:00', '', 0, null),
          },
          line: {
            id: 'EN 60237',
            type: 'train',
            name: 'EN 60237',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  }
  //Rijeka to Stuttgart 19:38 08:38
  else if (origin.name === 'Rijeka' && destination.name === 'Stuttgart') {
    manualJourneysToReturn.push({
      id: 'manual-rijeka-stuttgart',
      amount: 89,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(19)
        .minute(38)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: departureDay.clone().hour(19).minute(38).format('YYYY-MM-DDTHH:mm'),
      arrival: nextDay.clone().hour(8).minute(38).format('YYYY-MM-DDTHH:mm'),
      duration: 45000000,
      origin: 'Rijeka',
      destination: 'Stuttgart',
      legs: [
        {
          origin: { name: 'Rijeka' },
          destination: { name: 'Stuttgart' },
          departure: departureDay.clone().hour(19).minute(38).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(8).minute(38).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'obb' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T19:38:00+01:00', '', 0, null),
          },
          line: {
            id: 'EN 480',
            type: 'train',
            name: 'EN 480',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  }
  //Stuttgart to Wien 20:29 06:34
  if (origin.name === 'Stuttgart' && destination.name === 'Wien') {
    manualJourneysToReturn.push({
      id: 'manual-stuttgart-wien',
      amount: 74,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(20)
        .minute(29)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: departureDay.clone().hour(20).minute(29).format('YYYY-MM-DDTHH:mm'),
      arrival: nextDay.clone().hour(6).minute(34).format('YYYY-MM-DDTHH:mm'),
      duration: 45000000,
      origin: 'Stuttgart',
      destination: 'Wien',
      legs: [
        {
          origin: { name: 'Stuttgart' },
          destination: { name: 'Wien' },
          departure: departureDay.clone().hour(20).minute(29).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(6).minute(34).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'obb' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T20:29:00+01:00', '', 0, null),
          },
          line: {
            id: 'EN 50237',
            type: 'train',
            name: 'EN 50237',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'obb',
      nighttrain: true,
    });
  }
  //Stuttgart to Budapest 20:29 09:19
  if (origin.name === 'Stuttgart' && destination.name === 'Budapest') {
    manualJourneysToReturn.push({
      id: 'manual-stuttgart-budapest',
      amount: 74,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(20)
        .minute(29)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: departureDay.clone().hour(20).minute(29).format('YYYY-MM-DDTHH:mm'),
      arrival: nextDay.clone().hour(9).minute(19).format('YYYY-MM-DDTHH:mm'),
      duration: 45000000,
      origin: 'Stuttgart',
      destination: 'Budapest',
      legs: [
        {
          origin: { name: 'Stuttgart' },
          destination: { name: 'Budapest' },
          departure: departureDay.clone().hour(20).minute(29).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(9).minute(19).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'obb' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T20:29:00+01:00', '', 0, null),
          },
          line: {
            id: 'EN 50237',
            type: 'train',
            name: 'EN 50237',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'obb',
      nighttrain: true,
    });
  }
  //Budapest to Stuttgart 20:40 08:38
  else if (origin.name === 'Budapest' && destination.name === 'Stuttgart') {
    manualJourneysToReturn.push({
      id: 'manual-budapest-stuttgart',
      amount: 89,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(20)
        .minute(40)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: departureDay.clone().hour(20).minute(40).format('YYYY-MM-DDTHH:mm'),
      arrival: nextDay.clone().hour(8).minute(38).format('YYYY-MM-DDTHH:mm'),
      duration: 45000000,
      origin: 'Budapest',
      destination: 'Stuttgart',
      legs: [
        {
          origin: { name: 'Budapest' },
          destination: { name: 'Stuttgart' },
          departure: departureDay.clone().hour(20).minute(40).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(8).minute(38).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'obb' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T20:40:00+01:00', '', 0, null),
          },
          line: {
            id: 'EN 50462',
            type: 'train',
            name: 'EN 50462',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  }

  //paris - milano 14:48 23:10 TGV INOUI
  if (
    origin.name === 'Paris' &&
    (destination.name === 'Milano' || destination.name === 'Milano Centrale')
  ) {
    manualJourneysToReturn.push({
      id: 'manual-paris-milano',
      amount: 79,
      link: `https://www.sncf-connect.com/app/home/search?userInput=Paris+Milan+${departureDay.format('DD/MM/YYYY')}+14h`,
      departure: departureDay.clone().hour(14).minute(48).format('YYYY-MM-DDTHH:mm'),
      arrival: departureDay.clone().hour(23).minute(10).format('YYYY-MM-DDTHH:mm'),
      duration: 45000000,
      origin: 'Paris',
      destination: 'Milano',
      legs: [
        {
          origin: { name: 'Paris Gare de Lyon - Hall 1 & 2' },
          destination: { name: 'St Jean de Maurienne Arvan' },
          departure: departureDay.clone().hour(14).minute(48).format('YYYY-MM-DDTHH:mm'),
          arrival: departureDay.clone().hour(18).minute(50).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'SNCF' },
          line: {
            id: '9255',
            reference: '9255',
            type: 'train',
            name: 'TGV INOUI',
          },
        },
        {
          origin: { name: 'St Jean de Maurienne Arvan' },
          destination: { name: 'Oulx Cesana Claviere Sestriere' },
          departure: departureDay.clone().hour(18).minute(50).format('YYYY-MM-DDTHH:mm'),
          arrival: departureDay.clone().hour(20).minute(10).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'SNCF' },
          line: {
            id: '69269',
            type: 'train',
            name: 'BUS 9255',
          },
        },
        {
          origin: { name: 'Oulx Cesana Claviere Sestriere' },
          destination: { name: 'Milano Centrale' },
          departure: departureDay.clone().hour(20).minute(52).format('YYYY-MM-DDTHH:mm'),
          arrival: departureDay.clone().hour(23).minute(10).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'SNCF' },
          line: {
            id: '9259',
            reference: '9259',
            type: 'train',
            name: 'TGV INOUI',
          },
        },
      ],
      mode: 'train',
      operator: 'SNCF',
      source: 'sncf',
      nighttrain: false,
    });
  } else if (origin.name === 'Milano' && destination.name === 'Paris') {
    manualJourneysToReturn.push({
      id: 'manual-milano-paris',
      amount: 79,
      link: `https://www.sncf-connect.com/app/home/search?userInput=Milan+Paris+${departureDay.format('DD/MM/YYYY')}+14h`,
      departure: departureDay.clone().hour(14).minute(48).format('YYYY-MM-DDTHH:mm'),
      arrival: nextDay.clone().hour(23).minute(14).format('YYYY-MM-DDTHH:mm'),
      duration: 45000000,
      origin: 'Milano',
      destination: 'Paris',
      legs: [
        {
          origin: { name: 'Milano Centrale' },
          destination: { name: 'Oulx Cesana Claviere Sestriere' },
          departure: departureDay.clone().hour(14).minute(48).format('YYYY-MM-DDTHH:mm'),
          arrival: departureDay.clone().hour(16).minute(33).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'SNCF' },
          line: {
            id: 'TGV 9258',
            type: 'train',
            name: 'TGV INOUI 9258',
          },
        },
        {
          origin: { name: 'Oulx Cesana Claviere Sestriere' },
          destination: { name: 'St Jean de Maurienne Arvan' },
          departure: departureDay.clone().hour(17).minute(10).format('YYYY-MM-DDTHH:mm'),
          arrival: departureDay.clone().hour(19).minute(0).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'SNCF' },
          line: {
            id: '69268',
            type: 'train',
            name: 'BUS 69268',
          },
        },
        {
          origin: { name: 'St Jean de Maurienne Arvan' },
          destination: { name: 'Paris Gare de Lyon - Hall 1 & 2' },
          departure: departureDay.clone().hour(19).minute(37).format('YYYY-MM-DDTHH:mm'),
          arrival: departureDay.clone().hour(23).minute(14).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'SNCF' },
          line: {
            id: '9258',
            type: 'train',
            name: 'TGV INOUI 9250',
          },
        },
      ],
      mode: 'train',
      operator: 'SNCF',
      source: 'sncf',
      nighttrain: false,
    });
  } else if (origin.name === 'Torino' && destination.name === 'Paris') {
    manualJourneysToReturn.push({
      id: 'manual-torino-paris',
      amount: 79,
      link: `https://www.sncf-connect.com/app/home/search?userInput=Turin+Paris+${departureDay.format('DD/MM/YYYY')}+14h`,
      departure: departureDay.clone().hour(16).minute(41).format('YYYY-MM-DDTHH:mm'),
      arrival: nextDay.clone().hour(23).minute(14).format('YYYY-MM-DDTHH:mm'),
      duration: 45000000,
      origin: 'Torino',
      destination: 'Paris',
      legs: [
        {
          origin: { name: 'Torino Porta Susa' },
          destination: { name: 'Oulx Cesana Claviere Sestriere' },
          departure: departureDay.clone().hour(15).minute(41).format('YYYY-MM-DDTHH:mm'),
          arrival: departureDay.clone().hour(16).minute(53).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'SNCF' },
          line: {
            id: 'TGV 9258',
            type: 'train',
            name: 'TGV INOUI 9258',
          },
        },
        {
          origin: { name: 'Oulx Cesana Claviere Sestriere' },
          destination: { name: 'St Jean de Maurienne Arvan' },
          departure: departureDay.clone().hour(17).minute(10).format('YYYY-MM-DDTHH:mm'),
          arrival: departureDay.clone().hour(19).minute(0).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'SNCF' },
          line: {
            id: '69268',
            type: 'train',
            name: 'BUS 69268',
          },
        },
        {
          origin: { name: 'St Jean de Maurienne Arvan' },
          destination: { name: 'Paris Gare de Lyon - Hall 1 & 2' },
          departure: departureDay.clone().hour(19).minute(37).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(23).minute(14).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'SNCF' },
          line: {
            id: '9258',
            type: 'train',
            name: 'TGV INOUI 9250',
          },
        },
      ],
      mode: 'train',
      operator: 'SNCF',
      source: 'sncf',
      nighttrain: false,
    });
  } else if (origin.name === 'Paris' && destination.name === 'Torino') {
    manualJourneysToReturn.push({
      id: 'manual-paris-torino',
      amount: 79,
      link: `https://www.sncf-connect.com/app/home/search?userInput=Paris+Turin+${departureDay.format('DD/MM/YYYY')}+14h`,
      departure: departureDay.clone().hour(14).minute(48).format('YYYY-MM-DDTHH:mm'),
      arrival: nextDay.clone().hour(22).minute(33).format('YYYY-MM-DDTHH:mm'),
      duration: 45000000,
      origin: 'Paris',
      destination: 'Torino',
      legs: [
        {
          origin: { name: 'Paris Gare de Lyon - Hall 1 & 2' },
          destination: { name: 'St Jean de Maurienne Arvan' },
          departure: departureDay.clone().hour(14).minute(48).format('YYYY-MM-DDTHH:mm'),
          arrival: departureDay.clone().hour(18).minute(25).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'SNCF' },
          line: {
            id: '9255',
            type: 'train',
            reference: '9259',
            name: 'TGV INOUI',
          },
        },
        {
          origin: { name: 'St Jean de Maurienne Arvan' },
          destination: { name: 'Oulx Cesana Claviere Sestriere' },
          departure: departureDay.clone().hour(18).minute(50).format('YYYY-MM-DDTHH:mm'),
          arrival: departureDay.clone().hour(20).minute(10).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'SNCF' },
          line: {
            id: '69269',
            type: 'train',
            name: 'BUS 9255',
          },
        },
        {
          origin: { name: 'Oulx Cesana Claviere Sestriere' },
          destination: { name: 'Torino Porta Susa' },
          departure: departureDay.clone().hour(20).minute(52).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(21).minute(46).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'SNCF' },
          line: {
            id: '9259',
            type: 'train',
            reference: '9259',
            name: 'TGV INOUI',
          },
        },
      ],
      mode: 'train',
      operator: 'SNCF',
      source: 'sncf',
      nighttrain: false,
    });
  }
  //Paris to Zagreb 15:55 10:39
  if (origin.name === 'Paris' && destination.name === 'Zagreb') {
    manualJourneysToReturn.push({
      id: 'manual-paris-zagreb',
      amount: 115,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(15)
        .minute(55)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: departureDay.clone().hour(15).minute(55).format('YYYY-MM-DDTHH:mm'),
      arrival: nextDay.clone().hour(10).minute(39).format('YYYY-MM-DDTHH:mm'),
      duration: 45000000,
      origin: 'Paris',
      destination: 'Zagreb',
      legs: [
        {
          origin: { name: "Gare de l'Est" },
          destination: { name: 'Stuttgart Hbf' },
          departure: departureDay.clone().hour(15).minute(55).format('YYYY-MM-DDTHH:mm'),
          arrival: departureDay.clone().hour(19).minute(4).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'SNCF' },
          amenities: {
            bike: bikePrice('TGV', '2024-01-06T15:55:00+01:00', '', 0, null),
          },
          line: {
            id: 'TGV 9577',
            reference: '9577',
            type: 'train',
            name: 'TGV INOUI',
          },
        },
        {
          origin: { name: 'Stuttgart Hbf' },
          destination: { name: 'Zagreb Glavni Kol.' },
          departure: departureDay.clone().hour(20).minute(29).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(10).minute(39).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'OBB' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T20:29:00+01:00', '', 0, null),
          },
          line: {
            id: 'NJ 237',
            reference: '237',
            type: 'train',
            name: 'Nightjet',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  } else if (origin.name === 'Zagreb' && destination.name === 'Paris') {
    manualJourneysToReturn.push({
      id: 'manual-zagreb-paris',
      amount: 79,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(19)
        .minute(39)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: departureDay.clone().hour(19).minute(39).format('YYYY-MM-DDTHH:mm'),
      arrival: nextDay.clone().hour(12).minute(33).format('YYYY-MM-DDTHH:mm'),
      duration: 54000000,
      origin: 'Zagreb',
      destination: 'Paris',
      legs: [
        {
          origin: { name: 'Zagreb Glavni Kol.' },
          destination: { name: 'Stuttgart Hbf' },
          departure: departureDay.clone().hour(19).minute(39).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(8).minute(38).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'OBB' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T19:39:00+01:00', '', 0, null),
          },
          line: {
            id: 'NJ 236',
            reference: '236',
            type: 'train',
            name: 'Nightjet',
          },
        },
        {
          origin: { name: 'Stuttgart Hbf' },
          destination: { name: "Gare de l'Est" },
          departure: nextDay.clone().hour(9).minute(10).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(12).minute(33).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'SNCF' },
          amenities: {
            bike: bikePrice('TGV', '2024-01-07T09:10:00+01:00', '', 0, null),
          },
          line: {
            id: 'TGV 9576',
            reference: '9576',
            type: 'train',
            name: 'TGV INOUI',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  }
  //Stuttgart to Zagreb 20:29 10:39
  if (origin.name === 'Stuttgart' && destination.name === 'Zagreb') {
    manualJourneysToReturn.push({
      id: 'manual-stuttgart-zagreb',
      amount: PRICE_UNKNOWN_CONST,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(20)
        .minute(29)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: '2024-01-06T20:29:00+01:00',
      arrival: '2024-01-07T10:39:00+01:00',
      duration: 45000000,
      origin: 'Stuttgart',
      destination: 'Zagreb',
      legs: [
        {
          origin: { name: 'Stuttgart' },
          destination: { name: 'Zagreb' },
          departure: '2024-01-06T20:29:00+01:00',
          arrival: '2024-01-07T10:39:00+01:00',
          mode: 'train',
          operator: { name: 'obb' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T20:29:00+01:00', '', 0, null),
          },
          line: {
            id: 'EN 40237',
            type: 'train',
            name: 'EN 40237',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  }
  //Zurich Zagreb 19:40 10:39
  if (origin.name === 'Zürich' && destination.name === 'Zagreb') {
    manualJourneysToReturn.push({
      id: 'manual-zurich-zagreb',
      amount: 89,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(19)
        .minute(40)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: departureDay.clone().hour(19).minute(40).format('YYYY-MM-DDTHH:mm'),
      arrival: nextDay.clone().hour(10).minute(39).format('YYYY-MM-DDTHH:mm'),
      duration: 54000000,
      origin: 'Zurich',
      destination: 'Zagreb',
      legs: [
        {
          origin: { name: 'Zurich HB' },
          destination: { name: 'Zagreb' },
          departure: departureDay.clone().hour(19).minute(40).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(10).minute(39).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'obb' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T19:40:00+01:00', '', 0, null),
          },
          line: {
            id: 'EN 40465',
            reference: '40465',
            type: 'train',
            name: 'EN 40465',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  } else if (origin.name === 'Zagreb' && destination.name === 'Zürich') {
    manualJourneysToReturn.push({
      id: 'manual-zagreb-zurich',
      amount: 89,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(19)
        .minute(39)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: '2024-01-06T19:39:00+01:00',
      arrival: '2024-01-07T10:39:00+01:00',
      duration: 54000000,
      origin: 'Zagreb',
      destination: 'Zurich',
      legs: [
        {
          origin: { name: 'Zagreb' },
          destination: { name: 'Zurich HB' },
          departure: '2024-01-06T19:39:00+01:00',
          arrival: '2024-01-07T10:39:00+01:00',
          mode: 'train',
          operator: { name: 'obb' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T19:39:00+01:00', '', 0, null),
          },
          line: {
            id: 'EN 40465',
            reference: '40465',
            type: 'train',
            name: 'EN 40465',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  }
  //Paris Zagreb via Zurich
  if (origin.name === 'Paris' && destination.name === 'Zagreb') {
    manualJourneysToReturn.push({
      id: 'manual-paris-zurich-zagreb',
      amount: 89,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(14)
        .minute(22)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: departureDay.clone().hour(14).minute(22).format('YYYY-MM-DDTHH:mm'),
      arrival: nextDay.clone().hour(10).minute(39).format('YYYY-MM-DDTHH:mm'),
      duration: 54000000,
      origin: 'Paris',
      destination: 'Zagreb',
      legs: [
        {
          origin: { name: 'Paris Gare de Lyon - Hall 1 & 2' },
          destination: { name: 'Zurich HB' },
          departure: departureDay.clone().hour(14).minute(22).format('YYYY-MM-DDTHH:mm'),
          arrival: departureDay.clone().hour(18).minute(26).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'SNCF' },
          amenities: {
            bike: bikePrice('TGV', '2024-01-06T14:22:00+01:00', '', 0, null),
          },
          line: {
            id: 'TGV 9215',
            reference: '9215',
            type: 'train',
            name: 'TGV Lyria',
          },
        },
        {
          origin: { name: 'Zurich HB' },
          destination: { name: 'Zagreb Glavni kolodvor' },
          departure: departureDay.clone().hour(19).minute(40).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(10).minute(39).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'OBB' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T20:29:00+01:00', '', 0, null),
          },
          line: {
            id: 'EN 40237',
            type: 'train',
            name: 'EN 40237',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  } else if (origin.name === 'Zagreb' && destination.name === 'Paris') {
    manualJourneysToReturn.push({
      id: 'manual-zagreb-zurich-paris',
      amount: 89,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(19)
        .minute(39)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: departureDay.clone().hour(19).minute(39).format('YYYY-MM-DDTHH:mm'),
      arrival: nextDay.clone().hour(12).minute(33).format('YYYY-MM-DDTHH:mm'),
      duration: 54000000,
      origin: 'Zagreb',
      destination: 'Paris',
      legs: [
        {
          origin: { name: 'Zagreb' },
          destination: { name: 'Zurich HB' },
          departure: departureDay.clone().hour(19).minute(39).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(8).minute(38).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'OBB' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T19:39:00+01:00', '', 0, null),
          },
          line: {
            id: 'EN 40237',
            type: 'train',
            name: 'EN 40237',
          },
        },
        {
          origin: { name: 'Zurich HB' },
          destination: { name: 'Paris Gare de Lyon - Hall 1 & 2' },
          departure: nextDay.clone().hour(9).minute(10).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(12).minute(33).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'SNCF' },
          amenities: {
            bike: bikePrice('TGV', '2024-01-07T09:10:00+01:00', '', 0, null),
          },
          line: {
            id: 'TGV 9576',
            reference: '9576',
            type: 'train',
            name: 'TGV INOUI',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  } else if (origin.name === 'Paris' && destination.name === 'København') {
    if (SnalltagetBerlinStockholmAvailable(nextDay.toISOString())) {
      manualJourneysToReturn.push({
        //Paris - Hamburg - Copenhagen (TGV + ICE + Snalltaget)
        id: 'manual-paris-Hamburg-copenhagen-tgv',
        amount: 180,
        link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
          origin.name
        }&stationDestName=${destination.name}&outwardDateTime=${departureDay
          .clone()
          .hour(19)
          .minute(12)
          .format('YYYY-MM-DDTHH:mm')}`,
        departure: departureDay.clone().hour(19).minute(12).format('YYYY-MM-DDTHH:mm'),
        arrival: nextDay.clone().hour(17).minute(7).format('YYYY-MM-DDTHH:mm'),
        duration: 45000000,
        origin: 'Paris',
        destination: 'Copenhagen',
        legs: [
          {
            origin: { name: "Paris Gare de l'Est" },
            destination: { name: 'Mannheim Hbf' },
            departure: departureDay.clone().hour(13).minute(9).format('YYYY-MM-DDTHH:mm'),
            arrival: departureDay.clone().hour(16).minute(15).format('YYYY-MM-DDTHH:mm'),
            mode: 'train',
            operator: { name: 'DB' },
            line: {
              id: 'ICE 9553',
              type: 'train',
              name: 'ICE 9553',
            },
          },
          {
            origin: { name: 'Mannheim Hbf' },
            destination: { name: 'Hamburg Hbf' },
            departure: departureDay
              .clone()
              .hour(17)
              .minute(31)
              .format('YYYY-MM-DDTHH:mm'),
            arrival: departureDay.clone().hour(22).minute(32).format('YYYY-MM-DDTHH:mm'),
            mode: 'train',
            operator: { name: 'DB' },
            line: {
              id: 'ICE 595',
              type: 'train',
              name: 'ICE 595',
            },
          },
          {
            origin: { name: 'Hamburg Hbf' },
            destination: { name: 'Copenhagen' },
            departure: departureDay
              .clone()
              .hour(23)
              .minute(59)
              .format('YYYY-MM-DDTHH:mm'),
            arrival: nextDay.clone().hour(7).minute(0).format('YYYY-MM-DDTHH:mm'),
            mode: 'train',
            operator: { name: 'Snalltaget' },
            line: {
              id: '10300',
              type: 'train',
              name: '10300',
            },
          },
        ],
        mode: 'train',
        operator: 'OBB',
        source: 'obb',
        nighttrain: true,
      });
    }
  }
  //Zagreb to Stuttgart 19:39 08:38
  else if (origin.name === 'Zagreb' && destination.name === 'Stuttgart') {
    manualJourneysToReturn.push({
      id: 'manual-zagreb-stuttgart',
      amount: PRICE_UNKNOWN_CONST,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(19)
        .minute(39)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: '2024-01-06T19:39:00+01:00',
      arrival: '2024-01-07T08:38:00+01:00',
      duration: 45000000,
      origin: 'Zagreb',
      destination: 'Stuttgart',
      legs: [
        {
          origin: { name: 'Zagreb' },
          destination: { name: 'Stuttgart' },
          departure: '2024-01-06T19:39:00+01:00',
          arrival: '2024-01-07T08:38:00+01:00',
          mode: 'train',
          operator: { name: 'obb' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T19:39:00+01:00', '', 0, null),
          },
          line: {
            id: 'EN 414',
            type: 'train',
            name: 'EN 414',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  }
  //Paris - Inverness, 2 legs, Paris-London, London-Inverness
  if (origin.name === 'Paris' && destination.name === 'Inverness') {
    manualJourneysToReturn.push({
      id: 'manual-paris-london-inverness-night',
      amount: 369,
      link: 'https://www.eurostar.com/uk-en/train/france-to-uk/travel-to-inverness',
      departure: departureDay.clone().hour(18).minute(8).format('YYYY-MM-DDTHH:mm:ssZ'),
      arrival: nextDay.clone().hour(8).minute(45).format('YYYY-MM-DDTHH:mm:ssZ'),
      duration: 54000000,
      origin: 'Paris',
      destination: 'Inverness',
      legs: [
        {
          origin: { name: 'Paris Gare du Nord' },
          destination: { name: 'London St Pancras' },
          departure: departureDay
            .clone()
            .hour(18)
            .minute(8)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: departureDay
            .clone()
            .hour(19)
            .minute(39)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          mode: 'train',
          operator: { name: 'Eurostar' },
          line: {
            id: 'ES 9058',
            type: 'train',
            name: 'ES 9058',
          },
        },
        LondonInvernessDirectHighLander(date),
      ],
      mode: 'train',
      operator: 'caledonian sleeper',
      source: 'manual',
      nighttrain: true,
    });
    //paris - london - inverness with eurostar + LNER
    manualJourneysToReturn.push({
      id: 'manual-paris-london-inverness-lner',
      amount: 178,
      link: 'https://www.eurostar.com/uk-en/train/france-to-uk/travel-to-inverness',
      departure: departureDay.clone().hour(8).minute(39).format('YYYY-MM-DDTHH:mm:ssZ'),
      arrival: departureDay.clone().hour(15).minute(8).format('YYYY-MM-DDTHH:mm:ssZ'),
      duration: 54000000,
      origin: 'Paris',
      destination: 'Inverness',
      legs: [
        {
          origin: { name: 'Paris Gare du Nord' },
          destination: { name: 'London St Pancras' },
          departure: departureDay
            .clone()
            .hour(9)
            .minute(30)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: departureDay
            .clone()
            .hour(10)
            .minute(30)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          mode: 'train',
          operator: { name: 'Eurostar' },
          line: {
            id: 'ES 9015',
            type: 'train',
            name: 'ES 9015',
          },
        },
        {
          //London - Inverness with LNER
          origin: { name: 'London Kings Cross' },
          destination: { name: 'Inverness' },
          departure: departureDay
            .clone()
            .hour(12)
            .minute(0)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: departureDay.clone().hour(20).minute(7).format('YYYY-MM-DDTHH:mm:ssZ'),
          mode: 'train',
          operator: { name: 'London Eastern Railway' },
          line: {
            id: 'GR6300',
            type: 'train',
            name: 'LNER',
          },
        },
      ],
      mode: 'train',
      operator: 'caledonian sleeper',
      source: 'manual',
      nighttrain: false,
    });
  } else if (
    //Inverness-London, London-Paris
    origin.name === 'Inverness' &&
    destination.name === 'Paris'
  ) {
    manualJourneysToReturn.push({
      id: 'manual-inverness-london-paris',
      amount: 399,
      link: 'https://www.eurostar.com/uk-en/train/france-to-uk/travel-to-inverness',
      departure: departureDay.clone().hour(20).minute(31).format('YYYY-MM-DDTHH:mm:ssZ'),
      arrival: nextDay.clone().hour(7).minute(30).format('YYYY-MM-DDTHH:mm:ssZ'),
      duration: 54000000,
      origin: 'Inverness',
      destination: 'Paris',
      legs: [
        {
          origin: { name: 'Inverness' },
          destination: { name: 'London Euston' },
          departure: departureDay
            .clone()
            .hour(20)
            .minute(31)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: nextDay.clone().hour(7).minute(15).format('YYYY-MM-DDTHH:mm:ssZ'),
          mode: 'train',
          operator: { name: 'Caledonian Sleeper' },
          line: {
            id: 'Highlander',
            type: 'train',
            name: 'Highlander',
          },
        },
        {
          origin: { name: 'London St Pancras' },
          destination: { name: 'Paris Gare du Nord' },
          departure: nextDay.clone().hour(8).minute(19).format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: nextDay.clone().hour(10).minute(47).format('YYYY-MM-DDTHH:mm:ssZ'),
          mode: 'train',
          operator: { name: 'Eurostar' },
          line: {
            id: 'ES 9058',
            type: 'train',
            name: 'ES 9058',
          },
        },
      ],
      mode: 'train',
      operator: 'caledonian sleeper',
      source: 'manual',
      nighttrain: true,
    });
  }

  //2 legs, Paris-London, London-Edinburgh Waverley
  if (origin.name === 'Paris' && destination.name === 'Edinburgh Waverley') {
    //Paris - London - Edinburgh with Eurostar + LNER
    manualJourneysToReturn.push({
      id: 'manual-paris-london-edinburgh',
      amount: 119,
      link: 'https://www.eurostar.com/uk-en/train/france-to-uk/travel-to-edinburgh',
      departure: departureDay.clone().hour(8).minute(39).format('YYYY-MM-DDTHH:mm:ssZ'),
      arrival: departureDay.clone().hour(15).minute(8).format('YYYY-MM-DDTHH:mm:ssZ'),
      duration: 54000000,
      origin: 'Paris',
      destination: 'Edinburgh Waverley',
      legs: [
        {
          origin: { name: 'Paris Gare du Nord' },
          destination: { name: 'London St Pancras' },
          departure: departureDay
            .clone()
            .hour(8)
            .minute(39)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: departureDay.clone().hour(10).minute(0).format('YYYY-MM-DDTHH:mm:ssZ'),
          mode: 'train',
          operator: { name: 'Eurostar' },
          line: {
            id: 'ES 9013',
            type: 'train',
            name: 'ES 9013',
          },
        },
        {
          //London - Edinburgh with Lumo
          origin: { name: 'London Kings Cross' },
          destination: { name: 'Edinburgh Waverley' },
          departure: departureDay
            .clone()
            .hour(10)
            .minute(45)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: departureDay
            .clone()
            .hour(15)
            .minute(11)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          mode: 'train',
          operator: { name: 'Lumo' },
          line: {
            id: 'LD1102',
            type: 'train',
            name: 'Lumo',
          },
        },
      ],
      mode: 'train',
      operator: 'Eurostar',
      source: 'manual',
      nighttrain: false,
    });
    manualJourneysToReturn.push({
      id: 'manual-paris-london-edinburgh',
      amount: 399,
      link: 'https://www.eurostar.com/uk-en/train/france-to-uk/travel-to-edinburgh',
      departure: departureDay.clone().hour(20).minute(31).format('YYYY-MM-DDTHH:mm:ssZ'),
      arrival: nextDay.clone().hour(7).minute(30).format('YYYY-MM-DDTHH:mm:ssZ'),
      duration: 54000000,
      origin: 'Paris',
      destination: 'Edinburgh',
      legs: [
        {
          origin: { name: 'Paris Gare du Nord' },
          destination: { name: 'London St Pancras' },
          departure: departureDay
            .clone()
            .hour(20)
            .minute(31)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: departureDay.clone().hour(22).minute(0).format('YYYY-MM-DDTHH:mm:ssZ'),
          mode: 'train',
          operator: { name: 'Eurostar' },
          line: {
            id: 'ES 9058',
            type: 'train',
            name: 'ES 9058',
          },
        },
        LondonEdinburghDirectLowLander(date),
      ],
      mode: 'train',
      operator: 'caledonian sleeper',
      source: 'manual',
      nighttrain: true,
    });
  } else if (
    //London-Paris, Edinburgh-London
    origin.name === 'Edinburgh Waverley' &&
    destination.name === 'Paris'
  ) {
    manualJourneysToReturn.push({
      id: 'manual-edinburgh-london-paris',
      amount: 399,
      link: 'https://www.eurostar.com/uk-en/train/france-to-uk/travel-to-edinburgh',
      departure: departureDay.clone().hour(20).minute(31).format('YYYY-MM-DDTHH:mm:ssZ'),
      arrival: nextDay.clone().hour(7).minute(30).format('YYYY-MM-DDTHH:mm:ssZ'),
      duration: 54000000,
      origin: 'Edinburgh',
      destination: 'Paris',
      legs: [
        {
          origin: { name: 'Edinburgh Waverley' },
          destination: { name: 'London Euston' },
          departure: departureDay
            .clone()
            .hour(20)
            .minute(31)
            .format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: nextDay.clone().hour(7).minute(15).format('YYYY-MM-DDTHH:mm:ssZ'),
          mode: 'train',
          operator: { name: 'Caledonian Sleeper' },
          line: {
            id: 'Lowlander',
            type: 'train',
            name: 'Lowlander',
          },
        },
        {
          origin: { name: 'London St Pancras' },
          destination: { name: 'Paris Gare du Nord' },
          departure: nextDay.clone().hour(8).minute(19).format('YYYY-MM-DDTHH:mm:ssZ'),
          arrival: nextDay.clone().hour(10).minute(47).format('YYYY-MM-DDTHH:mm:ssZ'),
          mode: 'train',
          operator: { name: 'Eurostar' },
          line: {
            id: 'ES 9058',
            type: 'train',
            name: 'ES 9058',
          },
        },
      ],
      mode: 'train',
      operator: 'caledonian sleeper',
      source: 'manual',
      nighttrain: true,
    });
  }
  //Hamburg - Vienna, "Nightjet" departure 20:10, arrival 09:17
  if (origin.name === 'Hamburg' && destination.name === 'Wien') {
    manualJourneysToReturn.push({
      id: 'manual-hamburg-vienna',
      amount: 99,
      link: 'https://tickets.oebb.at/en/ticket?stationOrigName=8000150&stationDestName=8100002&outwardDateTime=2024-01-06T20:10',
      departure: '2024-01-06T20:10:00+01:00',
      arrival: '2024-01-07T09:17:00+01:00',
      duration: 45000000,
      origin: 'Hamburg',
      destination: 'Wien',
      legs: [
        {
          origin: { name: 'Hamburg Hbf' },
          destination: { name: 'Wien Hbf' },
          departure: '2024-01-06T20:10:00+01:00',
          arrival: '2024-01-07T09:17:00+01:00',
          mode: 'train',
          operator: { name: 'OBB' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T20:10:00+01:00', '', 0, null),
          },
          line: {
            id: 'NJ 491',
            type: 'train',
            name: 'NJ 491',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'obb',
      nighttrain: true,
    });
  }
  //Wien - Hamburg, "Nightjet" departure 20:10, arrival 08:47
  else if (origin.name === 'Wien' && destination.name === 'Hamburg') {
    manualJourneysToReturn.push({
      id: 'manual-vienna-hamburg',
      amount: 119,
      link: 'https://tickets.oebb.at/en/ticket?stationOrigName=8100002&stationDestName=8000150&outwardDateTime=2024-01-06T20:10',
      departure: '2024-01-06T20:10:00+01:00',
      arrival: '2024-01-07T08:47:00+01:00',
      duration: 45000000,
      origin: 'Wien',
      destination: 'Hamburg',
      legs: [
        {
          origin: { name: 'Wien Hbf' },
          destination: { name: 'Hamburg Hbf' },
          departure: '2024-01-06T20:10:00+01:00',
          arrival: '2024-01-07T08:47:00+01:00',
          mode: 'train',
          operator: { name: 'OBB' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T20:10:00+01:00', '', 0, null),
          },
          line: {
            id: 'NJ 490',
            type: 'train',
            name: 'NJ 490',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'obb',
      nighttrain: true,
    });
  }
  //Hamburg - Innsbruck, "Nightjet" departure 20:10, arrival 09:14
  if (origin.name === 'Hamburg' && destination.name === 'Innsbruck') {
    manualJourneysToReturn.push({
      id: 'manual-hamburg-innsbruck',
      amount: 99,
      link: 'https://tickets.oebb.at/en/ticket?stationOrigName=8000150&stationDestName=8100003&outwardDateTime=2024-01-06T20:10',
      departure: '2024-01-06T20:10:00+01:00',
      arrival: '2024-01-07T09:14:00+01:00',
      duration: 45000000,
      origin: 'Hamburg',
      destination: 'Innsbruck',
      legs: [
        {
          origin: { name: 'Hamburg Hbf' },
          destination: { name: 'Innsbruck Hbf' },
          departure: '2024-01-06T20:10:00+01:00',
          arrival: '2024-01-07T09:14:00+01:00',
          mode: 'train',
          operator: { name: 'OBB' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T20:10:00+01:00', '', 0, null),
          },
          line: {
            id: 'NJ 40491',
            type: 'train',
            name: 'NJ 40491',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'obb',
      nighttrain: true,
    });
  }
  //Innsbruck - Hamburg, "Nightjet" departure 20:44, arrival 08:47
  else if (origin.name === 'Innsbruck' && destination.name === 'Hamburg') {
    manualJourneysToReturn.push({
      id: 'manual-innsbruck-hamburg',
      amount: 99,
      link: 'https://tickets.oebb.at/en/ticket?stationOrigName=8100003&stationDestName=8000150&outwardDateTime=2024-01-06T20:44',
      departure: '2024-01-06T20:44:00+01:00',
      arrival: '2024-01-07T08:47:00+01:00',
      duration: 45000000,
      origin: 'Innsbruck',
      destination: 'Hamburg',
      legs: [
        {
          origin: { name: 'Innsbruck Hbf' },
          destination: { name: 'Hamburg Hbf' },
          departure: '2024-01-06T20:44:00+01:00',
          arrival: '2024-01-07T08:47:00+01:00',
          mode: 'train',
          operator: { name: 'OBB' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T20:44:00+01:00', '', 0, null),
          },
          line: {
            id: 'NJ 40420',
            type: 'train',
            name: 'NJ 40420',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'obb',
      nighttrain: true,
    });
  }
  //Bregenz - Wien, "Nightjet" departure 21:40, arrival 06:58
  if (origin.name === 'Bregenz' && destination.name === 'Wien') {
    manualJourneysToReturn.push({
      id: 'manual-bregenz-vienna',
      amount: 99,
      link: 'https://tickets.oebb.at/en/ticket?stationOrigName=8100001&stationDestName=8100002&outwardDateTime=2024-01-06T21:40',
      departure: '2024-01-06T21:40:00+01:00',
      arrival: '2024-01-07T06:58:00+01:00',
      duration: 45000000,
      origin: 'Bregenz',
      destination: 'Wien',
      legs: [
        {
          origin: { name: 'Bregenz' },
          destination: { name: 'Wien Hbf' },
          departure: '2024-01-06T21:40:00+01:00',
          arrival: '2024-01-07T06:58:00+01:00',
          mode: 'train',
          operator: { name: 'OBB' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T21:40:00+01:00', '', 0, null),
          },
          line: {
            id: 'NJ 447',
            type: 'train',
            name: 'NJ 447',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  }
  //Wien - Bregenz, "Nightjet" departure 22:55, arrival 08:59
  else if (origin.name === 'Wien' && destination.name === 'Bregenz') {
    manualJourneysToReturn.push({
      id: 'manual-vienna-bregenz',
      amount: 99,
      link: 'https://tickets.oebb.at/en/ticket?stationOrigName=8100002&stationDestName=8100001&outwardDateTime=2024-01-06T22:55',
      departure: '2024-01-06T22:55:00+01:00',
      arrival: '2024-01-07T08:59:00+01:00',
      duration: 45000000,
      origin: 'Wien',
      destination: 'Bregenz',
      legs: [
        {
          origin: { name: 'Wien Hbf' },
          destination: { name: 'Bregenz' },
          departure: '2024-01-06T22:55:00+01:00',
          arrival: '2024-01-07T08:59:00+01:00',
          mode: 'train',
          operator: { name: 'OBB' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T22:55:00+01:00', 'NJ 446', 0, null),
          },
          line: {
            id: 'NJ 446',
            type: 'train',
            name: 'NJ 446',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  }
  //Olso - Bergen, Vy, departure 23:03, arrival 06:27
  if (origin.name === 'Oslo' && destination.name === 'Bergen') {
    manualJourneysToReturn.push({
      id: 'manual-oslo-bergen',
      amount: 49,
      link: 'https://www.vy.no/en',
      departure: '2024-01-06T23:03:00+01:00',
      arrival: '2024-01-07T06:27:00+01:00',
      duration: 45000000,
      origin: 'Oslo',
      destination: 'Bergen',
      legs: [
        {
          origin: { name: 'Oslo S' },
          destination: { name: 'Bergen' },
          departure: '2024-01-06T23:03:00+01:00',
          arrival: '2024-01-07T06:27:00+01:00',
          mode: 'train',
          operator: { name: 'Vy' },
          line: {
            id: '605',
            type: 'train',
            name: 'VY 605',
          },
        },
      ],
      mode: 'train',
      operator: 'Vy',
      source: 'manual',
      nighttrain: true,
    });
  } else if (origin.name === 'Bergen' && destination.name === 'Oslo') {
    manualJourneysToReturn.push({
      id: 'manual-bergen-oslo',
      amount: 49,
      link: 'https://www.vy.no/en',
      departure: '2024-01-06T23:03:00+01:00',
      arrival: '2024-01-07T06:27:00+01:00',
      duration: 45000000,
      origin: 'Bergen',
      destination: 'Oslo',
      legs: [
        {
          origin: { name: 'Bergen' },
          destination: { name: 'Oslo S' },
          departure: '2024-01-06T23:03:00+01:00',
          arrival: '2024-01-07T06:27:00+01:00',
          mode: 'train',
          operator: { name: 'Vy' },
          line: {
            id: '606',
            type: 'train',
            name: 'VY 606',
          },
        },
      ],
      mode: 'train',
      operator: 'Vy',
      source: 'manual',
      nighttrain: true,
    });
  }

  // Trieste Centrale to Roma Termini
  if (origin.name === 'Trieste' && destination.name === 'Roma') {
    manualJourneysToReturn.push({
      id: 'manual-trieste-centrale-roma-termini',
      amount: 69,
      link: 'https://www.trenitalia.com/en.html',
      departure: '2024-01-06T19:11:00+01:00',
      arrival: '2024-01-07T06:35:00+01:00',
      duration: 39840000,
      origin: 'Trieste Centrale',
      destination: 'Roma Termini',
      legs: [
        {
          origin: { name: 'Trieste Centrale' },
          destination: { name: 'Roma Termini' },
          departure: '2024-01-06T19:11:00+01:00',
          arrival: '2024-01-07T06:35:00+01:00',
          mode: 'train',
          operator: { name: 'FS' },
          line: {
            id: '770',
            type: 'train',
            name: 'ICN 770',
          },
        },
      ],
      mode: 'train',
      operator: 'FS',
      source: 'Trenitalia',
      nighttrain: true,
    });
  }

  // Roma Termini to Trieste Centrale
  else if (origin.name === 'Roma' && destination.name === 'Trieste') {
    manualJourneysToReturn.push({
      id: 'manual-roma-termini-trieste-centrale',
      amount: 69,
      link: 'https://www.trenitalia.com/en.html',
      departure: '2024-01-06T22:35:00+01:00',
      arrival: '2024-01-07T09:55:00+01:00',
      duration: 39600000,
      origin: 'Roma Termini',
      destination: 'Trieste Centrale',
      legs: [
        {
          origin: { name: 'Roma Termini' },
          destination: { name: 'Trieste Centrale' },
          departure: '2024-01-06T22:35:00+01:00',
          arrival: '2024-01-07T09:55:00+01:00',
          mode: 'train',
          operator: { name: 'FS' },
          line: {
            id: 'ICN 774',
            type: 'train',
            name: 'ICN 774',
          },
        },
      ],
      mode: 'train',
      operator: 'FS',
      source: 'Trenitalia',
      nighttrain: true,
    });
  }
  // Torino Porta Susa to Salerno
  if (
    (origin.name === 'Torino Porta Susa' || origin.name === 'Torino') &&
    destination.name === 'Salerno'
  ) {
    manualJourneysToReturn.push({
      id: 'manual-torino-porta-susa-salerno',
      amount: 69,
      link: 'https://www.trenitalia.com/en.html',
      departure: '2024-01-06T20:40:00+01:00',
      arrival: '2024-01-07T09:46:00+01:00',
      duration: 47160000,
      origin: 'Torino Porta Susa',
      destination: 'Salerno',
      legs: [
        {
          origin: { name: 'Torino Porta Susa' },
          destination: { name: 'Salerno' },
          departure: '2024-01-06T20:40:00+01:00',
          arrival: '2024-01-07T09:46:00+01:00',
          mode: 'train',
          operator: { name: 'FS' },
          line: {
            id: 'ICN 797',
            type: 'train',
            name: 'ICN 797',
          },
        },
      ],
      mode: 'train',
      operator: 'FS',
      source: 'Trenitalia',
      nighttrain: true,
    });
  }

  // Salerno to Torino Porta Susa
  else if (
    origin.name === 'Salerno' &&
    (destination.name === 'Torino Porta Susa' || destination.name === 'Torino')
  ) {
    manualJourneysToReturn.push({
      id: 'manual-salerno-torino-porta-susa',
      amount: 69,
      link: 'https://www.trenitalia.com/en.html',
      departure: '2024-01-06T20:38:00+01:00',
      arrival: '2024-01-07T09:05:00+01:00',
      duration: 45220000,
      origin: 'Salerno',
      destination: 'Torino Porta Susa',
      legs: [
        {
          origin: { name: 'Salerno' },
          destination: { name: 'Torino Porta Susa' },
          departure: '2024-01-06T20:38:00+01:00',
          arrival: '2024-01-07T09:05:00+01:00',
          mode: 'train',
          operator: { name: 'FS' },
          line: {
            id: 'ICN 798',
            type: 'train',
            name: 'ICN 798',
          },
        },
      ],
      mode: 'train',
      operator: 'FS',
      source: 'Trenitalia',
      nighttrain: true,
    });
  }
  // Palermo Centrale to Milano Centrale
  if (origin.name === 'Palermo' && destination.name === 'Milano Centrale') {
    manualJourneysToReturn.push({
      id: 'manual-palermo-centrale-milano-centrale',
      amount: 116,
      link: 'https://www.trenitalia.com/en.html',
      departure: '2024-01-06T12:58:00+01:00',
      arrival: '2024-01-07T10:10:00+01:00',
      duration: 75720000,
      origin: 'Palermo Centrale',
      destination: 'Milano Centrale',
      legs: [
        {
          origin: { name: 'Palermo Centrale' },
          destination: { name: 'Milano Centrale' },
          departure: '2024-01-06T12:58:00+01:00',
          arrival: '2024-01-07T10:10:00+01:00',
          mode: 'train',
          operator: { name: 'FS' },
          line: {
            id: 'ICN 1964',
            type: 'train',
            name: 'ICN 1964',
          },
        },
      ],
      mode: 'train',
      operator: 'FS',
      source: 'Trenitalia',
      nighttrain: true,
    });
  }
  // Milano Centrale to Palermo Centrale
  else if (origin.name === 'Milano Centrale' && destination.name === 'Palermo') {
    manualJourneysToReturn.push({
      id: 'manual-milano-centrale-palermo-centrale',
      amount: 116,
      link: 'https://www.trenitalia.com/en.html',
      departure: '2024-01-06T20:10:00+01:00',
      arrival: '2024-01-07T16:55:00+01:00',
      duration: 74700000,
      origin: 'Milano Centrale',
      destination: 'Palermo Centrale',
      legs: [
        {
          origin: { name: 'Milano Centrale' },
          destination: { name: 'Palermo Centrale' },
          departure: '2024-01-06T20:10:00+01:00',
          arrival: '2024-01-07T16:55:00+01:00',
          mode: 'train',
          operator: { name: 'FS' },
          line: {
            id: 'ICN 1967',
            type: 'train',
            name: 'ICN 1967',
          },
        },
      ],
      mode: 'train',
      operator: 'FS',
      source: 'Trenitalia',
      nighttrain: true,
    });
  }
  //3 legs, Paris-Geneva, Geneva-Milano, Milano-Palermo
  if (origin.name === 'Paris' && destination.name === 'Palermo') {
    manualJourneysToReturn.push({
      id: 'manual-paris-palermo',
      amount: PRICE_UNKNOWN_CONST,
      link: 'https://www.trenitalia.com/en.html',
      departure: '2024-01-06T19:23:00+01:00',
      arrival: '2024-01-07T16:55:00+01:00',
      duration: 74700000,
      origin: 'Paris',
      destination: 'Palermo',
      legs: [
        {
          origin: { name: 'Paris Gare de Lyon' },
          destination: { name: 'Geneva' },
          departure: '2024-01-06T08:18:00+01:00',
          arrival: '2024-01-07T11:29:00+01:00',
          mode: 'train',
          operator: { name: 'SNCF' },
          line: {
            id: 'TGV 9763',
            type: 'train',
            name: 'TGV 9763',
          },
        },
        {
          origin: { name: 'Geneva' },
          destination: { name: 'Milano Centrale' },
          departure: '2024-01-07T13:39:00+01:00',
          arrival: '2024-01-07T17:43:00+01:00',
          mode: 'train',
          operator: { name: 'FS' },
          line: {
            id: 'EC 39',
            type: 'train',
            name: 'EC 39',
          },
        },
        {
          origin: { name: 'Milano Centrale' },
          destination: { name: 'Palermo' },
          departure: '2024-01-07T20:10:00+01:00',
          arrival: '2024-01-08T16:55:00+01:00',
          mode: 'train',
          operator: { name: 'FS' },
          line: {
            id: 'ICN 1963',
            type: 'train',
            name: 'ICN 1963',
          },
        },
      ],
      mode: 'train',
      operator: 'FS',
      source: 'manual',
      nighttrain: true,
    });
  }
  if (origin.name === 'Milano Centrale' && destination.name === 'Lecce (città)') {
    manualJourneysToReturn.push({
      id: 'manual-milano-centrale-lecce',
      amount: 69,
      link: 'https://www.trenitalia.com/en.html',
      departure: '2024-01-06T21:15:00+01:00',
      arrival: '2024-01-07T07:00:00+01:00',
      duration: 34200000,
      origin: 'Milano Centrale',
      destination: 'Lecce',
      legs: [
        {
          origin: { name: 'Milano Centrale' },
          destination: { name: 'Lecce' },
          departure: '2024-01-06T21:15:00+01:00',
          arrival: '2024-01-07T07:00:00+01:00',
          mode: 'train',
          operator: { name: 'FS' },
          line: {
            id: 'ICN 765',
            type: 'train',
            name: 'ICN 765',
          },
        },
      ],
      mode: 'train',
      operator: 'FS',
      source: 'Trenitalia',
      nighttrain: true,
    });
  } else if (origin.name === 'Lecce (città)' && destination.name === 'Milano Centrale') {
    manualJourneysToReturn.push({
      id: 'manual-lecce-milano-centrale',
      amount: 69,
      link: 'https://www.trenitalia.com/en.html',
      departure: '2024-01-06T18:31:00+01:00',
      arrival: '2024-01-07T07:05:00+01:00',
      duration: 44760000,
      origin: 'Lecce',
      destination: 'Milano Centrale',
      legs: [
        {
          origin: { name: 'Lecce' },
          destination: { name: 'Milano Centrale' },
          departure: '2024-01-06T18:31:00+01:00',
          arrival: '2024-01-07T07:05:00+01:00',
          mode: 'train',
          operator: { name: 'FS' },
          line: {
            id: 'ICN 758',
            type: 'train',
            name: 'ICN 758',
          },
        },
      ],
      mode: 'train',
      operator: 'FS',
      source: 'Trenitalia',
      nighttrain: true,
    });
  }

  //if not saturdays
  if (departureDay.day() !== 6) {
    if (origin.name === 'London' && destination.name === 'Fort William') {
      manualJourneysToReturn.push({
        id: 'manual-london-fort-william',
        amount: 350,
        link: `https://www.sleeper.scot/booking?origin=1072&destination=8827&adults=1&outwardDate=${moment(
          date
        ).format('YYYY-MM-DD')}`,
        departure: '2024-01-06T21:15:00+01:00',
        arrival: '2024-01-07T10:00:00+01:00',
        duration: 45000000,
        origin: 'London Euston',
        destination: 'Fort William / An Gearasdan',
        legs: [LondonFortWilliamDirectHighLander(date)],
        mode: 'train',
        operator: 'Caledonian Sleeper',
        source: 'caledoniansleeper',
        nighttrain: true,
      });
    } else if (origin.name === 'Fort William' && destination.name === 'London') {
      manualJourneysToReturn.push({
        id: 'manual-fort-william-london',
        amount: 350,
        link: `https://www.sleeper.scot/booking?origin=8827&destination=1072&adults=1&outwardDate=${moment(
          date
        ).format('YYYY-MM-DD')}`,
        departure: '2024-01-06T19:50:00+01:00',
        arrival: '2024-01-07T08:00:00+01:00',
        duration: 45000000,
        origin: 'Fort William / An Gearasdan',
        destination: 'London Euston',
        legs: [
          {
            origin: { name: 'Fort William / An Gearasdan' },
            destination: { name: 'London Euston' },
            departure: '2024-01-06T19:50:00+01:00',
            arrival: '2024-01-07T08:00:00+01:00',
            mode: 'train',
            operator: { name: 'Caledonian Sleeper' },
            line: {
              id: 'Highlander',
              type: 'train',
              name: 'Highlander',
            },
          },
        ],
        mode: 'train',
        operator: 'Caledonian Sleeper',
        source: 'caledoniansleeper',
        nighttrain: true,
      });
    }
    if (origin.name === 'London' && destination.name === 'Inverness') {
      manualJourneysToReturn.push({
        id: 'manual-london-inverness',
        amount: 350,
        link: `https://www.sleeper.scot/booking?origin=1072&destination=8649&adults=1&outwardDate=${moment(
          date
        ).format('YYYY-MM-DD')}`,
        departure: departureDay.clone().hour(21).minute(15).format('YYYY-MM-DDTHH:mm'),
        arrival: nextDay.clone().hour(8).minute(45).format('YYYY-MM-DDTHH:mm'),
        duration: 45000000,
        origin: 'London Euston',
        destination: 'Inverness / Inbhir Nis',
        legs: [LondonInvernessDirectHighLander(date)],
        mode: 'train',
        operator: 'Caledonian Sleeper',
        source: 'caledoniansleeper',
        nighttrain: true,
      });
    } else if (origin.name === 'Inverness' && destination.name === 'London') {
      manualJourneysToReturn.push({
        id: 'manual-inverness-london',
        amount: 350,
        link: `https://www.sleeper.scot/booking?origin=8649&destination=1072&adults=1&outwardDate=${moment(
          date
        ).format('YYYY-MM-DD')}`,
        departure: '2024-01-06T20:45:00+01:00',
        arrival: '2024-01-07T08:00:00+01:00',
        duration: 45000000,
        origin: 'Inverness / Inbhir Nis',
        destination: 'London Euston',
        legs: [
          {
            origin: { name: 'Inverness / Inbhir Nis' },
            destination: { name: 'London Euston' },
            departure: '2024-01-06T20:45:00+01:00',
            arrival: '2024-01-07T08:00:00+01:00',
            mode: 'train',
            operator: { name: 'Caledonian Sleeper' },
            line: {
              id: 'Highlander',
              type: 'train',
              name: 'Highlander',
            },
          },
        ],
        mode: 'train',
        operator: 'Caledonian Sleeper',
        source: 'caledoniansleeper',
        nighttrain: true,
      });
    } else if (origin.name === 'London' && destination.name === 'Aberdeen') {
      manualJourneysToReturn.push({
        id: 'manual-london-aberdeen',
        amount: 350,
        link: `https://www.sleeper.scot/booking?origin=1072&destination=8976&adults=1&outwardDate=${moment(
          date
        ).format('YYYY-MM-DD')}`,
        departure: '2024-01-06T21:15:00+01:00',
        arrival: '2024-01-07T07:40:00+01:00',
        duration: 45000000,
        origin: 'London Euston',
        destination: 'Aberdeen',
        legs: [LondonAberdeenDirectHighLander(date)],
        mode: 'train',
        operator: 'Caledonian Sleeper',
        source: 'caledoniansleeper',
        nighttrain: true,
      });
    } else if (origin.name === 'Aberdeen' && destination.name === 'London') {
      manualJourneysToReturn.push({
        id: 'manual-aberdeen-london',
        amount: 350,
        link: `https://www.sleeper.scot/booking?origin=8976&destination=1072&adults=1&outwardDate=${moment(
          date
        ).format('YYYY-MM-DD')}`,
        departure: '2024-01-06T21:43:00+01:00',
        arrival: '2024-01-07T08:00:00+01:00',
        duration: 45000000,
        origin: 'Aberdeen',
        destination: 'London Euston',
        legs: [
          {
            origin: { name: 'Aberdeen' },
            destination: { name: 'London Euston' },
            departure: '2024-01-06T21:43:00+01:00',
            arrival: '2024-01-07T08:00:00+01:00',
            mode: 'train',
            operator: { name: 'Caledonian Sleeper' },
            line: {
              id: 'Highlander',
              type: 'train',
              name: 'Highlander',
            },
          },
        ],
        mode: 'train',
        operator: 'Caledonian Sleeper',
        source: 'caledoniansleeper',
        nighttrain: true,
      });
    }
    //Paris - Aberdeen (Eurostar + Caledonian Sleeper)
    if (origin.name === 'Paris' && destination.name === 'Aberdeen') {
      manualJourneysToReturn.push({
        id: 'manual-paris-aberdeen-caledonian',
        amount: 350,
        link: `https://www.sleeper.scot/booking?origin=1072&destination=8976&adults=1&outwardDate=${moment(
          date
        ).format('YYYY-MM-DD')}`,
        departure: departureDay.clone().hour(20).minute(31).format('YYYY-MM-DDTHH:mm'),
        arrival: nextDay.clone().hour(7).minute(40).format('YYYY-MM-DDTHH:mm'),
        duration: 45000000,
        origin: 'Paris Gare de Lyon',
        destination: 'Aberdeen',
        legs: [
          {
            origin: { name: 'Paris Gare du Nord' },
            destination: { name: 'London St Pancras' },
            departure: departureDay.clone().hour(18).minute(8).format('YYYY-MM-DDTHH:mm'),
            arrival: departureDay.clone().hour(19).minute(39).format('YYYY-MM-DDTHH:mm'),
            mode: 'train',
            operator: { name: 'Eurostar' },
            line: {
              id: 'ES 9051',
              type: 'train',
              name: 'ES 9051',
            },
          },
          LondonAberdeenDirectHighLander(date),
        ],
        mode: 'train',
        operator: 'Caledonian Sleeper',
        source: 'manual',
        nighttrain: true,
      });
      // Paris - Edinburgh - Aberdeen ( Eurostar + Lumo + Scotrail)
      manualJourneysToReturn.push({
        id: 'manual-paris-aberdeen-lumo',
        amount: 168,
        link: `https://www.lumo.co.uk/`,
        departure: departureDay.clone().hour(8).minute(42).format('YYYY-MM-DDTHH:mm'),
        arrival: nextDay.clone().hour(17).minute(55).format('YYYY-MM-DDTHH:mm'),
        duration: 45000000,
        origin: 'Paris Gare du Nord',
        destination: 'Aberdeen',
        legs: [
          {
            origin: { name: 'Paris Gare du Nord' },
            destination: { name: 'London St Pancras' },
            departure: departureDay.clone().hour(8).minute(42).format('YYYY-MM-DDTHH:mm'),
            arrival: departureDay.clone().hour(10).minute(0).format('YYYY-MM-DDTHH:mm'),
            mode: 'train',
            operator: { name: 'Eurostar' },
            line: {
              id: 'ES 9051',
              type: 'train',
              name: 'ES 9051',
            },
          },
          {
            origin: { name: 'London Kings Cross' },
            destination: { name: 'Edinburgh Waverley' },
            departure: departureDay
              .clone()
              .hour(10)
              .minute(45)
              .format('YYYY-MM-DDTHH:mm'),
            arrival: nextDay.clone().hour(15).minute(11).format('YYYY-MM-DDTHH:mm'),
            mode: 'train',
            operator: { name: 'Lumo' },
            line: {
              id: 'Lumo 1A',
              type: 'train',
              name: 'Lumo 1A',
            },
          },
          {
            origin: { name: 'Edinburgh Waverley' },
            destination: { name: 'Aberdeen' },
            departure: nextDay.clone().hour(15).minute(30).format('YYYY-MM-DDTHH:mm'),
            arrival: nextDay.clone().hour(17).minute(55).format('YYYY-MM-DDTHH:mm'),
            mode: 'train',
            operator: { name: 'Scotrail' },
            line: {
              id: 'SR 1A',
              type: 'train',
              name: 'SR 1A',
            },
          },
        ],
        mode: 'train',
        operator: 'Lumo',
        source: 'manual',
        nighttrain: true,
      });
    }

    if (origin.name === 'London' && destination.name === 'Edinburgh Waverley') {
      manualJourneysToReturn.push({
        id: 'manual-london-edinburgh',
        amount: 350,
        link: `https://www.sleeper.scot/booking?origin=1072&destination=9328&adults=1&outwardDate=${moment(
          date
        ).format('YYYY-MM-DD')}`,
        departure: '2024-01-06T23:45:00+01:00',
        arrival: '2024-01-07T07:30:00+01:00',
        duration: 45000000,
        origin: 'London Euston',
        destination: 'Edinburgh Waverley',
        legs: [LondonEdinburghDirectLowLander(date)],
        mode: 'train',
        operator: 'Caledonian Sleeper',
        source: 'caledoniansleeper',
        nighttrain: true,
      });
    } else if (origin.name === 'Edinburgh Waverley' && destination.name === 'London') {
      manualJourneysToReturn.push({
        id: 'manual-edinburgh-london',
        amount: 350,
        link: `https://www.sleeper.scot/booking?origin=9328&destination=1072&adults=1&outwardDate=${moment(
          date
        ).format('YYYY-MM-DD')}`,
        departure: '2024-01-06T23:40:00+01:00',
        arrival: '2024-01-07T07:15:00+01:00',
        duration: 45000000,
        origin: 'Edinburgh Waverley',
        destination: 'London Euston',
        legs: [
          {
            origin: { name: 'Edinburgh Waverley' },
            destination: { name: 'London Euston' },
            departure: '2024-01-06T23:40:00+01:00',
            arrival: '2024-01-07T07:15:00+01:00',
            mode: 'train',
            operator: { name: 'Caledonian Sleeper' },
            line: {
              id: 'Lowlander',
              type: 'train',
              name: 'Lowlander',
            },
          },
        ],
        mode: 'train',
        operator: 'Caledonian Sleeper',
        source: 'caledoniansleeper',
        nighttrain: true,
      });
    }
    if (origin.name === 'Paris' && destination.name === 'Glasgow') {
      manualJourneysToReturn.push({
        id: 'manual-paris-london-glasgow',
        amount: 119,
        link: 'https://www.eurostar.com/uk-en/train/france-to-uk/travel-to-edinburgh',
        departure: departureDay.clone().hour(8).minute(39).format('YYYY-MM-DDTHH:mm:ssZ'),
        arrival: nextDay.clone().hour(15).minute(8).format('YYYY-MM-DDTHH:mm:ssZ'),
        duration: 54000000,
        origin: 'Paris',
        destination: 'Glasgow Central',
        legs: [
          {
            origin: { name: 'Paris Gare du Nord' },
            destination: { name: 'London St Pancras' },
            departure: departureDay
              .clone()
              .hour(8)
              .minute(39)
              .format('YYYY-MM-DDTHH:mm:ssZ'),
            arrival: departureDay
              .clone()
              .hour(10)
              .minute(0)
              .format('YYYY-MM-DDTHH:mm:ssZ'),
            mode: 'train',
            operator: { name: 'Eurostar' },
            line: {
              id: 'ES 9013',
              type: 'train',
              name: 'ES 9013',
            },
          },
          {
            //London - Edinburgh with LNER
            origin: { name: 'London Euston' },
            destination: { name: 'Glasgow Central' },
            departure: departureDay
              .clone()
              .hour(10)
              .minute(30)
              .format('YYYY-MM-DDTHH:mm:ssZ'),
            arrival: departureDay
              .clone()
              .hour(15)
              .minute(0)
              .format('YYYY-MM-DDTHH:mm:ssZ'),
            mode: 'train',
            operator: { name: 'Avanti West Coast' },
            line: {
              id: 'VT6080',
              type: 'train',
              name: 'Avanti West Coast',
            },
          },
        ],
        mode: 'train',
        operator: 'Eurostar',
        source: 'manual',
        nighttrain: false,
      });
      manualJourneysToReturn.push({
        id: 'manual-paris-glasgow',
        amount: 359,
        link: `https://www.sleeper.scot/booking?origin=0080&destination=0433&adults=1&outwardDate=${moment(
          date
        ).format('YYYY-MM-DD')}`,
        departure: departureDay.clone().hour(20).minute(31).format('YYYY-MM-DDTHH:mm'),
        arrival: nextDay.clone().hour(7).minute(30).format('YYYY-MM-DDTHH:mm'),
        duration: 45000000,
        origin: 'Paris Gare de Lyon',
        destination: 'Glasgow Central',
        legs: [
          {
            origin: { name: 'Paris Gare du Nord' },
            destination: { name: 'London St Pancras' },
            departure: departureDay
              .clone()
              .hour(20)
              .minute(31)
              .format('YYYY-MM-DDTHH:mm'),
            arrival: departureDay.clone().hour(22).minute(0).format('YYYY-MM-DDTHH:mm'),
            mode: 'train',
            operator: { name: 'Eurostar' },
            line: {
              id: 'ES 9058',
              type: 'train',
              name: 'ES 9058',
            },
          },
          LondonGlasgowDirectLowLander(date),
        ],
        mode: 'train',
        operator: 'Caledonian Sleeper',
        source: 'manual',
        nighttrain: true,
      });
    } else if (origin.name === 'Glasgow' && destination.name === 'Paris') {
      manualJourneysToReturn.push({
        id: 'manual-glasgow-paris',
        amount: 359,
        link: `https://www.sleeper.scot/booking?origin=0433&destination=0080&adults=1&outwardDate=${moment(
          date
        ).format('YYYY-MM-DD')}`,
        departure: departureDay.clone().hour(23).minute(40).format('YYYY-MM-DDTHH:mm'),
        arrival: nextDay.clone().hour(11).minute(29).format('YYYY-MM-DDTHH:mm'),
        duration: 45000000,
        origin: 'Glasgow Central',
        destination: 'Paris Gare de Lyon',
        legs: [
          {
            origin: { name: 'Glasgow Central' },
            destination: { name: 'London Euston' },
            departure: departureDay
              .clone()
              .hour(23)
              .minute(40)
              .format('YYYY-MM-DDTHH:mm'),
            arrival: nextDay.clone().hour(7).minute(15).format('YYYY-MM-DDTHH:mm'),
            mode: 'train',
            operator: { name: 'Caledonian Sleeper' },
            line: {
              id: 'Lowlander',
              type: 'train',
              name: 'Lowlander',
            },
          },
          {
            origin: { name: 'London St Pancras' },
            destination: { name: 'Paris Gare du Nord' },
            departure: nextDay.clone().hour(8).minute(7).format('YYYY-MM-DDTHH:mm'),
            arrival: nextDay.clone().hour(11).minute(29).format('YYYY-MM-DDTHH:mm'),
            mode: 'train',
            operator: { name: 'Eurostar' },
            line: {
              id: 'ES 9059',
              type: 'train',
              name: 'ES 9059',
            },
          },
        ],
        mode: 'train',
        operator: 'Caledonian Sleeper',
        source: 'caledoniansleeper',
        nighttrain: true,
      });
    }

    if (origin.name === 'London' && destination.name === 'Glasgow') {
      manualJourneysToReturn.push({
        id: 'manual-london-glasgow',
        amount: 350,
        link: `https://www.sleeper.scot/booking?origin=1072&destination=0433&adults=1&outwardDate=${moment(
          date
        ).format('YYYY-MM-DD')}`,
        departure: departureDay.clone().hour(23).minute(45).format('YYYY-MM-DDTHH:mm'),
        arrival: nextDay.clone().hour(7).minute(30).format('YYYY-MM-DDTHH:mm'),
        duration: 45000000,
        origin: 'London Euston',
        destination: 'Glasgow Central',
        legs: [LondonGlasgowDirectLowLander(date)],
        mode: 'train',
        operator: 'Caledonian Sleeper',
        source: 'caledoniansleeper',
        nighttrain: true,
      });
    } else if (origin.name === 'Glasgow' && destination.name === 'London') {
      manualJourneysToReturn.push({
        id: 'manual-glasgow-london',
        amount: 350,
        link: `https://www.sleeper.scot/booking?origin=0433&destination=1072&adults=1&outwardDate=${moment(
          date
        ).format('YYYY-MM-DD')}`,
        departure: departureDay.clone().hour(23).minute(40).format('YYYY-MM-DDTHH:mm'),
        arrival: nextDay.clone().hour(7).minute(15).format('YYYY-MM-DDTHH:mm'),
        duration: 45000000,
        origin: 'Glasgow Central',
        destination: 'London Euston',
        legs: [
          {
            origin: { name: 'Glasgow Central' },
            destination: { name: 'London Euston' },
            departure: departureDay
              .clone()
              .hour(23)
              .minute(40)
              .format('YYYY-MM-DDTHH:mm'),
            arrival: nextDay.clone().hour(7).minute(15).format('YYYY-MM-DDTHH:mm'),
            mode: 'train',
            operator: { name: 'Caledonian Sleeper' },
            line: {
              id: 'Lowlander',
              type: 'train',
              name: 'Lowlander',
            },
          },
        ],
        mode: 'train',
        operator: 'Caledonian Sleeper',
        source: 'caledoniansleeper',
        nighttrain: true,
      });
    }

    if (origin.name === 'London' && destination.name === 'Penzance') {
      manualJourneysToReturn.push({
        id: 'manual-london-penzance',
        amount: 95,
        link: `https://www.gwr.com/tickets/#/3087/3526/${departureDay.format(
          'YYYY-MM-DD'
        )}/D23:00/N/N/1/0/0/1/1/N/N/N`,
        departure: '2024-01-06T23:45:00+01:00',
        arrival: '2024-01-07T07:54:00+01:00',
        duration: 45000000,
        origin: 'London Paddington',
        destination: 'Penzance',
        legs: [
          {
            origin: { name: 'London Paddington' },
            destination: { name: 'Penzance' },
            departure: '2024-01-06T23:45:00+01:00',
            arrival: '2024-01-07T07:54:00+01:00',
            mode: 'train',
            operator: { name: 'GWR' },
            line: {
              id: 'Night Riviera',
              type: 'train',
              name: 'Night Riviera',
            },
          },
        ],
        mode: 'train',
        operator: 'GWR',
        source: 'GWR',
        nighttrain: true,
      });
    } else if (origin.name === 'Penzance' && destination.name === 'London') {
      manualJourneysToReturn.push({
        id: 'manual-penzance-london',
        amount: 95,
        link: `https://www.gwr.com/tickets/#/3526/3087/${departureDay.format(
          'YYYY-MM-DD'
        )}/D23:00/N/N/1/0/0/1/1/N/N/N`,
        departure: '2024-01-06T21:45:00+01:00',
        arrival: '2024-01-07T05:08:00+01:00',
        duration: 45000000,
        origin: 'Penzance',
        destination: 'London Paddington',
        legs: [
          {
            origin: { name: 'Penzance' },
            destination: { name: 'London Paddington' },
            departure: '2024-01-06T21:45:00+01:00',
            arrival: '2024-01-07T05:08:00+01:00',
            mode: 'train',
            operator: { name: 'GWR' },
            line: {
              id: 'Night Riviera',
              type: 'train',
              name: 'Night Riviera',
            },
          },
        ],
        mode: 'train',
        operator: 'GWR',
        source: 'GWR',
        nighttrain: true,
      });
    }
  }

  if (origin.name === 'Stuttgart' && destination.name === 'Venezia') {
    manualJourneysToReturn.push({
      id: 'manual-stuttgart-venice',
      amount: 89,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(20)
        .minute(10)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: '2024-01-06T20:29:00+01:00',
      arrival: '2024-01-07T08:34:00+01:00',
      duration: 45000000,
      origin: 'Stuttgart',
      destination: 'Venice',
      legs: [StuttgartVeniceDirectNightjet(date)],
      mode: 'train',
      operator: 'OBB',
      source: 'obb',
      nighttrain: true,
    });
  } else if (origin.name === 'Venezia' && destination.name === 'Stuttgart') {
    manualJourneysToReturn.push({
      id: 'manual-venice-stuttgart',
      amount: 99,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(20)
        .minute(0o7)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: '2024-01-06T21:05:00+01:00',
      arrival: '2024-01-07T08:38:00+01:00',
      duration: 45000000,
      origin: 'Venice',
      destination: 'Stuttgart',
      legs: [
        {
          origin: { name: 'Venice' },
          destination: { name: 'Stuttgart' },
          departure: '2024-01-06T21:05:00+01:00',
          arrival: '2024-01-07T08:38:00+01:00',
          mode: 'train',
          operator: { name: 'obb' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T21:05:00+01:00', null, 0, null),
          },
          line: {
            id: 'NJ 236',
            type: 'train',
            name: 'Nightjet',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'obb',
      nighttrain: true,
    });
  }

  //Paris Vien By Zurich 16:22 - 20:26 then 20:40 - 06:34
  if (
    origin.name === 'Paris' &&
    (destination.name === 'Wien' || destination.name === 'Wien Hbf')
  ) {
    manualJourneysToReturn.push({
      id: 'manual-paris-zurich-vienna-en',
      amount: 155,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(16)
        .minute(22)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: departureDay.clone().hour(16).minute(22).format('YYYY-MM-DDTHH:mm'),
      arrival: nextDay.clone().hour(6).minute(34).format('YYYY-MM-DDTHH:mm'),
      duration: 45000000,
      origin: 'Paris',
      destination: 'Wien',
      legs: [
        {
          origin: { name: 'Gare de Lyon' },
          destination: { name: 'Zurich HB' },
          departure: departureDay.clone().hour(16).minute(22).format('YYYY-MM-DDTHH:mm'),
          arrival: departureDay.clone().hour(20).minute(26).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'SNCF' },
          amenities: {
            bike: bikePrice('TGV', '2024-01-06T16:22:00+01:00', '', 0, null),
          },
          line: {
            id: 'TGV Lyria 9219',
            type: 'train',
            name: 'TGV Lyria',
          },
        },
        {
          origin: { name: 'Zurich HB' },
          destination: { name: 'Wien Hbf' },
          departure: departureDay.clone().hour(20).minute(40).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(6).minute(34).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'obb' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T20:40:00+01:00', '', 0, null),
          },
          line: {
            id: 'EN 40467',
            type: 'train',
            name: 'EN 40467',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
    //same journey with a nighjet from Zurich to Wien
    manualJourneysToReturn.push({
      id: 'manual-paris-zurich-vienna-nightjet',
      amount: 179,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(16)
        .minute(22)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: departureDay.clone().hour(16).minute(22).format('YYYY-MM-DDTHH:mm'),
      arrival: nextDay.clone().hour(7).minute(58).format('YYYY-MM-DDTHH:mm'),
      duration: 45000000,
      origin: 'Paris',
      destination: 'Wien',
      legs: [
        {
          origin: { name: 'Gare de Lyon' },
          destination: { name: 'Zurich HB' },
          departure: departureDay.clone().hour(16).minute(22).format('YYYY-MM-DDTHH:mm'),
          arrival: departureDay.clone().hour(20).minute(26).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          type: 'TRAIN_TRAVEL',
          operator: { name: 'SNCF' },
          amenities: {
            bike: bikePrice('TGV', '2024-01-06T16:22:00+01:00', '', 0, null),
          },
          line: {
            id: 'TGV Lyria 9219',
            type: 'train',
            name: 'TGV Lyria',
          },
        },
        {
          origin: { name: 'Zurich HB' },
          destination: { name: 'Zurich HB' },
          departure: departureDay.clone().hour(20).minute(26).format('YYYY-MM-DDTHH:mm'),
          arrival: departureDay.clone().hour(20).minute(40).format('YYYY-MM-DDTHH:mm'),
          duration: { hours: 0, minutes: 14 },
          mode: 'train',
          type: 'PLATFORM_CHANGE',
          hint: {
            type: 'delayAlert',
            message:
              'Based on our datas, you could miss this connection. This TGV Lyria 9219 have a 9min delay on average.',
          },
          operator: { name: 'SNCF' },
          line: {
            id: '9219',
            type: 'train',
            name: 'TGV Lyria 9219',
          },
        },
        {
          origin: { name: 'Zurich HB' },
          destination: { name: 'Wien Hbf' },
          departure: departureDay.clone().hour(20).minute(40).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(7).minute(58).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'obb' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T20:40:00+01:00', '', 0, null),
          },
          line: {
            id: 'NJ 467',
            type: 'train',
            name: 'NJ 467',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  }
  //Wien Paris By Stuttgart 23:28 - 08:38 then 09:10 - 12:33
  else if (origin.name === 'Wien' && destination.name === 'Paris') {
    manualJourneysToReturn.push({
      id: 'manual-wien-stuttgart-paris',
      amount: 155,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(23)
        .minute(28)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: departureDay.clone().hour(23).minute(28).format('YYYY-MM-DDTHH:mm'),
      arrival: nextDay.clone().hour(8).minute(38).format('YYYY-MM-DDTHH:mm'),
      duration: 37800000,
      origin: 'Wien',
      destination: 'Paris',
      legs: [
        {
          origin: { name: 'Wien Hbf' },
          destination: { name: 'Stuttgart Hbf' },
          departure: departureDay.clone().hour(23).minute(28).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(8).minute(38).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'obb' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T23:28:00+01:00', '', 0, null),
          },
          line: {
            id: 'EN 50462',
            type: 'train',
            name: 'EN 50462',
          },
        },
        {
          origin: { name: 'Stuttgart Hbf' },
          destination: { name: 'Paris Est' },
          departure: nextDay.clone().hour(9).minute(10).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(12).minute(33).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'SNCF' },
          amenities: {
            bike: bikePrice('TGV', '2024-01-07T09:10:00+01:00', '', 0, null),
          },
          line: {
            id: 'TGV 9576',
            type: 'train',
            name: 'TGV 9576',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  }
  //Zurich Wien 20:40 - 07:58
  if (origin.name === 'Zürich' && destination.name === 'Wien') {
    manualJourneysToReturn.push({
      id: 'manual-zurich-vienna-nightjet',
      amount: 79,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(20)
        .minute(40)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: '2024-01-06T20:40:00+01:00',
      arrival: '2024-01-07T07:58:00+01:00',
      duration: 45000000,
      origin: 'Zurich',
      destination: 'Wien',
      legs: [
        {
          origin: { name: 'Zurich HB' },
          destination: { name: 'Wien Hbf' },
          departure: '2024-01-06T20:40:00+01:00',
          arrival: '2024-01-07T07:58:00+01:00',
          mode: 'train',
          operator: { name: 'obb' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T20:40:00+01:00', '', 0, null),
          },
          line: {
            id: 'NJ 467',
            type: 'train',
            name: 'NJ 467',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'obb',
      nighttrain: true,
    });
  }
  //Paris Bucharest by Stuttgart and Wien, 15:55 - 19:04 then ...
  if (origin.name === 'Paris' && destination.name === 'Bucharest') {
    manualJourneysToReturn.push({
      id: 'manual-paris-stuttgart-bucharest',
      amount: 199,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(15)
        .minute(55)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: departureDay.clone().hour(15).minute(55).format('YYYY-MM-DDTHH:mm'),
      arrival: nextDay.clone().hour(9).minute(2).format('YYYY-MM-DDTHH:mm'),
      duration: 61620000,
      origin: 'Paris',
      destination: 'Bucharest',
      legs: [
        {
          origin: { name: 'Gare de lEst' },
          destination: { name: 'Stuttgart Hbf' },
          departure: departureDay.clone().hour(15).minute(55).format('YYYY-MM-DDTHH:mm'),
          arrival: departureDay.clone().hour(19).minute(4).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'SNCF' },
          amenities: {
            bike: bikePrice('TGV', '2024-01-06T15:55:00+01:00', '', 0, null),
          },
          line: {
            id: 'TGV 9577',
            type: 'train',
            name: 'TGV 9577',
          },
        },
        {
          origin: { name: 'Stuttgart Hbf' },
          destination: { name: 'Wien Hbf' },
          departure: departureDay.clone().hour(20).minute(29).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(6).minute(34).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'obb' },
          amenities: {
            bike: bikePrice('Nightjet', '2024-01-06T20:29:00+01:00', '', 0, null),
          },
          line: {
            id: 'EN 40467',
            type: 'train',
            name: 'EN 40467',
          },
        },
        {
          origin: { name: 'Wien Hbf' },
          destination: { name: 'Bucharest' },
          departure: nextDay.clone().hour(19).minute(42).format('YYYY-MM-DDTHH:mm'),
          arrival: nextNextDay.clone().hour(17).minute(10).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'obb' },
          amenities: {
            bike: bikePrice('Dacia', '2024-01-07T19:42:00+01:00', '', 0, null),
          },
          line: {
            id: 'D 347',
            type: 'train',
            name: 'D 347',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  }
  //Wien to Bucharest , 19:42 - 17:10
  if (origin.name === 'Wien' && destination.name === 'Bucharest') {
    manualJourneysToReturn.push({
      id: 'manual-wien-bucharest',
      amount: 59,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(19)
        .minute(42)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: '2024-01-06T19:42:00+01:00',
      arrival: '2024-01-07T17:10:00+01:00',
      duration: 45000000,
      origin: 'Wien',
      destination: 'Bucharest',
      legs: [
        {
          origin: { name: 'Wien Hbf' },
          destination: { name: 'Bucharest' },
          departure: '2024-01-06T19:42:00+01:00',
          arrival: '2024-01-07T17:10:00+01:00',
          mode: 'train',
          operator: { name: 'obb' },
          amenities: {
            bike: bikePrice('Dacia', '2024-01-06T19:42:00+01:00', '', 0, null),
          },
          line: {
            id: 'D 347',
            type: 'train',
            name: 'D 347',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  } else if (origin.name === 'Bucharest' && destination.name === 'Wien') {
    manualJourneysToReturn.push({
      id: 'manual-bucharest-wien',
      amount: 59,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(10)
        .minute(45)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: '2024-01-06T10:45:00+01:00',
      arrival: '2024-01-07T08:20:00+01:00',
      duration: 45000000,
      origin: 'Bucharest',
      destination: 'Wien',
      legs: [
        {
          origin: { name: 'Bucharest' },
          destination: { name: 'Wien Hbf' },
          departure: '2024-01-06T10:45:00+01:00',
          arrival: '2024-01-07T08:20:00+01:00',
          mode: 'train',
          operator: { name: 'obb' },
          amenities: {
            bike: bikePrice('Dacia', '2024-01-06T10:45:00+01:00', '', 0, null),
          },
          line: {
            id: 'D 346',
            type: 'train',
            name: 'D 346',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  }
  if (origin.name === 'Paris' && destination.name === 'Budapest') {
    manualJourneysToReturn.push({
      id: 'manual-paris-stuttgart-budapest',
      amount: 129,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(19)
        .minute(12)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: departureDay.clone().hour(15).minute(55).format('YYYY-MM-DDTHH:mm'),
      arrival: nextDay.clone().hour(9).minute(2).format('YYYY-MM-DDTHH:mm'),
      duration: 61620,
      origin: 'Paris',
      destination: 'Budapest',
      legs: [
        {
          origin: { name: "Gare de l'Est" },
          destination: { name: 'Stuttgart Hbf' },
          departure: departureDay.clone().hour(15).minute(55).format('YYYY-MM-DDTHH:mm'),
          arrival: departureDay.clone().hour(19).minute(4).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'SNCF' },
          amenities: {
            bike: bikePrice('TGV', '2024-01-06T15:55:00+01:00', '', 0, null),
          },
          line: {
            id: '9577',
            type: 'train',
            name: 'TGV 9577',
          },
        },
        {
          origin: { name: 'Stuttgart Hbf' },
          destination: { name: 'Budapest-Kelenföld' },
          departure: departureDay.clone().hour(20).minute(29).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(9).minute(2).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'ÖBB' },
          line: {
            id: 'EN 50237',
            type: 'train',
            name: 'EN 50237',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  } else if (origin.name === 'Budapest' && destination.name === 'Paris') {
    manualJourneysToReturn.push({
      id: 'manual-budapest-stuttgart-paris',
      amount: 164,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(19)
        .minute(12)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: departureDay.clone().hour(20).minute(55).format('YYYY-MM-DDTHH:mm'),
      arrival: nextDay.clone().hour(14).minute(13).format('YYYY-MM-DDTHH:mm'),
      duration: 45000000,
      origin: 'Budapest',
      destination: 'Paris',
      legs: [
        {
          origin: { name: 'Budapest-KELENFOELD' },
          destination: { name: 'Stuttgart Hbf' },
          departure: departureDay.clone().hour(20).minute(55).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(9).minute(26).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'ÖBB' },
          line: {
            id: 'EN 50462',
            type: 'train',
            name: 'EN 50462',
          },
        },
        {
          origin: { name: 'Stuttgart Hbf' },
          destination: { name: "Gare de l'Est" },
          departure: nextDay.clone().hour(10).minute(52).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(14).minute(13).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'DB' },
          line: {
            id: '9574',
            type: 'train',
            name: 'ICE 9574',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  }
  if (origin.name === 'Paris' && destination.name === 'Berlin') {
    //Eurostar to Brussels + European Sleeper to Berlin
    manualJourneysToReturn.push({
      id: 'manual-paris-brussels-berlin',
      amount: 188,
      link: `https://www.europeansleeper.eu/`,
      departure: departureDay.clone().hour(17).minute(25).format('YYYY-MM-DDTHH:mm'),
      arrival: nextDay.clone().hour(6).minute(26).format('YYYY-MM-DDTHH:mm'),
      duration: 45000000,
      origin: 'Paris',
      destination: 'Berlin',
      legs: [
        {
          origin: { name: 'Paris Nord' },
          destination: { name: 'Brussels Midi' },
          departure: departureDay.clone().hour(17).minute(25).format('YYYY-MM-DDTHH:mm'),
          arrival: departureDay.clone().hour(18).minute(47).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'Eurostar' },
          line: {
            id: 'ES 9369',
            type: 'train',
            name: 'ES 9369',
          },
        },
        {
          origin: { name: 'Brussels Midi' },
          destination: { name: 'Berlin' },
          departure: departureDay.clone().hour(19).minute(22).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(6).minute(26).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'European Sleeper' },
          line: {
            id: 'ES453',
            type: 'train',
            name: 'ES453',
          },
        },
      ],
      mode: 'train',
      operator: 'European Sleeper',
      source: 'manual',
      nighttrain: true,
    });
  } else if (origin.name === 'Berlin' && destination.name === 'Paris') {
    //European Sleeper to Brussels + Eurostar to Paris
    manualJourneysToReturn.push({
      id: 'manual-berlin-brussels-paris',
      amount: 188,
      link: `https://www.europeansleeper.eu/`,
      departure: departureDay.clone().hour(22).minute(56).format('YYYY-MM-DDTHH:mm'),
      arrival: nextDay.clone().hour(11).minute(36).format('YYYY-MM-DDTHH:mm'),
      duration: 45000000,
      origin: 'Berlin',
      destination: 'Paris',
      legs: [
        {
          origin: { name: 'Berlin' },
          destination: { name: 'Brussels Midi' },
          departure: departureDay.clone().hour(22).minute(56).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(9).minute(27).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'European Sleeper' },
          line: {
            id: 'ES452',
            type: 'train',
            name: 'ES452',
          },
        },
        {
          origin: { name: 'Brussels Midi' },
          destination: { name: 'Paris Nord' },
          departure: nextDay.clone().hour(10).minute(13).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(11).minute(36).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'Eurostar' },
          line: {
            id: 'ES 9422',
            type: 'train',
            name: 'ES 9422',
          },
        },
      ],
      mode: 'train',
      operator: 'European Sleeper',
      source: 'europeansleeper',
      nighttrain: true,
    });
  }
  if (origin.name === 'Paris' && destination.name === 'Budapest') {
    manualJourneysToReturn.push({
      id: 'manual-paris-zurich-budapest',
      amount: 124,
      link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
        origin.name
      }&stationDestName=${destination.name}&outwardDateTime=${departureDay
        .clone()
        .hour(16)
        .minute(22)
        .format('YYYY-MM-DDTHH:mm')}`,
      departure: departureDay.clone().hour(16).minute(22).format('YYYY-MM-DDTHH:mm'),
      arrival: nextDay.clone().hour(9).minute(2).format('YYYY-MM-DDTHH:mm'),
      duration: 44000000,
      origin: 'Paris',
      destination: 'Budapest',
      legs: [
        {
          origin: { name: "Gare de l'Est" },
          destination: { name: 'Zurich HB' },
          departure: departureDay.clone().hour(16).minute(22).format('YYYY-MM-DDTHH:mm'),
          arrival: departureDay.clone().hour(20).minute(26).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'SNCF' },
          amenities: {
            bike: bikePrice('Lyria', '2024-01-06T16:22:00+01:00', '', 0, null),
          },
          line: {
            id: '9219 ',
            type: 'train',
            name: 'TGV Lyria 9219',
          },
        },

        {
          origin: { name: 'Zurich HB' },
          destination: { name: 'Budapest-Kelenföld' },
          departure: departureDay.clone().hour(20).minute(40).format('YYYY-MM-DDTHH:mm'),
          arrival: nextDay.clone().hour(9).minute(2).format('YYYY-MM-DDTHH:mm'),
          mode: 'train',
          operator: { name: 'ÖBB' },
          line: {
            id: 'EN 40467',
            type: 'train',
            name: 'EN 40467',
          },
        },
      ],
      mode: 'train',
      operator: 'OBB',
      source: 'manual',
      nighttrain: true,
    });
  }

  //date is Saturday, Tuesday or Thursday
  if (departureDay.day() === 2 || departureDay.day() === 4 || departureDay.day() === 6) {
    //if date + 1 day is available
    if (
      SnalltagetBerlinStockholmAvailable(departureDay.clone().add(1, 'day').toISOString())
    ) {
      //Paris Berlin Copenhagen
      if (origin.name === 'Paris' && destination.name === 'København') {
        manualJourneysToReturn.push({
          id: 'manual-paris-berlin-copenhagen',
          amount: isNightJetParisBerlinUnavailable(departureDay).yes
            ? PRICE_UNKNOWN_CONST
            : 195,
          link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
            origin.name
          }&stationDestName=${destination.name}&outwardDateTime=${departureDay
            .clone()
            .hour(19)
            .minute(12)
            .format('YYYY-MM-DDTHH:mm')}`,
          departure: departureDay.clone().hour(19).minute(12).format('YYYY-MM-DDTHH:mm'),
          arrival: nextNextDay.clone().hour(17).minute(7).format('YYYY-MM-DDTHH:mm'),
          duration: 45000000,
          origin: 'Paris',
          destination: 'Copenhagen',
          legs: [
            ParisBerlinDirectNightjet(date),
            {
              origin: { name: 'Berlin Hbf' },
              destination: { name: 'Copenhagen' },
              departure: nextDay.clone().hour(21).minute(11).format('YYYY-MM-DDTHH:mm'),
              arrival: nextNextDay.clone().hour(7).minute(0).format('YYYY-MM-DDTHH:mm'),
              mode: 'train',
              operator: { name: 'Snälltåget' },
              line: {
                id: '10300',
                type: 'train',
                name: '10300',
              },
            },
          ],
          mode: 'train',
          operator: 'OBB',
          source: 'manual',
          nighttrain: true,
        });
      } else if (
        //Paris Berlin Stockholm
        origin.name === 'Paris' &&
        destination.name === 'Stockholm'
      ) {
        manualJourneysToReturn.push({
          id: 'manual-paris-berlin-stockholm',
          amount: 219,
          link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
            origin.name
          }&stationDestName=${destination.name}&outwardDateTime=${departureDay
            .clone()
            .hour(19)
            .minute(12)
            .format('YYYY-MM-DDTHH:mm')}`,
          departure: departureDay.clone().hour(19).minute(12).format('YYYY-MM-DDTHH:mm'),
          arrival: nextNextDay.clone().hour(13).minute(20).format('YYYY-MM-DDTHH:mm'),
          duration: 45000000,
          origin: 'Paris',
          destination: 'Stockholm',
          legs: [
            ParisBerlinDirectNightjet(date),
            {
              origin: { name: 'Berlin Hbf' },
              destination: { name: 'Stockholm' },
              departure: nextDay.clone().hour(21).minute(11).format('YYYY-MM-DDTHH:mm'),
              arrival: nextNextDay.clone().hour(13).minute(20).format('YYYY-MM-DDTHH:mm'),
              mode: 'train',
              operator: { name: 'Snälltåget' },
              line: {
                id: '10300',
                type: 'train',
                name: 'Snälltåget',
              },
            },
          ],
          mode: 'train',
          operator: 'Snälltåget',
          source: 'manual',
          nighttrain: true,
        });
      }
    }
    //NJ Brussel
    if (origin.name === 'Bruxelles' && destination.name === 'Wien') {
      manualJourneysToReturn.push({
        id: 'manual-brussel-wien',
        amount: 99,
        link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
          origin.name
        }&stationDestName=${destination.name}&outwardDateTime=${departureDay
          .clone()
          .hour(18)
          .minute(55)
          .format('YYYY-MM-DDTHH:mm')}`,
        departure: departureDay.clone().hour(18).minute(55).format('YYYY-MM-DDTHH:mm'),
        arrival: nextDay.clone().hour(10).minute(13).format('YYYY-MM-DDTHH:mm'),
        duration: 45000000,
        origin: 'Brussel',
        destination: 'Vienna',
        legs: [
          {
            origin: { name: 'Brussel' },
            destination: { name: 'Vienna' },
            departure: departureDay
              .clone()
              .hour(18)
              .minute(55)
              .format('YYYY-MM-DDTHH:mm'),
            arrival: nextDay.clone().hour(10).minute(13).format('YYYY-MM-DDTHH:mm'),
            mode: 'train',
            operator: { name: 'obb' },
            amenities: {
              bike: bikePrice('Nightjet', '2024-01-01T18:55:00+01:00', '', 0, null),
            },
            line: {
              id: 'NJ 469',
              name: 'Nightjet',
              type: 'train',
            },
          },
        ],
        mode: 'train',
        operator: 'OBB',
        source: 'obb',
        nighttrain: true,
      });
    } else if (origin.name === 'Bruxelles' && destination.name === 'Berlin') {
      manualJourneysToReturn.push({
        id: 'manual-brussel-berlin',
        amount: 69,
        link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
          origin.name
        }&stationDestName=${destination.name}&outwardDateTime=${departureDay
          .clone()
          .hour(19)
          .minute(0o3)
          .format('YYYY-MM-DDTHH:mm')}`,
        departure: departureDay.clone().hour(19).minute(7).format('YYYY-MM-DDTHH:mm'),
        arrival: nextDay.clone().hour(9).minute(16).format('YYYY-MM-DDTHH:mm'),
        duration: 45000000,
        origin: 'Brussel',
        destination: 'Berlin',
        legs: [
          {
            origin: { name: 'Brussel' },
            destination: { name: 'Berlin' },
            departure: departureDay.clone().hour(19).minute(7).format('YYYY-MM-DDTHH:mm'),
            arrival: nextDay.clone().hour(9).minute(16).format('YYYY-MM-DDTHH:mm'),
            mode: 'train',
            operator: { name: 'obb' },
            amenities: {
              bike: bikePrice('Nightjet', '2024-01-01T19:07:00+01:00', '', 0, null),
            },
            line: {
              id: 'NJ 425',
              name: 'Nightjet',
              type: 'train',
            },
          },
        ],
        mode: 'train',
        operator: 'OBB',
        source: 'obb',
        nighttrain: true,
      });
    }
    //Including NJ from Strasbourg
    if (origin.name === 'Strasbourg' && destination.name === 'Wien') {
      manualJourneysToReturn.push({
        id: 'manual-strasbourg-wien',
        amount: 109,
        link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
          origin.name
        }&stationDestName=${destination.name}&outwardDateTime=${departureDay
          .clone()
          .hour(23)
          .minute(42)
          .format('YYYY-MM-DDTHH:mm')}`,
        departure: departureDay.clone().hour(23).minute(42).format('YYYY-MM-DDTHH:mm'),
        arrival: nextDay.clone().hour(10).minute(13).format('YYYY-MM-DDTHH:mm'),
        duration: 45000000,
        origin: 'Strasbourg',
        destination: 'Vienna',
        legs: [
          {
            origin: { name: 'Strasbourg' },
            destination: { name: 'Vienna' },
            departure: departureDay
              .clone()
              .hour(23)
              .minute(42)
              .format('YYYY-MM-DDTHH:mm'),
            arrival: nextDay.clone().hour(10).minute(13).format('YYYY-MM-DDTHH:mm'),
            mode: 'train',
            operator: { name: 'obb' },
            amenities: {
              bike: bikePrice('Nightjet', '2024-01-06T23:42:00+01:00', '', 0, null),
            },
            line: {
              id: 'NJ 40424',
              type: 'train',
              name: 'Nightjet',
            },
            unavailable: ParisNightjetAugustToOctoberPerturbations(departureDay),
          },
        ],
        mode: 'train',
        operator: 'OBB',
        source: 'obb',
        nighttrain: true,
      });
    } else if (origin.name === 'Strasbourg' && destination.name === 'Berlin') {
      manualJourneysToReturn.push({
        id: 'manual-strasbourg-berlin',
        amount: 129,
        link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
          origin.name
        }&stationDestName=${destination.name}&outwardDateTime=${departureDay
          .clone()
          .hour(21)
          .minute(45)
          .format('YYYY-MM-DDTHH:mm')}`,
        departure: departureDay.clone().hour(21).minute(45).format('YYYY-MM-DDTHH:mm'),
        arrival: nextDay.clone().hour(9).minute(58).format('YYYY-MM-DDTHH:mm'),
        duration: 42000000,
        origin: 'Strasbourg',
        destination: 'Berlin Hbf',
        legs: [
          {
            origin: { name: 'Strasbourg' },
            destination: { name: 'Berlin Hbf' },
            departure: departureDay
              .clone()
              .hour(21)
              .minute(45)
              .format('YYYY-MM-DDTHH:mm'),
            arrival: nextDay.clone().hour(9).minute(58).format('YYYY-MM-DDTHH:mm'),
            mode: 'train',
            operator: { name: 'obb' },
            amenities: {
              bike: bikePrice('Nightjet', '2024-01-06T21:45:00+01:00', '', 0, null),
            },
            line: {
              id: 'NJ 40469',
              type: 'train',
              name: 'Nightjet',
            },
            unavailable: ParisNightjetAugustToOctoberPerturbations(departureDay),
          },
        ],
        mode: 'train',
        operator: 'OBB',
        source: 'obb',
        nighttrain: true,
      });
    } else if (origin.name === 'Lyon' && destination.name === 'Wien') {
      manualJourneysToReturn.push({
        id: 'manual-lyon-strasbourg-wien',
        amount: PRICE_UNKNOWN_CONST,
        link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
          origin.name
        }&stationDestName=${destination.name}&outwardDateTime=${departureDay
          .clone()
          .hour(18)
          .minute(3)
          .format('YYYY-MM-DDTHH:mm')}`,
        departure: '2024-01-06T18:03:00+01:00',
        arrival: '2024-01-07T10:13:00+01:00',
        duration: 45000000,
        origin: 'Lyon',
        destination: 'Vienna',
        legs: [
          {
            origin: { name: 'Lyon Part-Dieu' },
            destination: { name: 'Strasbourg' },
            departure: '2024-01-06T18:03:00+01:00',
            arrival: '2024-01-06T22:07:00+01:00',
            mode: 'train',
            operator: { name: 'SNCF' },
            line: {
              id: 'TGV 2096',
              type: 'train',
              name: 'TGV 2096',
            },
            unavailable: ParisNightjetAugustToOctoberPerturbations(departureDay),
          },
          {
            origin: { name: 'Strasbourg' },
            destination: { name: 'Wien Hbf' },
            departure: '2024-01-06T23:42:00+01:00',
            arrival: '2024-01-07T10:13:00+01:00',
            mode: 'train',
            operator: { name: 'obb' },
            line: {
              id: 'NJ 469',
              type: 'train',
              name: 'Nightjet',
            },
          },
        ],
        mode: 'train',
        operator: 'OBB',
        source: 'manual',
        nighttrain: true,
      });
    } else if (origin.name === 'Lyon' && destination.name === 'Berlin') {
      manualJourneysToReturn.push({
        id: 'manual-lyon-strasbourg-berlin',
        amount: PRICE_UNKNOWN_CONST,
        link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
          origin.name
        }&stationDestName=${destination.name}&outwardDateTime=${departureDay
          .clone()
          .hour(18)
          .minute(3)
          .format('YYYY-MM-DDTHH:mm')}`,
        departure: '2024-01-06T18:03:00+01:00',
        arrival: '2024-01-07T09:16:00+01:00',
        duration: 45000000,
        origin: 'Lyon',
        destination: 'Berlin',
        legs: [
          {
            origin: { name: 'Lyon Part-Dieu' },
            destination: { name: 'Strasbourg' },
            departure: '2024-01-06T18:03:00+01:00',
            arrival: '2024-01-06T22:07:00+01:00',
            mode: 'train',
            operator: { name: 'SNCF' },
            line: {
              id: 'TGV 2096',
              type: 'train',
              name: 'TGV 2096',
            },
          },
          {
            origin: { name: 'Strasbourg' },
            destination: { name: 'Berlin' },
            departure: '2024-01-06T23:42:00+01:00',
            arrival: '2024-01-07T09:16:00+01:00',
            mode: 'train',
            operator: { name: 'obb' },
            line: {
              id: 'NJ 425',
              type: 'train',
              name: 'Nightjet',
            },
            unavailable: ParisNightjetAugustToOctoberPerturbations(departureDay),
          },
        ],
        mode: 'train',
        operator: 'OBB',
        source: 'manual',
        nighttrain: true,
      });
    }
    // NJ from Paris
    else if (origin.name === 'Paris' && destination.name === 'Cluj Napoca') {
      manualJourneysToReturn.push({
        id: 'manual-paris-wien-cluj',
        amount: PRICE_UNKNOWN_CONST,
        link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
          origin.name
        }&stationDestName=${destination.name}&outwardDateTime=${departureDay
          .clone()
          .hour(19)
          .minute(12)
          .format('YYYY-MM-DDTHH:mm')}`,
        departure: '2024-01-06T19:12:00+01:00',
        arrival: '2024-01-07T21:34:00+01:00',
        duration: 45000000,
        origin: 'Paris',
        destination: 'Cluj-Napoca',
        legs: [
          ParisWienDirectNightjet(date),
          {
            origin: { name: 'Wien Hbf' },
            destination: { name: 'Cluj-Napoca' },
            departure: '2024-01-07T19:42:00+01:00',
            arrival: '2024-01-08T10:44:00+01:00',
            mode: 'train',
            operator: { name: 'CFR' },
            line: {
              id: 'IRN 347',
              type: 'train',
              name: 'IRN 347',
            },
          },
        ],
        mode: 'train',
        operator: 'OBB',
        source: 'manual',
        nighttrain: true,
      });
    } else if (origin.name === 'Paris' && destination.name === 'Bucharest') {
      manualJourneysToReturn.push({
        id: 'manual-paris-wien-bucuresti',
        amount: PRICE_UNKNOWN_CONST,
        link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
          origin.name
        }&stationDestName=${destination.name}&outwardDateTime=${departureDay
          .clone()
          .hour(19)
          .minute(12)
          .format('YYYY-MM-DDTHH:mm')}`,
        departure: '2024-01-06T19:12:00+01:00',
        arrival: '2024-01-08T15:06:00+01:00',
        duration: 45000000,
        origin: 'Paris',
        destination: 'Bucuresti',
        legs: [
          ParisWienDirectNightjet(date),
          {
            origin: { name: 'Wien Hbf' },
            destination: { name: 'Bucuresti Nord' },
            departure: '2024-01-07T19:42:00+01:00',
            arrival: '2024-01-08T15:06:00+01:00',
            mode: 'train',
            operator: { name: 'CFR' },
            line: {
              id: 'IRN 347',
              type: 'train',
              name: 'IRN 347',
            },
          },
        ],
        mode: 'train',
        operator: 'OBB',
        source: 'manual',
        nighttrain: true,
      });
    } else if (origin.name === 'Paris' && destination.name === 'Budapest') {
      manualJourneysToReturn.push({
        id: 'manual-paris-wien-budapest',
        amount: isNightJetParisWienUnavailable(departureDay).yes
          ? PRICE_UNKNOWN_CONST
          : 199,
        link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
          origin.name
        }&stationDestName=${destination.name}&outwardDateTime=${departureDay
          .clone()
          .format('YYYY-MM-DD')}T19:00`,
        departure: departureDay
          .clone()
          .hour(19)
          .minute(12)
          .format('YYYY-MM-DDTHH:mm:ssZ'),
        arrival: nextDay.clone().hour(13).minute(19).format('YYYY-MM-DDTHH:mm:ssZ'),
        duration: 45000000,
        origin: 'Paris',
        destination: 'Budapest',
        legs: [
          ParisWienDirectNightjet(date),
          {
            origin: { name: 'Wien Hbf' },
            destination: { name: 'Budapest-Keleti pu' },
            departure: nextDay.clone().hour(10).minute(42).format('YYYY-MM-DDTHH:mm'),
            arrival: nextDay.clone().hour(13).minute(19).format('YYYY-MM-DDTHH:mm'),
            mode: 'train',
            operator: { name: 'obb' },
            line: {
              id: 'EC 143',
              type: 'train',
              name: 'EC 143',
            },
          },
        ],
        mode: 'train',
        operator: 'OBB',
        source: 'manual',
        nighttrain: true,
      });
    } else if (origin.name === 'Paris' && destination.name === 'Berlin') {
      manualJourneysToReturn.push({
        id: 'manual-paris-berlin',
        amount: ParisBerlinPrice(departureDay),

        link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
          origin.name
          //TODO: Fix redirection date for OBB
        }&stationDestName=${destination.name}&outwardDateTime=${departureDay
          .clone()
          .hour(19)
          .minute(12)
          .format('YYYY-MM-DDTHH:mm')}`,
        departure: departureDay
          .clone()
          .hour(19)
          .minute(12)
          .format('YYYY-MM-DDTHH:mm:ssZ'),
        arrival: nextDay.clone().hour(8).minute(26).format('YYYY-MM-DDTHH:mm:ssZ'),
        duration: 42000000,
        origin: "Paris Gare de l'Est",
        destination: 'Berlin Hbf',
        legs: [ParisBerlinDirectNightjet(date)],
        mode: 'train',
        operator: 'OBB',
        source: 'obb',
        nighttrain: true,
      });
    } else if (origin.name === 'Paris' && destination.name === 'Wien') {
      manualJourneysToReturn.push({
        id: 'manual-paris-wien',
        amount: ParisWienPrice(departureDay),
        link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
          origin.name
        }&stationDestName=${destination.name}&outwardDateTime=${departureDay
          .clone()
          .hour(19)
          .minute(12)
          .format('YYYY-MM-DDTHH:mm')}`,
        departure: departureDay.clone().hour(19).minute(12).format('YYYY-MM-DDTHH:mm'),
        arrival: nextDay.clone().hour(10).minute(13).format('YYYY-MM-DDTHH:mm'),
        duration: 45000000,
        origin: 'Paris',
        destination: 'Vienna',
        legs: [ParisWienDirectNightjet(date)],
        mode: 'train',
        operator: 'OBB',
        source: 'obb',
        nighttrain: true,
      });
    } else if (origin.name === 'Paris' && destination.name === 'København') {
      manualJourneysToReturn.push({
        id: 'manual-paris-berlin-copenhagen',
        amount: isNightJetParisBerlinUnavailable(departureDay).yes
          ? PRICE_UNKNOWN_CONST
          : 180,
        link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
          origin.name
        }&stationDestName=${destination.name}&outwardDateTime=${departureDay
          .clone()
          .hour(19)
          .minute(12)
          .format('YYYY-MM-DDTHH:mm')}`,
        departure: departureDay.clone().hour(19).minute(12).format('YYYY-MM-DDTHH:mm'),
        arrival: nextNextDay.clone().hour(17).minute(7).format('YYYY-MM-DDTHH:mm'),
        duration: 45000000,
        origin: 'Paris',
        destination: 'Copenhagen',
        legs: [
          ParisBerlinDirectNightjet(date),
          {
            origin: { name: 'Berlin Hbf' },
            destination: { name: 'Copenhagen' },
            departure: nextDay.clone().hour(18).minute(37).format('YYYY-MM-DDTHH:mm'),
            arrival: nextNextDay.clone().hour(8).minute(2).format('YYYY-MM-DDTHH:mm'),
            mode: 'train',
            operator: { name: 'SJ' },
            line: {
              id: 'EN 345',
              type: 'train',
              name: 'EN 345',
            },
          },
        ],
        mode: 'train',
        operator: 'OBB',
        source: 'manual',
        nighttrain: true,
      });
    } else if (origin.name === 'Paris' && destination.name === 'Stockholm') {
      manualJourneysToReturn.push({
        id: 'manual-paris-berlin-stockholm',
        amount: isNightJetParisBerlinUnavailable(departureDay).yes
          ? PRICE_UNKNOWN_CONST
          : 205,
        link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
          origin.name
        }&stationDestName=${destination.name}&outwardDateTime=${departureDay
          .clone()
          .hour(19)
          .minute(12)
          .format('YYYY-MM-DDTHH:mm')}`,
        departure: departureDay.clone().hour(19).minute(12).format('YYYY-MM-DDTHH:mm'),
        arrival: nextNextDay.clone().hour(13).minute(20).format('YYYY-MM-DDTHH:mm'),
        duration: 45000000,
        origin: 'Paris',
        destination: 'Stockholm',
        legs: [
          ParisBerlinDirectNightjet(date),
          {
            origin: { name: 'Berlin Hbf' },
            destination: { name: 'Stockholm' },
            departure: nextDay.clone().hour(18).minute(37).format('YYYY-MM-DDTHH:mm'),
            arrival: nextNextDay.clone().hour(9).minute(57).format('YYYY-MM-DDTHH:mm'),
            mode: 'train',
            operator: { name: 'SJ' },
            line: {
              id: 'EN 345',
              type: 'train',
              name: 'EN 345',
              reference: '345',
            },
          },
        ],
        mode: 'train',
        operator: 'SJ',
        source: 'manual',
        nighttrain: true,
      });
    } else if (origin.name === 'Nantes' && destination.name === 'Berlin') {
      manualJourneysToReturn.push({
        id: 'manual-nantes-berlin',
        amount: 129,
        link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
          origin.name
        }&stationDestName=${destination.name}&outwardDateTime=${departureDay
          .clone()
          .hour(16)
          .minute(0o5)
          .format('YYYY-MM-DDTHH:mm')}`,
        departure: departureDay.clone().hour(16).minute(5).format('YYYY-MM-DDTHH:mm'),
        arrival: nextDay.clone().hour(9).minute(58).format('YYYY-MM-DDTHH:mm'),
        duration: 42000000,
        origin: 'Nantes',
        destination: 'Berlin',
        legs: [
          {
            origin: { name: 'Nantes' },
            destination: { name: 'Paris Montparnasse' },
            departure: departureDay.clone().hour(16).minute(5).format('YYYY-MM-DDTHH:mm'),
            arrival: departureDay.clone().hour(18).minute(25).format('YYYY-MM-DDTHH:mm'),
            mode: 'train',
            operator: { name: 'SNCF' },
            line: {
              id: 'TGV 8880',
              type: 'train',
              reference: '8880',
              name: 'TGV 8880',
            },
          },
          ParisBerlinDirectNightjet(date),
        ],
        mode: 'train',
        operator: 'OBB',
        source: 'manual',
        nighttrain: true,
      });
      //Bordeaux Berlin via Paris
    } else if (origin.name === 'Bordeaux' && destination.name === 'Berlin') {
      manualJourneysToReturn.push({
        id: 'manual-bordeaux-paris-berlin',
        amount: 129,
        link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
          origin.name
        }&stationDestName=${destination.name}&outwardDateTime=${departureDay
          .clone()
          .hour(16)
          .minute(0o5)
          .format('YYYY-MM-DDTHH:mm')}`,
        departure: departureDay.clone().hour(15).minute(46).format('YYYY-MM-DDTHH:mm'),
        arrival: nextDay.clone().hour(9).minute(58).format('YYYY-MM-DDTHH:mm'),
        duration: 42000000,
        origin: 'Bordeaux',
        destination: 'Berlin',
        legs: [
          {
            origin: { name: 'Bordeaux' },
            destination: { name: 'Paris Montparnasse' },
            departure: departureDay
              .clone()
              .hour(15)
              .minute(46)
              .format('YYYY-MM-DDTHH:mm'),
            arrival: departureDay.clone().hour(17).minute(49).format('YYYY-MM-DDTHH:mm'),
            mode: 'train',
            operator: { name: 'SNCF' },
            line: {
              id: 'TGV 8282',
              type: 'train',
              reference: '8282',
              name: 'TGV 8282',
            },
          },
          ParisBerlinDirectNightjet(date),
        ],
        mode: 'train',
        operator: 'OBB',
        source: 'manual',
        nighttrain: true,
      });
    } else if (origin.name === 'Bordeaux' && destination.name === 'Wien') {
      manualJourneysToReturn.push({
        id: 'manual-bordeaux-paris-wien',
        amount: 119,
        link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
          origin.name
        }&stationDestName=${destination.name}&outwardDateTime=${departureDay
          .clone()
          .hour(16)
          .minute(0o5)
          .format('YYYY-MM-DDTHH:mm')}`,
        departure: departureDay.clone().hour(15).minute(46).format('YYYY-MM-DDTHH:mm'),
        arrival: nextDay.clone().hour(10).minute(13).format('YYYY-MM-DDTHH:mm'),
        duration: 45000000,
        origin: 'Bordeaux',
        destination: 'Vienna',
        legs: [
          {
            origin: { name: 'Bordeaux' },
            destination: { name: 'Paris Montparnasse' },
            departure: departureDay
              .clone()
              .hour(15)
              .minute(46)
              .format('YYYY-MM-DDTHH:mm'),
            arrival: departureDay.clone().hour(17).minute(49).format('YYYY-MM-DDTHH:mm'),
            mode: 'train',
            operator: { name: 'SNCF' },
            line: {
              id: 'TGV 8582',
              type: 'train',
              reference: '8282',
              name: 'TGV 8582',
            },
          },
          ParisWienDirectNightjet(date),
        ],
        mode: 'train',
        operator: 'OBB',
        source: 'manual',
        nighttrain: true,
      });
      //Bordeaux to Budapest
    } else if (origin.name === 'Bordeaux' && destination.name === 'Budapest') {
      manualJourneysToReturn.push({
        id: 'manual-bordeaux-paris-wien-budapest',
        amount: 159,
        link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
          origin.name
        }&stationDestName=${destination.name}&outwardDateTime=${departureDay
          .clone()
          .hour(16)
          .minute(0o5)
          .format('YYYY-MM-DDTHH:mm')}`,
        departure: departureDay.clone().hour(15).minute(46).format('YYYY-MM-DDTHH:mm'),
        arrival: nextDay.clone().hour(13).minute(19).format('YYYY-MM-DDTHH:mm'),
        duration: 45000000,
        origin: 'Bordeaux',
        destination: 'Budapest',
        legs: [
          {
            origin: { name: 'Bordeaux' },
            destination: { name: 'Paris Montparnasse' },
            departure: departureDay
              .clone()
              .hour(15)
              .minute(46)
              .format('YYYY-MM-DDTHH:mm'),
            arrival: departureDay.clone().hour(17).minute(49).format('YYYY-MM-DDTHH:mm'),
            mode: 'train',
            operator: { name: 'SNCF' },
            line: {
              id: 'TGV 8582',
              type: 'train',
              reference: '8282',
              name: 'TGV 8582',
            },
          },
          ParisWienDirectNightjet(date),
          {
            origin: { name: 'Wien Hbf' },
            destination: { name: 'Budapest-Keleti pu' },
            departure: nextDay.clone().hour(10).minute(42).format('YYYY-MM-DDTHH:mm'),
            arrival: nextDay.clone().hour(13).minute(19).format('YYYY-MM-DDTHH:mm'),
            mode: 'train',
            operator: { name: 'obb' },
            line: {
              id: 'EC 143',
              type: 'train',
              name: 'EC 143',
            },
          },
        ],
        mode: 'train',
        operator: 'OBB',
        source: 'manual',
        nighttrain: true,
      });
    } else if (origin.name === 'Toulouse' && destination.name === 'Berlin') {
      manualJourneysToReturn.push({
        id: 'manual-toulouse-paris-berlin',
        amount: 139,
        link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
          origin.name
        }&stationDestName=${destination.name}&outwardDateTime=${departureDay
          .clone()
          .hour(12)
          .minute(27)
          .format('YYYY-MM-DDTHH:mm')}`,
        departure: departureDay.clone().hour(12).minute(27).format('YYYY-MM-DDTHH:mm'),
        arrival: nextDay.clone().hour(9).minute(58).format('YYYY-MM-DDTHH:mm'),
        duration: 42000000,
        origin: 'Toulouse',
        destination: 'Berlin',
        legs: [
          {
            origin: { name: 'Toulouse' },
            destination: { name: 'Paris Montparnasse' },
            departure: departureDay
              .clone()
              .hour(12)
              .minute(27)
              .format('YYYY-MM-DDTHH:mm'),
            arrival: departureDay.clone().hour(17).minute(9).format('YYYY-MM-DDTHH:mm'),
            mode: 'train',
            operator: { name: 'SNCF' },
            line: {
              id: 'TGV 8512',
              type: 'train',
              reference: '8512',
              name: 'TGV 8512',
            },
          },
          ParisBerlinDirectNightjet(date),
        ],
        mode: 'train',
        operator: 'OBB',
        source: 'manual',
        nighttrain: true,
      });
    } else if (origin.name === 'Toulouse' && destination.name === 'Wien') {
      manualJourneysToReturn.push({
        id: 'manual-toulouse-paris-wien',
        amount: 149,
        link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
          origin.name
        }&stationDestName=${destination.name}&outwardDateTime=${departureDay
          .clone()
          .hour(12)
          .minute(27)
          .format('YYYY-MM-DDTHH:mm')}`,
        departure: departureDay.clone().hour(12).minute(27).format('YYYY-MM-DDTHH:mm'),
        arrival: nextDay.clone().hour(10).minute(13).format('YYYY-MM-DDTHH:mm'),
        duration: 45000000,
        origin: 'Toulouse',
        destination: 'Vienna',
        legs: [
          {
            origin: { name: 'Toulouse' },
            destination: { name: 'Paris Montparnasse' },
            departure: departureDay
              .clone()
              .hour(12)
              .minute(27)
              .format('YYYY-MM-DDTHH:mm'),
            arrival: departureDay.clone().hour(17).minute(9).format('YYYY-MM-DDTHH:mm'),
            mode: 'train',
            operator: { name: 'SNCF' },
            line: {
              id: 'TGV 8512',
              type: 'train',
              reference: '8512',
              name: 'TGV 8512',
            },
          },
          ParisWienDirectNightjet(date),
        ],
        mode: 'train',
        operator: 'OBB',
        source: 'manual',
        nighttrain: true,
      });
    } else if (origin.name === 'Nantes' && destination.name === 'Wien') {
      manualJourneysToReturn.push({
        id: 'manual-nantes-wien',
        amount: 139,
        link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
          origin.name
        }&stationDestName=${destination.name}&outwardDateTime=${departureDay
          .clone()
          .hour(16)
          .minute(0o5)
          .format('YYYY-MM-DDTHH:mm')}`,
        departure: departureDay.clone().hour(16).minute(5).format('YYYY-MM-DDTHH:mm'),
        arrival: nextDay.clone().hour(10).minute(13).format('YYYY-MM-DDTHH:mm'),
        duration: 45000000,
        origin: 'Nantes',
        destination: 'Vienna',
        legs: [
          {
            origin: { name: 'Nantes' },
            destination: { name: 'Paris Montparnasse' },
            departure: departureDay.clone().hour(16).minute(5).format('YYYY-MM-DDTHH:mm'),
            arrival: departureDay.clone().hour(18).minute(25).format('YYYY-MM-DDTHH:mm'),
            mode: 'train',
            operator: { name: 'SNCF' },
            line: {
              id: 'TGV 8880',
              type: 'train',
              reference: '8880',
              name: 'TGV 8880',
            },
          },
          ParisWienDirectNightjet(date),
        ],
        mode: 'train',
        operator: 'OBB',
        source: 'manual',
        nighttrain: true,
      });
    }
  } else if (
    //else if date is Monday or Wednesday or Friday
    departureDay.day() === 1 ||
    departureDay.day() === 3 ||
    departureDay.day() === 5
  ) {
    if (origin.name === 'Berlin' && destination.name === 'Paris') {
      manualJourneysToReturn.push({
        id: 'manual-berlin-paris',
        amount: ParisNightjetAugustToOctoberPerturbations(departureDay).yes
          ? PRICE_UNKNOWN_CONST
          : 119,
        link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
          origin.name
        }&stationDestName=${destination.name}&outwardDateTime=${departureDay
          .clone()
          .subtract(1, 'day')
          .hour(20)
          .minute(0o7)
          .format('YYYY-MM-DDTHH:mm')}`,
        departure: '2024-01-01T20:07:00+01:00',
        arrival: '2024-01-02T10:24:00+01:00',
        duration: 45000000,
        origin: 'Berlin Hbf',
        destination: "Paris Gare de l'Est",
        legs: [BerlinParisDirectNightjet(departureDay)],
        mode: 'train',
        operator: 'OBB',
        source: 'obb',
        nighttrain: true,
      });
    } else if (origin.name === 'Wien' && destination.name === 'Paris') {
      manualJourneysToReturn.push({
        id: 'manual-vienna-paris',
        amount: isNightJetWienParisUnavailable(departureDay).yes
          ? PRICE_UNKNOWN_CONST
          : 109,
        link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
          origin.name
        }&stationDestName=${destination.name}&outwardDateTime=${departureDay
          .clone()
          .subtract(1, 'day')
          .hour(19)
          .minute(38)
          .format('YYYY-MM-DDTHH:mm')}`,
        departure: '2024-08-01T19:38:00+01:00',
        arrival: '2024-08-02T10:24:00+01:00',
        duration: 45000000,
        origin: 'Vienna',
        destination: 'Paris',
        legs: [WienParisDirectNightjet(departureDay)],
        mode: 'train',
        operator: 'OBB',
        source: 'obb',
        nighttrain: true,
      });
    }

    if (origin.name === 'Wien' && destination.name === 'Strasbourg') {
      manualJourneysToReturn.push({
        id: 'manual-wien-strasbourg',
        amount: 119,
        link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
          origin.name
        }&stationDestName=${destination.name}&outwardDateTime=${departureDay
          .clone()
          .hour(19)
          .minute(38)
          .format('YYYY-MM-DDTHH:mm')}`,

        departure: '2024-01-06T19:38:00+01:00',
        arrival: '2024-01-07T06:00:00+01:00',
        duration: 45000000,
        origin: 'Vienna',
        destination: 'Strasbourg',
        legs: [
          {
            origin: { name: 'Vienna' },
            destination: { name: 'Strasbourg' },
            departure: '2024-01-06T19:38:00+01:00',
            arrival: '2024-01-07T06:00:00+01:00',
            mode: 'train',
            operator: { name: 'obb' },
            amenities: {
              bike: bikePrice('Nightjet', '2024-01-06T19:38:00+01:00', '', 0, null),
            },
            line: {
              id: 'NJ 468',
              name: 'Nightjet',
              type: 'train',
            },
            unavailable: ParisNightjetAugustToOctoberPerturbations(departureDay),
          },
        ],
        mode: 'train',
        operator: 'OBB',
        source: 'obb',
        nighttrain: true,
      });
    } else if (origin.name === 'Berlin' && destination.name === 'Strasbourg') {
      manualJourneysToReturn.push({
        id: 'manual-berlin-strasbourg',
        amount: 119,
        link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
          origin.name
        }&stationDestName=${destination.name}&outwardDateTime=${departureDay
          .clone()
          .hour(18)
          .minute(43)
          .format('YYYY-MM-DDTHH:mm')}`,

        departure: '2024-01-06T20:18:00+01:00',
        arrival: '2024-01-07T05:44:00+01:00',
        duration: 45000000,
        origin: 'Berlin',
        destination: 'Strasbourg',
        legs: [
          {
            origin: { name: 'Berlin Hbf' },
            destination: { name: 'Strasbourg' },
            departure: '2024-01-06T18:43:00+01:00',
            arrival: '2024-01-07T06:00:00+01:00',
            mode: 'train',
            operator: { name: 'obb' },
            amenities: {
              bike: bikePrice('Nightjet', '2024-01-06T18:43:00+01:00', '', 0, null),
            },
            line: {
              id: 'NJ 40424',
              name: 'Nightjet',
              type: 'train',
            },
            unavailable: ParisNightjetAugustToOctoberPerturbations(departureDay),
          },
        ],
        mode: 'train',
        operator: 'OBB',
        source: 'obb',
        nighttrain: true,
      });
    } else if (origin.name === 'Budapest' && destination.name === 'Paris') {
      manualJourneysToReturn.push({
        id: 'manual-budapest-zurich-paris',
        amount: PRICE_UNKNOWN_CONST,
        link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
          origin.name
        }&stationDestName=${destination.name}&outwardDateTime=${departureDay
          .clone()
          .hour(20)
          .minute(30)
          .format('YYYY-MM-DDTHH:mm')}`,
        departure: '2024-01-06T20:55:00+01:00',
        arrival: '2024-01-07T13:38:00+01:00',
        duration: 45000000,
        origin: 'Budapest',
        destination: 'Paris',
        legs: [
          {
            origin: { name: 'Budapest-Keleti' },
            destination: { name: 'Zurich HB' },
            departure: '2024-01-06T20:55:00+01:00',
            arrival: '2024-01-07T08:20:00+01:00',
            mode: 'train',
            operator: { name: 'obb' },
            amenities: {
              bike: bikePrice('Nightjet', '2024-01-06T20:55:00+01:00', '', 0, null),
            },
            line: {
              id: 'EN 40462',
              type: 'train',
              name: 'Nightjet',
            },
          },
          {
            origin: { name: 'Zurich HB' },
            destination: { name: 'Paris Gare de Lyon' },
            departure: '2024-01-07T09:34:00+01:00',
            arrival: '2024-01-07T13:38:00+01:00',
            mode: 'train',
            operator: { name: 'TGV Lyria' },
            amenities: {
              bike: bikePrice('Lyria', '2024-01-07T09:34:00+01:00', '', 0, null),
            },
            line: {
              id: 'TGV 9210',
              type: 'train',
              name: '9210',
            },
          },
        ],
        mode: 'train',
        operator: 'OBB',
        source: 'manual',
        nighttrain: true,
      });
    }
    if (origin.name === 'Wien' && destination.name === 'Bruxelles') {
      manualJourneysToReturn.push({
        id: 'manual-vienna-brussel',
        amount: 119,
        link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
          origin.name
        }&stationDestName=${destination.name}&outwardDateTime=${departureDay
          .clone()
          .hour(18)
          .minute(0)
          .format('YYYY-MM-DDTHH:mm')}`,
        departure: '2024-01-01T18:08:00+01:00',
        arrival: '2024-01-02T09:56:00+01:00',
        duration: 45000000,
        origin: 'Vienna',
        destination: 'Brussel',
        legs: [
          {
            origin: { name: 'Vienna' },
            destination: { name: 'Brussel' },
            departure: '2024-01-01T18:08:00+01:00',
            arrival: '2024-01-02T09:56:00+01:00',
            mode: 'train',
            operator: { name: 'obb' },
            amenities: {
              bike: bikePrice('Nightjet', '2024-01-01T18:08:00+01:00', '', 0, null),
            },
            line: {
              id: 'NJ 40468',
              name: 'Nightjet',
              type: 'train',
            },
          },
        ],
        mode: 'train',
        operator: 'OBB',
        source: 'obb',
        nighttrain: true,
      });
    } else if (origin.name === 'Berlin' && destination.name === 'Bruxelles') {
      manualJourneysToReturn.push({
        id: 'manual-berlin-brussel',
        amount: 55,
        link: `https://tickets.oebb.at/en/ticket?stationOrigName=${
          origin.name
        }&stationDestName=${destination.name}&outwardDateTime=${departureDay
          .clone()
          .hour(19)
          .minute(0o7)
          .format('YYYY-MM-DDTHH:mm')}`,
        departure: '2024-01-01T19:07:00+01:00',
        arrival: '2024-01-02T09:55:00+01:00',
        duration: 45000000,
        origin: 'Berlin',
        destination: 'Brussel',
        legs: [
          {
            origin: { name: 'Berlin' },
            destination: { name: 'Brussel' },
            departure: '2024-01-01T19:07:00+01:00',
            arrival: '2024-01-02T09:55:00+01:00',
            mode: 'train',
            operator: { name: 'obb' },
            amenities: {
              bike: bikePrice('Nightjet', '2024-01-01T19:07:00+01:00', '', 0, null),
            },
            line: {
              id: 'NJ 424',
              name: 'Nightjet',
              type: 'train',
            },
          },
        ],
        mode: 'train',
        operator: 'OBB',
        source: 'obb',
        nighttrain: true,
      });
    }
  }
  console.log(
    'manualInputs: ' + origin.name + ' ' + destination.name + ' ' + date + ' ' + id
  );
  console.log('manualjourneys: ' + manualJourneysToReturn[0]);
  if (id) {
    manualJourneysToReturn.filter((journey) => journey.id == id);
  }
  return manualJourneysToReturn;
};

export default hardcodedJourneys;
