'use client';

import { useState } from 'react';

import { PopoverClose } from '@radix-ui/react-popover';
import { BusIcon, Car, DotIcon, TrainIcon } from 'lucide-react';
import { ChevronDown, XCircle } from 'lucide-react';
import { useTranslations } from 'next-intl';

import { Button } from '../ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../ui/card';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { RadioGroup, RadioGroupItem } from '../ui/radio-group';
import { Slider } from '../ui/slider';
import { Label } from '../ui/label';
import { MAXIMUM_CONNECTIONS } from './TicketCardsList';
import { DURATION_RANGE, PRICE_RANGE } from './AdvisedStations';
import NightTrainToggle from './NightTrainToggle';

interface Props {
  priceRange: Array<number>;
  durationRange: Array<number>;
  setPriceRange: (value: Array<number>) => void;
  setDurationRange: (value: Array<number>) => void;
  transportMode: string;
  setTransportMode: (value: string) => void;
  className?: string;
  size?: 'default' | 'sm' | 'xs' | 'lg' | 'selector' | 'icon';
  skiMode?: boolean;
  setSkiMode: (value: boolean) => Promise<URLSearchParams>;
  filterMaxConnectionCount: number;
  setFilterMaxConnectionCount: (value: number) => void;
  nightTrainMode: boolean;
  setNightTrainMode: (value: boolean) => void;
}

const DEFAULT_ADVISED_CONNECTIONS_FILTER = [0, 2];

export default function ResultsFilters({
  setTransportMode,
  setPriceRange,
  durationRange,
  setDurationRange,
  priceRange,
  transportMode,
  className,
  size,
  skiMode,
  setSkiMode,
  filterMaxConnectionCount,
  setFilterMaxConnectionCount,
  nightTrainMode,
  setNightTrainMode,
}: Props) {
  const t = useTranslations('Advises');

  const statuses = [
    {
      value: 'all',
      label: 'All',
      icon: DotIcon,
    },
    {
      value: 'car',
      label: 'Car only',
      icon: Car,
    },
    {
      value: 'train',
      label: 'Train only',
      icon: TrainIcon,
    },
    {
      value: 'bus',
      label: 'Bus only',
      icon: BusIcon,
    },
  ];

  return (
    <div
      className={`flex flex-row items-center justify-start gap-x-1.5 overflow-x-auto scrollbar-hide ${className}`}
    >
      <PriceFilter
        priceRange={priceRange}
        setPriceRange={setPriceRange}
        size={size}
        t={t}
      />
      <NightTrainToggle
        nightTrainMode={nightTrainMode}
        onClick={() => setNightTrainMode(!nightTrainMode)}
      />
      <DurationFilter
        durationRange={durationRange}
        setDurationRange={setDurationRange}
        size={size}
        t={t}
      />

      <ConnexionsFilter
        filterMaxConnectionCount={filterMaxConnectionCount}
        setFilterMaxConnectionCount={setFilterMaxConnectionCount}
        connectionCountOptions={DEFAULT_ADVISED_CONNECTIONS_FILTER}
      />

      <SkiModeFilter skiMode={skiMode} setSkiMode={setSkiMode} size={size} t={t} hide />
    </div>
  );
}

const SkiModeFilter = ({ skiMode, setSkiMode, size, t, hide }) => {
  if (hide) {
    return null;
  }
  return (
    <Button
      variant={skiMode ? 'default' : 'outline'}
      size={size}
      className="min-w-fit"
      onClick={() => setSkiMode(!skiMode)}
    >
      <span className="h-3 mb-2 -ml-0.5 mr-1"> ⛷️ </span>
      {t('filters.interestPoint.ski')}
      {skiMode ? (
        <XCircle
          onClick={async (e) => {
            await setSkiMode(false);
            e.stopPropagation();
          }}
          className="ml-2 h-4 w-4 -mr-1 rounded-full hover:bg-slate-300"
        />
      ) : null}
    </Button>
  );
};

const DurationFilter = ({ durationRange, setDurationRange, size, t }) => {
  const [localDurationRange, setlocalDurationRange] =
    useState<Array<number>>(DURATION_RANGE);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={durationRange[1] < DURATION_RANGE[1] ? 'default' : 'outline'}
          size={size}
          className="min-w-fit"
        >
          {durationRange[1] >= DURATION_RANGE[1]
            ? t('filters.duration.title')
            : `${t('filters.duration.prefix')} ${durationRange[1]}h`}
          {durationRange[1] < DURATION_RANGE[1] ? (
            <XCircle
              onClick={(e) => {
                setDurationRange(DURATION_RANGE);
                e.stopPropagation();
              }}
              className="ml-2 h-4 w-4 rounded-full hover:bg-slate-300"
            />
          ) : (
            <ChevronDown className="ml-2 h-4 w-4" />
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-72 p-0">
        <Card className="border-none">
          <CardHeader>
            <CardTitle>{t('filters.duration.title')}</CardTitle>
            <CardDescription>
              {t('filters.duration.subtitle')} {localDurationRange[1]}h
            </CardDescription>
          </CardHeader>
          <CardContent>
            <Slider
              defaultValue={
                durationRange[1] < DURATION_RANGE[1]
                  ? [durationRange[1]]
                  : [DURATION_RANGE[1]]
              }
              max={DURATION_RANGE[1]}
              min={1}
              step={1}
              onValueChange={(value) => setlocalDurationRange([0, value[0]])}
              className="relative w-[100%] cursor-pointer"
            />
          </CardContent>
          <CardFooter className="justify-end">
            <PopoverClose asChild>
              <Button
                onClick={() => {
                  setDurationRange(localDurationRange);
                }}
              >
                {t('filters.duration.validateBtn')}
              </Button>
            </PopoverClose>
          </CardFooter>
        </Card>
      </PopoverContent>
    </Popover>
  );
};

const PriceFilter = ({ priceRange, setPriceRange, size, t }) => {
  const [localpriceRange, setlocalPriceRange] = useState<Array<number>>(PRICE_RANGE);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={priceRange[1] < PRICE_RANGE[1] ? 'default' : 'outline'}
          size={size}
          className="min-w-fit"
        >
          {priceRange[1] >= PRICE_RANGE[1]
            ? t('filters.price.title')
            : `${t('filters.price.prefix')} ${priceRange[1]}` + '€'}
          {priceRange[1] < PRICE_RANGE[1] ? (
            <XCircle
              onClick={(e) => {
                setPriceRange(PRICE_RANGE);
                e.stopPropagation();
              }}
              className="ml-2 h-4 w-4 rounded-full hover:bg-slate-300"
            />
          ) : (
            <ChevronDown className="ml-2 h-4 w-4" />
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-72 p-0">
        <Card className="border-none">
          <CardHeader>
            <CardTitle>{t('filters.price.title')}</CardTitle>
            <CardDescription>
              {t('filters.price.subtitle')} {localpriceRange[1]}€
            </CardDescription>
          </CardHeader>
          <CardContent>
            <Slider
              defaultValue={
                priceRange[1] < PRICE_RANGE[1] ? [priceRange[1]] : [PRICE_RANGE[1]]
              }
              max={PRICE_RANGE[1]}
              min={20}
              step={1}
              onValueChange={(value) => setlocalPriceRange([0, value[0]])}
              className="relative w-[100%] cursor-pointer"
            />
          </CardContent>
          <CardFooter className="justify-end">
            <PopoverClose asChild>
              <Button onClick={() => setPriceRange(localpriceRange)}>
                {t('filters.price.validateBtn')}
              </Button>
            </PopoverClose>
          </CardFooter>
        </Card>
      </PopoverContent>
    </Popover>
  );
};

const TransportModeFilter = ({ transportMode, setTransportMode, size, t, statuses }) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={transportMode !== 'all' ? 'default' : 'outline'}
          size={size}
          className="min-w-fit"
        >
          {transportMode === 'all'
            ? t('filters.mode.title')
            : t(`filters.mode.${transportMode}`)}
          <ChevronDown className="ml-2 h-4 w-4" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-56">
        <RadioGroup value={transportMode} onValueChange={setTransportMode}>
          {statuses.map((status, key) => (
            <div
              className="flex items-center cursor-pointer space-x-1.5 hover:bg-secondary rounded-md px-2 py-3"
              key={key}
            >
              <RadioGroupItem value={status.value} id={status.value} />
              <Label
                htmlFor={status.value}
                className="flex flex-row items-center cursor-pointer w-full"
              >
                <status.icon className="mr-2 h-4 w-4" />
                <span> {t(`filters.mode.${status.value}`)}</span>
              </Label>
            </div>
          ))}
        </RadioGroup>
      </PopoverContent>
    </Popover>
  );
};

export const ConnexionsFilter = ({
  filterMaxConnectionCount,
  setFilterMaxConnectionCount,
  connectionCountOptions,
}: {
  filterMaxConnectionCount: number;
  setFilterMaxConnectionCount: (value: number) => void;
  connectionCountOptions: number[];
}) => {
  const t = useTranslations('Tickets');

  const isFiltered = filterMaxConnectionCount < connectionCountOptions[1];
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant={isFiltered ? 'default' : 'outline'} className="min-w-fit">
          {isFiltered
            ? t('filtering.connexions.many', { count: filterMaxConnectionCount })
            : t('filtering.connexions.title')}
          <ChevronDown className="ml-2 h-4 w-4" />
        </Button>
      </PopoverTrigger>
      <PopoverContent align="start" className="w-60">
        <RadioGroup
          value={filterMaxConnectionCount.toString()}
          onValueChange={(value) => setFilterMaxConnectionCount(parseInt(value))}
        >
          {Array.from(
            { length: connectionCountOptions[1] - connectionCountOptions[0] },
            (_, i) => connectionCountOptions[0] + i
          ).map((status, key) => (
            <div
              className="flex items-center cursor-pointer space-x-1.5 hover:bg-secondary rounded-md px-2 py-3"
              key={key}
            >
              <RadioGroupItem value={status.toString()} id={status.toString()} />
              <Label
                htmlFor={status.toString()}
                className="flex flex-row items-center w-full cursor-pointer"
              >
                <span>{t('filtering.connexions.many', { count: status })}</span>
              </Label>
            </div>
          ))}
        </RadioGroup>
        {isFiltered ? (
          <PopoverClose asChild>
            <Button
              variant="link"
              className="text-orange"
              onClick={() => {
                setFilterMaxConnectionCount(MAXIMUM_CONNECTIONS);
              }}
            >
              Clear
            </Button>
          </PopoverClose>
        ) : null}
      </PopoverContent>
    </Popover>
  );
};
