//Those names have to match with showCasedCities.json names

export const top5OriginStations = ['Paris', 'Brussels', 'Lyon', 'Marseille', 'Nantes'];

export const top5DestinationsFrom = (originStationName: string) => {
  const destinationsMap = {
    Paris: ['Berlin', 'Venice', 'Barcelona', 'Wien', 'Amsterdam'],
    Bruxelles: ['Berlin', 'Roma', 'Barcelona', 'Wien', 'Praha'],
    Lyon: ['Barcelona', 'Berlin', 'Amsterdam', 'Wien', 'Torino'],
    Marseille: ['Berlin', 'Barcelona', 'Roma', 'Milano Centrale', 'Venice'],
    Toulouse: ['Barcelona', 'Wien', 'Berlin', 'Amsterdam', 'Roma'],
    Nantes: ['Madrid', 'Barcelona', 'Venice', 'Berlin', 'Wien'],
    Lille: ['Berlin', 'Barcelona', 'Hamburg', 'Wien', 'Amsterdam'],
    default: ['Berlin', 'Roma', 'Barcelona', 'Wien', 'Copenhagen'],
  };
  return destinationsMap[originStationName] || destinationsMap['default'];
};
export const allowBrowsingDestinations = (originStationName: string) => {
  return top5OriginStations.includes(originStationName);
};
