'use client';

import { useEffect } from 'react';

import { useLocale } from 'next-intl';

import { useDestinationName, useOriginName } from 'hooks/useQuerySearch';
import { useStationAutoComplete } from 'hooks/useStationAutoComplete';
import { utf8_to_latin1 } from 'utils/stationsUtils';

import { getCountryName } from 'utils/localisation';
import ReactCountryFlag from 'react-country-flag';

interface IAppAutocomplete {
  locationName: string;
  changeOrigin?: boolean;
  onClick: () => void;
  //external trigger
  onEnter?: boolean;
}

const AppAutocomplete = ({
  locationName,
  changeOrigin,
  onClick,
  onEnter,
}: IAppAutocomplete) => {
  const [, setOriginName] = useOriginName();
  const [, setDestinationName] = useDestinationName();

  const locale = useLocale();

  useEffect(() => {
    if (displayedStations && displayedStations.length > 0) {
      if (changeOrigin) {
        setOriginName(displayedStations[0].name).catch(console.error);
      } else {
        setDestinationName(displayedStations[0].name).catch(console.error);
      }
      onClick();
    }
  }, [onEnter]);

  const displayedStations = useStationAutoComplete(locationName);

  return (
    <div className="flex w-full flex-col">
      {displayedStations.slice(0, 5).map((data, index) => (
        <div
          onClick={async () => {
            if (changeOrigin) {
              await setOriginName(data.name);
            } else {
              await setDestinationName(data.name);
            }
            onClick();
          }}
          key={index}
          className="items-center flex w-full cursor-pointer gap-x-3 rounded-xl p-2 py-2 pr-8 pl-6 duration-300 hover:bg-gray-200 hover:bg-opacity-40  md:flex-row md:py-3"
        >
          <div className="bg-gray-250 mr-4 flex h-12 min-w-[48px] items-center justify-center rounded-xl text-lg bg-secondary/70">
            <ReactCountryFlag countryCode={data.country} svg className="w-8 h-full" />
          </div>
          <div className="md:mt-0">
            <h3 className="text-sm overflow-clip text-base font-medium text-gray-500">
              {utf8_to_latin1(data.name)}
            </h3>

            <span className="text-sm text-gray-300">
              {data[`info_${locale}`]
                ? utf8_to_latin1(data[`info_${locale}`])
                : getCountryName(locale, data.country)}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AppAutocomplete;
