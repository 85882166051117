'use client';

import { useState } from 'react';

import { ClockIcon } from '@heroicons/react/outline';
import { sendGAEvent } from '@next/third-parties/google';
import { ChevronDown, ChevronRightIcon, MoonIcon, ShuffleIcon } from 'lucide-react';
import { DateTime } from 'luxon';
import Image from 'next/image';
import { useSession } from 'next-auth/react';
import { useLocale, useTranslations } from 'next-intl';
import { PRICE_UNKNOWN_CONST } from 'pages/api/getDailyFares';
import { countTrainTravelLegs, formatPrice } from 'utils';
import { formatLinkDate, getDiffHoursFromDates, isNextDay } from 'utils/datesUtils';
import { Journey } from 'utils/journey';
import { CompaniesImages, LineImages as Line } from 'utils/OperatorsAndLinesLiveries';
import { operatorWebsiteName } from 'utils/operatorIconsAndCTA';
import { operatorIcons } from 'utils/operatorIconsAndCTA';
import { OperatorsShortnames as Operator } from 'utils/OperatorsShortnames';

import TrainlineIcon from '../../public/assets/icons/trainline.svg';
import { Badge } from '../ui/badge';
import { Button } from '../ui/button';

import AppJourneyLegs from './AppJourneyLegs';
import BikeAvailability from './BikeAvailability';
import QuoteDialog from './QuoteDialog';
import AvaibilityCalendarDialog from './AppAvailabilityCalendar';
import PricedQuoteDialogForAdmins from './PricedQuoteDialogForAdmins';

const TicketCard = ({ offer }: { offer: Journey }) => {
  const t = useTranslations('Tickets');
  const [isUnfold, SetUnfold] = useState<boolean>(false);
  const locale = useLocale();
  const AvaibilityCalendar_FLAG = false;
  const session = useSession()?.data;
  const departure = DateTime.fromISO(offer.departure) as DateTime;
  const arrival = DateTime.fromISO(offer.arrival) as DateTime;
  const duration = getDiffHoursFromDates(arrival, departure);
  const numberOfTrainLegs = countTrainTravelLegs(offer.legs);

  // Removing 1h to departure time so that departures up to 01h the next day are considered as the same day
  const adjustedDeparture = departure.minus({ hours: 1 });
  let departureDay = adjustedDeparture.startOf('day').plus({ days: 1 });
  let numberOfNights = 0;
  while (departureDay < arrival) {
    numberOfNights++;
    departureDay = departureDay.plus({ days: 1 });
  }

  const nextDay = isNextDay(departure, arrival);

  if (!(offer.legs?.length > 0)) {
    return null;
  }
  const unavailableLeg = offer.legs?.find((leg) => leg?.unavailable?.yes);
  const unavailable = unavailableLeg?.unavailable?.yes;
  const unavailableReason = unavailableLeg?.unavailable?.reason;

  return (
    <div
      className={`relative bg-white my-2.5 block h-auto overflow-hidden rounded-xl border-[1px] hover:border-slate-300 transition-all`}
    >
      {unavailable && AvaibilityCalendar_FLAG && (
        <AvaibilityCalendarDialog
          origin={offer.origin}
          destination={offer.destination}
          id={offer.id}
        />
      )}

      <div
        className={`${unavailable ? 'bg-purpple-background' : '@xl:hover:bg-gradient-to-r from-transparent to-secondary max-sm:bg-gradient-to-r bg-white'} card relative pt-2 px-2 @xl:pt-4 @xl:pb-1 z-10 w-full cursor-pointer block text-left peer-focus:block `}
        onClick={() => SetUnfold(!isUnfold)}
      >
        {(offer.nighttrain && offer.mode == 'train') ||
        (offer.value && offer.value?.length > 0) ? (
          <div className="flex flex-row-reverse justify-start items-center gap-1.5 flex-end w-full h-4 @xl:h-2 z-20">
            <UnavailabilityReason
              available={!unavailable}
              unavailableReason={unavailableReason}
              t={t}
            />
            <OfferValue value={offer.value} t={t} unavailable={unavailable} />
            <NightTrainBadge offer={offer} t={t} />
          </div>
        ) : null}

        <div className="flex flex-row justify-between w-full">
          <div className="hidden @xl:flex">
            <OperatorLivery offer={offer} />
          </div>

          <div className="grid w-full grid-cols-[auto,0.9fr] @xl:grid-cols-[0.9fr,0.8fr,0.6fr]">
            <TimesAndOperators offer={offer} nextDay={nextDay} locale={locale} />

            <div className="hidden @xl:flex">
              <ConnectionsAndDuration
                offer={offer}
                t={t}
                duration={duration}
                numberOfTrainLegs={numberOfTrainLegs}
                numberOfNights={numberOfNights}
              />
            </div>
            <Fares
              offer={offer}
              t={t}
              unavailable={unavailable}
              numberOfTrainLegs={numberOfTrainLegs}
            />
          </div>
        </div>

        {isUnfold && (
          <div
            className={`absolute hidden @xl:-bottom-9 justify-end w-full right-0 @xl:px-2 @xl:flex text-slate-400`}
          >
            <ShowMoreButton isUnfold={isUnfold} SetUnfold={SetUnfold} t={t} />
          </div>
        )}

        <div
          className={`${isUnfold && 'hidden'} @xl:hidden absolute -bottom-11 w-full px-2 right-0 left-0 flex flex-row-reverse justify-between text-slate-400`}
        >
          <div className="flex gap-1">
            <JourneyDurationAndConnections
              offer={offer}
              t={t}
              duration={duration}
              numberOfTrainLegs={numberOfTrainLegs}
              numberOfNights={numberOfNights}
              isUnfold={isUnfold}
            />
            <ShowMoreButton isUnfold={isUnfold} SetUnfold={SetUnfold} t={t} />
          </div>
          <OperatorLivery offer={offer} />
        </div>
      </div>
      <div
        className={`${
          isUnfold
            ? 'h-auto translate-y-0 pt-4 pb-1.5 border-t border-solid border-gray-200'
            : 'pb-11 @xl:pb-0 z-0 h-0 -translate-y-12 overflow-hidden bg-white'
        } relative flex flex-col px-5 transition-all duration-300`}
        aria-hidden={!isUnfold}
      >
        <AppJourneyLegs journeyLegs={offer.legs} />
        <BookingButton offer={offer} locale={locale} t={t} session={session} />
      </div>
    </div>
  );
};

const OperatorLivery = ({ offer }) => {
  return (
    <div className="relative -ml-10 -mr-8 flex w-48 items-center">
      <Image
        key={offer.id}
        src={
          offer.operator == 'flix' && offer.mode == 'train'
            ? CompaniesImages('flixtrain')
            : Line(offer.legs[0])
        }
        alt={offer.legs[0].line?.name ?? offer.legs[0].operator.name ?? 'train'}
        height={50}
        width={120}
        className={''}
        placeholder="blur"
        blurDataURL={CompaniesImages(offer.operator) || CompaniesImages('train')}
        style={{
          maxWidth: '100%',
          height: 'auto',
        }}
      />
    </div>
  );
};
const ShowMoreButton = ({ isUnfold, SetUnfold, t }) => {
  return (
    <div
      className="flex flex-row items-center hover:underline cursor-pointer"
      onClick={() => SetUnfold(!isUnfold)}
    >
      <div className="text-sm font-light hidden @xl:block @xl:text-md text-slate-400">
        {isUnfold ? t('seeLess') : t('seeMore')}
      </div>
      <ChevronDown className={`h-5 w-5 ${isUnfold ? 'transform rotate-180' : ''}`} />
    </div>
  );
};
const Fares = ({ offer, t, unavailable, numberOfTrainLegs }) => {
  return (
    <div className="items-right flex flex-col items-end @xl:self-end justify-between py-2 px-1 text-right">
      <div className="flex w-max items-center gap-2">
        {unavailable ? (
          <h3 className="text-xl font-semibold text-orange">
            {t(`unavailable.default`)}
          </h3>
        ) : offer.amount == PRICE_UNKNOWN_CONST ? (
          <h3 className={`text-xl font-semibold`}>
            {t('numberOfTrains', { count: numberOfTrainLegs })}
          </h3>
        ) : (
          <h3
            className={`text-xl font-semibold ${offer.value && offer.value.includes('cheapest') && 'text-orange'}`}
          >
            {offer.source == 'manual' && (
              <span className="text-xs font-normal mr-1">{t('fare.from')}</span>
            )}
            {formatPrice(offer.amount)}
          </h3>
        )}
      </div>
    </div>
  );
};
const DepartureAndArrivalTimes = ({ offer, nextDay, locale }) => {
  return (
    <h3 className="flex flex-row whitespace-nowrap font-semibold">
      <p className="pr-1 font-medium text-primary">
        {formatLinkDate(offer.departure, locale)}{' '}
      </p>
      {'-'}
      <div className="pl-1 font-normal relative">
        {formatLinkDate(offer.arrival, locale)}
        {nextDay && (
          <p className="absolute right-0.5 text-right top-0 text-xs pl-1 font-normal text-slate-400 translate-x-full">
            +1
          </p>
        )}
      </div>
    </h3>
  );
};
const ConnectionsAndDuration = ({
  offer,
  t,
  duration,
  numberOfTrainLegs,
  numberOfNights,
}) => {
  return (
    <div className="items-left flex flex-col self-end flex-nowrap justify-between py-2 pr-2 text-lg">
      <Connections numberOfTrainLegs={numberOfTrainLegs} t={t} />
      <div className="flex w-max text-sm flex-row text-slate-400">
        <DurationDisplay
          offer={offer}
          duration={duration}
          t={t}
          numberOfNights={numberOfNights}
        />
        <BikeAvailability legs={offer.legs} />
      </div>
    </div>
  );
};
const TimesAndOperators = ({ offer, nextDay, locale }) => {
  return (
    <div className="items-left flex flex-col self-end flex-nowrap justify-between py-2 pr-2 text-lg overflow-x-hidden">
      <DepartureAndArrivalTimes offer={offer} nextDay={nextDay} locale={locale} />
      <OperatorsInfos numberOfTrainLegs={offer.legs.length} offer={offer} />
    </div>
  );
};
const UnavailabilityReason = ({ available, unavailableReason, t }) => {
  if (!available) {
    return (
      <div className="text-xs mr-1.5 font-semibold text-orange">
        {t(`unavailable.${unavailableReason}`)}
      </div>
    );
  }
  return null;
};
const OfferValue = ({ value, t, unavailable }) => {
  if (unavailable) {
    return null;
  }
  if (!value || value.length === 0) {
    return null;
  }

  const textColorClass = value.includes('cheapest') ? 'text-orange' : '';

  const displayValue = Array.isArray(value)
    ? value.map((va) => t(`tags.${va}`)).join(', ')
    : t(`tags.${value}`);

  return (
    <Badge variant="secondary" className={`overflow-visible px-2 bg-secondary`}>
      <p className={`${textColorClass}`}>{displayValue}</p>
    </Badge>
  );
};

export const NightTrainBadge = ({ offer, t }) => {
  if (offer.nighttrain && offer.mode == 'train') {
    return (
      <Badge
        variant="secondary"
        className="text-slate-400 overflow-visible px-2 gap-0.5 -ml-1"
      >
        <p>{t('tags.nighttrain')}</p>
        <MoonIcon absoluteStrokeWidth={true} className="h-3 w-3 self-center" />
      </Badge>
    );
  } else {
    return null;
  }
};

const OperatorsInfos = ({ numberOfTrainLegs, offer }) => {
  const uniqueOperators = [
    ...new Set(offer.legs.map((leg) => Operator(leg.operator.name) || offer.operator)),
  ];
  const displayText =
    uniqueOperators.length > 2
      ? `${numberOfTrainLegs} operators`
      : uniqueOperators.join(' + ');

  return (
    <div className="flex align-start font-light text-sm @xl:text-md whitespace-nowrap text-slate-400 overflow-hidden text-ellipsis">
      <span>{displayText}</span>
    </div>
  );
};

const JourneyDurationAndConnections = ({
  offer,
  t,
  duration,
  numberOfTrainLegs,
  numberOfNights,
  isUnfold,
}) => {
  if (isUnfold) return null;
  return (
    <div className="flex items-center w-max text-sm text-slate-400 font-light z-10">
      <div className="flex flex-row items-center">
        <ClockIcon className="mr-1 mb-[1px] h-[15px] w-[15px] self-center" />
        <DurationDisplay
          offer={offer}
          duration={duration}
          t={t}
          numberOfNights={numberOfNights}
        />
      </div>
      <div className="w-2.5" />

      <Connections numberOfTrainLegs={numberOfTrainLegs} t={t} icon />
      <BikeAvailability legs={offer.legs} />
    </div>
  );
};
const DurationDisplay = ({ offer, duration, t, numberOfNights }) => {
  return (
    <div className="font-light">
      {(offer.arrival && duration) || '-'}{' '}
      {offer.nighttrain && offer.mode == 'train' && (
        <span className="hidden @xl:inline-block">
          {' '}
          ({t('duration.nights', { count: numberOfNights })})
        </span>
      )}
    </div>
  );
};
const Connections = ({ numberOfTrainLegs, t, icon = false }) => {
  return (
    <>
      {numberOfTrainLegs > 1 ? (
        <div className="flex flex-row gap-1">
          {icon && <ShuffleIcon className="h-[14px] w-[14px] self-center" />}

          <p className="block">{t('changes.many', { count: numberOfTrainLegs - 1 })}</p>
        </div>
      ) : (
        <span>{t('changes.zero')}</span>
      )}
    </>
  );
};

const BookingButton = ({ offer, locale, t, session }) => {
  async function onClickGoogle(originName, destinationName, date) {
    const params = new URLSearchParams();
    params.append('startStation', originName);
    params.append('endStation', destinationName);
    params.append('date', new Date(date).toISOString());
    params.append('locale', locale);
    const link = await fetch(`/api/getTrainline?${params.toString()}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => res.json());

    window.open(link, '_blank');
  }

  if (offer.source !== 'directionEngine' && offer.source !== 'manual')
    return (
      <a
        target="_blank"
        href={offer.link}
        rel="noreferrer"
        className="@xl:w-fit self-center"
      >
        <Button
          id="button-operator-redirection"
          onClick={() => sendGAEvent({ event: 'sncfRedirectionClicked', value: '1' })}
          size="lg"
          className="peer mt-2 @xl:mt-0 mb-1.5 w-full @xl:w-80 self-center mx-auto font-bold group/button"
        >
          {operatorIcons(offer.source, offer.link)}
          <p>
            {t('bookBtn')} {operatorWebsiteName(offer.source, offer.link)}
          </p>
          <ChevronRightIcon className="h-5 w-5 ml-2 -mr-4 hidden @xl:group-hover/button:block" />
        </Button>
      </a>
    );
  else if (quotablesJourneys(offer)) {
    return (
      <div className="flex flex-row gap-2">
        <PricedQuoteDialogForAdmins offer={offer} session={session} />

        <QuoteDialog
          origin={offer.origin}
          destination={offer.destination}
          journeyId={offer.id as string}
          journeyLegs={offer.legs}
          session={session}
        />
      </div>
    );
  } else {
    return (
      <Button
        id="button-trainline-redirect"
        size="lg"
        onClick={() => onClickGoogle(offer.origin, offer.destination, offer.departure)}
        className="hidden peer mb-1.5 w-fit @xl:w-80 self-center bg-orange group/button font-bold"
      >
        <TrainlineIcon className="mr-3 h-5 w-5" />
        <p>
          {t('bookBtn')} {operatorWebsiteName(offer.source, offer.link)}
        </p>
        <ChevronRightIcon className="h-6 w-6 ml-2 -mr-4 hidden @xl:group-hover/button:block" />
      </Button>
    );
  }
};

function quotablesJourneys(offer: Journey) {
  // Extracted from the top cities where our users are from
  const topOrigins = new Set([
    'Paris',
    'Lyon',
    'Bruxelles',
    'Nantes',
    'Marseille',
    'Lille',
    'Toulouse',
    'Bordeaux',
    'Berlin',
    'Rennes',
    'Bari',
    'Wien',
    'Como',
    'Lesce Bled',
    'Warszawa',
    'Inverness',
  ]);
  const topDestination = new Set([
    'Venezia',
    'Zagreb',
    'Budapest',
    'Warszawa',
    'Praha',
    'København',
    'Stockholm',
    'Ljubljana',
    'Palermo',
    'Vilnius',
    'Wien',
    'Bari',
    'Como',
    'Lesce Bled',
    'Roma',
    'Berlin',
    'Cambridge Station',
    'Edinburgh Waverley',
    'Aberdeen',
    'Glasgow',
    'Inverness',
  ]);

  // We mirror it nonetheless because it costs nothing to cost the other way around too
  return (
    (topOrigins.has(offer.origin) && topDestination.has(offer.destination)) ||
    (topDestination.has(offer.origin) && topOrigins.has(offer.destination))
  );
}

export default TicketCard;
