'use client';

import { getPublicEnvVariable } from 'lib/utilsClientSide';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import { languageToCountryMapping } from 'utils/localisation';

enum WidgetState {
  Rendered = 1,
  NeedToRerender = 2,
}

const getYourGuidePartnerId = getPublicEnvVariable(
  process.env.NEXT_PUBLIC_GETYOURGUIDE_PARTNER_ID,
  'NEXT_PUBLIC_GETYOURGUIDE_PARTNER_ID'
);

export const GetYourGuideWidget = ({
  destination,
  locale,
}: {
  destination: string;
  locale: string;
}) => {
  const [destinationId, setDestinationId] = useState<string | null>(null);
  const [widgetState, setWidgetState] = useState<WidgetState>(WidgetState.Rendered);

  const country = languageToCountryMapping[locale];
  const t = useTranslations('Tickets.Activities');

  useEffect(() => {
    setDestinationId(getGetYourGuideDestinationId(destination));
    setWidgetState(WidgetState.NeedToRerender);
  }, [destination]);

  useEffect(() => {
    if (widgetState == WidgetState.NeedToRerender) {
      setWidgetState(WidgetState.Rendered);
    }
  }, [widgetState]);

  if (!destinationId) {
    return null;
  }

  // We need this hack to ensure GetYourGuide script is executed again when the destination changes
  // It is a GYG script limitation: we could implement our own script loader to avoid this
  if (widgetState == WidgetState.NeedToRerender) {
    return null;
  }

  return (
    <div className="mt-8">
      <script
        async
        defer
        src="https://widget.getyourguide.com/dist/pa.umd.production.min.js"
        data-gyg-partner-id={getYourGuidePartnerId}
      ></script>
      <div className="mb-2 flex outline-none items-center bg-orange bg-opacity-10 h-10 flex-grow rounded-full">
        <span className="flex w-full justify-center">
          <span className="font-semibold leading-none text-orange">{t('title')}</span>
        </span>
      </div>
      <div
        data-gyg-href="https://widget.getyourguide.com/default/activities.frame"
        data-gyg-location-id={destinationId}
        data-gyg-locale-code={country}
        data-gyg-widget="activities"
        data-gyg-number-of-items="3"
        data-gyg-partner-id={getYourGuidePartnerId}
      ></div>
    </div>
  );
};

// based on the name in the url origin param
const destinationIds = {
  Paris: '16',
  Wien: '7',
  Roma: '33',
  Berlin: '17',
  Barcelona: '45',
  Praha: '10',
  Budapest: '29',
  Madrid: '46',
  Amsterdam: '36',
  Venezia: '35',
  London: '57',
  Firenze: '32',
  Bruxelles: '8',
  'Bruxelles-Midi': '8',
  'München Hbf': '26',
  München: '26',
  'Edinburgh Waverley': '44',
  København: '12',
  'Milano Centrale': '139',
  Milano: '139',
  Stockholm: '50',
  Bordeaux: '287',
  Marseille: '292',
  Nice: '314',
  Zürich: '55',
  Glasgow: '438',
  Basel: '51',
  Lausanne: '463',
  Genève: '54',
  Warszawa: '41',
  Kraków: '40',
  Hamburg: '23',
  'San Sebastián': '94',
} as Record<string, string>;

function getGetYourGuideDestinationId(destName: string) {
  return destinationIds[destName] || null;
}
