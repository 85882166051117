import { greatCircleGeometry } from './utils';
import { EF_plane_data, cont_coeff, hold, detour_default } from './params';
import { Coord } from '@turf/turf';

interface EmissionFactors {
  [key: string]: {
    [key: string]: number;
  };
}

export interface DataPlane {
  kgCO2eq: number[];
  EF_tot: number[];
  colors: string[];
  NAME: string[];
  'Mean of Transport': string[];
}

export interface GeoPlane {
  colors: string;
  label: string;
  length: string;
  geometry: any;
}

function planeToGdf(
  tag1: Coord,
  tag2: Coord,
  EF_plane: EmissionFactors = EF_plane_data,
  contrails: number = cont_coeff,
  holding: number = hold,
  detour: number = detour_default,
  color_usage: string = '#ffffff',
  color_cont: string = '#ffffff'
): [DataPlane, GeoPlane] {
  // Compute geometry and distance (geodesic)
  const [geom_plane, bird] = greatCircleGeometry(tag1, tag2);

  // Different emission factors depending on the trip length
  let trip_category: string;
  if (bird < 1000) {
    trip_category = 'short';
  } else if (bird < 3500) {
    trip_category = 'medium';
  } else {
    trip_category = 'long';
  }

  // detour coefficient
  const adjustedBird = bird * detour;

  const emissionFactors = EF_plane[trip_category];

  const co2Factor = emissionFactors.combustion + emissionFactors.upstream;
  const nonCo2Factor = emissionFactors.combustion * (contrails - 1);

  const data_plane: DataPlane = {
    kgCO2eq: [adjustedBird * co2Factor + holding, adjustedBird * nonCo2Factor],
    EF_tot: [co2Factor, nonCo2Factor],
    colors: [color_usage, color_cont],
    NAME: ['Kerosene', 'Contrails'],
    'Mean of Transport': ['Plane', 'Plane'],
  };

  const geo_plane: GeoPlane = {
    colors: color_usage,
    label: 'Flight',
    length: `${Math.round(adjustedBird)}km`,
    geometry: geom_plane,
  };

  return [data_plane, geo_plane];
}
export { planeToGdf };
