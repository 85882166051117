import { useTranslations } from 'next-intl';

import { Button } from '../ui/button';

interface Props {
  className?: string;
  size?: 'default' | 'sm' | 'xs' | 'lg' | 'selector' | 'icon';
  directJourneys: boolean;
  setDirectJourneys: (value: boolean) => void;
}

export default function ResultsFilters({
  className,
  size,
  directJourneys,
  setDirectJourneys,
}: Props) {
  const t = useTranslations('Advises');

  return (
    <div
      className={`flex flex-row items-center justify-start gap-x-1.5 overflow-x-auto scrollbar-hide ${className}`}
    >
      {/* Direct routes filter */}

      <Button
        variant={directJourneys ? 'default' : 'outline'}
        size={size}
        className="min-w-fit"
        onClick={() => {
          setDirectJourneys(!directJourneys);
        }}
      >
        {t('filters.connexions.direct')}
      </Button>
    </div>
  );
}
