import { useEffect, useState } from 'react';

import { Close } from '@radix-ui/react-dialog';
import moment from 'moment';
import { CalendarIcon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Journey } from 'utils/journey';

import hardcodedJourneys, {
  getUnavailableDates,
} from '../../utils/fetchprices/hardcodedJourneys';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog';

import AppCalendar from './AppCalendar';
import { format, isWeekend } from 'date-fns';

export default function AvaibilityCalendarDialog({ origin, destination, id }) {
  const [unavailableDays, setUnavailableDays] = useState<Date[]>([]);

  useEffect(() => {
    const date = new Date();
    const allDatesOfTheTrimester: Date[] = [];
    const endOfCurrentTrimester = new Date(date.getFullYear(), date.getMonth() + 3, 0);

    for (let d = new Date(date); d <= endOfCurrentTrimester; d.setDate(d.getDate() + 1)) {
      allDatesOfTheTrimester.push(new Date(d));
    }

    const originTruncated = origin.split(' ')[0];
    const destinationTruncated = destination.split(' ')[0];
    const unavailableDates: Date[] = getUnavailableDates(
      originTruncated,
      destinationTruncated,
      allDatesOfTheTrimester,
      id
    );
    console.log(
      'origin:' +
        originTruncated +
        ' destination:' +
        destinationTruncated +
        ' date:' +
        date.toLocaleString() +
        ' id:' +
        id
    );
    setUnavailableDays(unavailableDates);
  }, [origin, destination, id]);

  const modifiers = {
    fullyBooked: unavailableDays,
    noService: unavailableDays,
  };

  function customDayContent(day) {
    const extraDot = isWeekend(day) ? (
      <div
        style={{
          height: '50px',
          width: '50px',
          borderRadius: '100%',
          background: 'orange',
          opacity: '0.5',
          position: 'absolute',
          top: 0,
          left: -15,
        }}
      />
    ) : null;

    return (
      <div style={{ position: 'relative' }}>
        {extraDot}
        <span>{format(day, 'd')}</span>
      </div>
    );
  }
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          variant="link"
          type="button"
          id="openAvailabilityCalendar"
          size="xs"
          className="absolute inset-0 h-full z-30 rounded-lg hover:opacity-100 opacity-0 bg-slate-400/20 backdrop-blur-sm flex items-center transition-opacity justify-center"
        >
          <CalendarIcon className="w-4 h-4 mr-2" />
          See availabilities
        </Button>
      </DialogTrigger>
      <DialogContent
        aria-describedby="dialog-description"
        className="max-sm:!max-w-full md:!max-w-full md:w-fit md:mx-24 p-4 md:p-6 lg:p-8 rounded-lg justify-center"
      >
        <DialogHeader>
          <DialogTitle className="text-xl font-bold mb-4 md:text-2xl lg:text-3xl">
            {origin} to {destination} <br></br>Night train availabilities
          </DialogTitle>
        </DialogHeader>
        <AppCalendar
          className="max-w-sm self-center mx-auto"
          months={1}
          disabledDates={modifiers.noService}
          dayContent={customDayContent}
        />
        <div className="flex justify-center -mt-2">
          <div className="flex items-center">
            <div className="w-4 h-4 bg-primary rounded-full mr-2"></div>
            <span className="text-sm">Available</span>
          </div>
          <div className="flex items-center ml-4">
            <div className="w-4 h-4 bg-muted-foreground/20 rounded-full mr-2"></div>
            <span className="text-sm">No Service</span>
          </div>
        </div>
        <p className="text-sm text-center text-gray-300 mt-1">
          {unavailableDays && unavailableDays.length} dates are not available
        </p>
        <p className="text-sm text-center text-gray-500">
          {unavailableDays && unavailableDays.map((day) => <p>{day.toDateString()}</p>)}
        </p>{' '}
        <div className="flex justify-end">
          <Close className="h-10 py-2 px-4 rounded-full text-white bg-primary text-primary-foreground hover:bg-primary/90">
            Close
          </Close>
        </div>
      </DialogContent>
    </Dialog>
  );
}
