import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { useLocale } from 'next-intl';

import { formatCheckDate } from 'utils';

interface Props {
  setCheckIn: (value: Date) => Promise<URLSearchParams>;
  checkIn: Date;
}
const DateButtons = ({ setCheckIn, checkIn }: Props) => {
  function updateDate(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  const locale = useLocale();
  const DO_NOT_DISPLAY = true;

  if (DO_NOT_DISPLAY) {
    return null;
  }
  return (
    <div className="mt-4 flex flex-row items-center justify-between">
      <button
        id="checkin-prev"
        className="rounded-full border border-gray-200 p-[5px] hover:bg-gray-100"
        onClick={() => setCheckIn(updateDate(checkIn, -1))}
      >
        <ChevronLeftIcon className="h-6" />
      </button>
      <div className="flex w-full justify-center text-center">
        <span className="text-md font-semibold">
          {checkIn ? formatCheckDate(checkIn, locale) : 'This week'}
        </span>
      </div>
      <button
        id="checkin-next"
        className=" rounded-full border border-gray-200 p-[5px] hover:bg-gray-100"
        onClick={() => setCheckIn(updateDate(checkIn, 1))}
      >
        <ChevronRightIcon className="h-6" />
      </button>
    </div>
  );
};

export default DateButtons;
