'use client';

import { useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useSession } from 'next-auth/react';
import { useTranslations } from 'next-intl';
import { useForm } from 'react-hook-form';

import { z } from 'zod';

import { collectFeedback } from 'utils/collectUserInputs';

import { Button } from '../ui/button';
import { Dialog, DialogContent, DialogTrigger } from '../ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';
import { Input } from '../ui/input';
import { Textarea } from '../ui/textarea';
import { toast } from '../ui/use-toast';

interface Props {
  originName?: string;
  destinationName?: string;
}

const JourneySubmissionCard = ({ originName, destinationName }: Props) => {
  const isInContextOfTickets = originName != null;
  const ticketsT = useTranslations('Tickets');
  const futureRoutesT = useTranslations('FutureRoutes');

  const mergedT = (key) => {
    if (isInContextOfTickets) {
      return ticketsT('JourneySubmissionCard.' + key);
    } else {
      return futureRoutesT('routeSubmission.' + key);
    }
  };

  const session = useSession()?.data;
  const [isOpen, setIsOpen] = useState(false);

  const formSchema = z.object({
    origin: z.string().min(1, {
      message: futureRoutesT('routeSubmission.originError'),
    }),
    destination: z.string().min(1, {
      message: futureRoutesT('routeSubmission.destinationError'),
    }),
    description: z.string().min(10, {
      message: futureRoutesT('routeSubmission.descriptionError'),
    }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      origin: originName,
      destination: destinationName,
    },
  });

  const submitItinerary = async (data: z.infer<typeof formSchema>) => {
    const email = session?.user?.email;

    if (email) {
      await collectFeedback(
        `👀 Missing itinerary from ${data.origin} to ${data.destination}\r\n` +
          `${data.description}`,
        email
      );
      toast({
        title: mergedT('toastSuccess.title'),
        description: mergedT('toastSuccess.subtitle'),
        duration: 5000,
      });
      //close the dialog
      setIsOpen(false);
    } else {
      toast({
        title: mergedT('toastError.title'),
        description: mergedT('toastError.subtitle'),
        duration: 5000,
      });
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button
          variant="link"
          size="sm"
          className="items-center my-4 text-sm font-medium flex backdrop-blur-sm bg-opacity-20"
        >
          {mergedT('btn')}
        </Button>
      </DialogTrigger>
      <DialogContent className="mr-6 mt-3 max-h-screen rounded-md border border-gray-200 bg-white text-sm shadow-md focus-within:outline-2">
        <Form {...form}>
          <form
            id="form-journeySubmission"
            onSubmit={form.handleSubmit(submitItinerary)}
            className="flex flex-col space-y-3"
          >
            <div className="flex flex-row items-start space-x-3">
              <FormField
                control={form.control}
                name="origin"
                render={({ field }) => (
                  <FormItem className="flex flex-col space-y-1">
                    <FormLabel
                      htmlFor="origin"
                      className="text-sm font-medium text-gray-700"
                    >
                      {mergedT('origin')}
                    </FormLabel>
                    <FormControl>
                      <Input
                        id="origin"
                        type="text"
                        required
                        {...field}
                        placeholder={mergedT('originPlaceholder')}
                        className="block border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="destination"
                render={({ field }) => (
                  <FormItem className="flex flex-col space-y-1">
                    <FormLabel
                      htmlFor="destination"
                      className="text-sm font-medium text-gray-700"
                    >
                      {mergedT('destination')}
                    </FormLabel>
                    <FormControl>
                      <Input
                        required
                        {...field}
                        type="text"
                        id="destination"
                        placeholder={mergedT('destinationPlaceholder')}
                        className="block border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem className="flex flex-col space-y-1">
                  <FormLabel htmlFor="date" className="text-sm font-medium text-gray-700">
                    {mergedT('description')}
                  </FormLabel>
                  <FormControl>
                    <Textarea
                      rows={4}
                      {...field}
                      placeholder={mergedT('descriptionPlaceholder')}
                      className="block w-full border-gray-300 rounded-md max-h-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm min-h-20"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button
              size="sm"
              type="submit"
              className="items-center w-full md:w-fit px-9 text-sm font-medium flex backdrop-blur-sm bg-opacity-20"
            >
              {mergedT('submit')}
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default JourneySubmissionCard;
