'use client';

import { ArrowLeftIcon, LucideArrowLeftRight } from 'lucide-react';
import { useLocale } from 'next-intl';

import { formatCheckDate } from 'utils';

import { Button } from '../ui/button';
import { utf8_to_latin1 } from 'utils/stationsUtils';
import { allowBrowsingDestinations } from 'config/topOriginStations';

interface Props {
  originName: string;
  destinationName?: string;
  handleOnBack?: () => Promise<void>;
  handleOnReverse?: () => void;
  checkIn: Date;
  prefix: string;
  transportMode?: string;
}

const SearchResultHeader = ({
  originName,
  destinationName,
  handleOnBack,
  handleOnReverse,
  checkIn,
  prefix,
  transportMode,
}: Props) => {
  const locale = useLocale();

  if (transportMode === 'plane' && window.innerWidth <= 768) {
    return null;
  }
  return (
    <div className="mb-5 mt-4 overflow-x-hidden flex flex-row items-center justify-start">
      {handleOnBack && allowBrowsingDestinations(originName) && (
        <Button
          variant="secondary"
          className="hidden lg:block absolute -mt-7 -left-12 h-fit bottom cursor-pointer"
          onClick={handleOnBack}
        >
          <ArrowLeftIcon className="h-10 w-10 opacity-50 text-gray-400 hover:text-primary" />
        </Button>
      )}

      <div className="mx-auto w-full md:ml-0 flex flex-col font-prosto">
        <div className="flex flex-row items-center justify-center sm:justify-start gap-1 text-sm text-gray-400">
          <span>{checkIn ? formatCheckDate(checkIn, locale) : 'This week'}</span>
          <span>{' · '}</span>
          <span>Aller simple</span>
        </div>
        <h1 className="text-xl mt-1 flex flex-nowrap justify-center sm:justify-start  overflow-hidden items-center font-semibold md:text-2xl lg:mb-6 lg:text-3xl">
          <span className="flex-shrink-0">{prefix} </span>
          <span className="w-1.5 flex-shrink-0" />
          <span className="overflow-hidden text-ellipsis whitespace-nowrap">
            {originName[`info_${locale}`] ?? utf8_to_latin1(originName)}
          </span>
          {destinationName !== null && destinationName && handleOnReverse && (
            <div className="flex items-center flex-shrink-0">
              <Button variant="ghost" size="icon" onClick={handleOnReverse}>
                <LucideArrowLeftRight className="h-6 w-6 hover:text-orange text-slate-400" />
              </Button>
              <span className="text-nowrap max-w-36 sm:max-w-72">
                {destinationName[`info_${locale}`] ?? utf8_to_latin1(destinationName)}
              </span>
            </div>
          )}
        </h1>
      </div>
    </div>
  );
};

export default SearchResultHeader;
