import Image from 'next/image';
import { useRouter, useSearchParams } from 'next/navigation';

import { goToSearchKeepingState } from 'hooks/querySearch';
import { useDestinationName, useOriginName } from 'hooks/useQuerySearch';
import { IExploreNearby } from 'typings';

interface IAppNearbyProps {
  data: IExploreNearby;
  changeOrigin?: boolean;
  isSmall?: boolean;
  onClick?: () => void;
  outline?: boolean;
  disabled?: boolean;
  brightColor?: boolean;
}

const AppNearby = ({
  data,
  outline,
  isSmall,
  onClick,
  changeOrigin,
  disabled,
  brightColor,
}: IAppNearbyProps) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const [, setDestinationName] = useDestinationName();
  const [, setOriginName] = useOriginName();

  const handleClick = async () => {
    if (disabled) {
      if (onClick) {
        return onClick();
      }
      return;
    }

    if (changeOrigin) {
      await setOriginName(data.stationName);
    } else {
      await setDestinationName(data.stationName);
    }
    if (isSmall && onClick) {
      onClick();
    } else {
      const nextSearchParams = new URLSearchParams(searchParams?.toString());
      if (changeOrigin) {
        nextSearchParams.set('origin', data.stationName);
      } else {
        nextSearchParams.set('destination', data.stationName);
      }
      await goToSearchKeepingState(router, nextSearchParams);
    }
  };

  return (
    <a
      onClick={handleClick}
      key={data.name}
      className={`${
        isSmall ? 'items-center' : 'items-center'
      } z-0 flex w-full cursor-pointer gap-x-3 rounded-xl p-2 py-4 pr-3 md:py-3 pl-6 text-left duration-100 hover:bg-gray-200 hover:bg-opacity-40 active:bg-opacity-40 md:flex-row `}
    >
      <div className="bg-gray-250 mr-1 flex h-12 min-w-[64px] items-center justify-center rounded-xl text-lg md:mr-4">
        <Image
          src={data.img}
          alt={data.name}
          width={64}
          height={64}
          placeholder="blur"
          blurDataURL={data.img}
          className={`${
            isSmall ? '' : 'h-12 w-12 md:h-16 md:w-16'
          }  h-14 w-14 rounded-lg object-cover ${outline ? 'border-2 border-primary' : ''}`}
          style={{
            maxWidth: '100%',
            height: 'auto',
            aspectRatio: '1/1',
          }}
        />
      </div>
      <div className={`md:mt-0 overflow-hidden`}>
        <span
          className={`${isSmall ? 'text-sm' : 'text-sm lg:text-base'} overflow-clip text-base font-medium text-ellipsis text-wrap line-clamp-2 tracking-tight  ${brightColor ? 'text-white' : 'text-gray-500'} ${outline ? 'text-primary' : ''}`}
        >
          {data.name}
        </span>
        <span
          className={`${isSmall ? 'text-sm' : 'text-sm lg:text-base'}  text-gray-300 leading-tight whitespace-nowrap`}
        >
          {data.country}
        </span>
      </div>
    </a>
  );
};

export default AppNearby;
