import { PRICE_UNKNOWN_CONST } from 'pages/api/getDailyFares';
import DateButtonWithPrice from './DateButtonWithPrice';

interface Props {
  dateToPrice: Array<[Date, number]>;
  checkIn: Date;
  setCheckIn: (value: Date) => Promise<URLSearchParams>;
  isLoading: boolean;
  hide: boolean;
}

const LineCalendar = ({ dateToPrice, checkIn, setCheckIn, isLoading, hide }: Props) => {
  const allPricesNull = dateToPrice.every(
    ([, price]) => !price || price === Infinity || price === PRICE_UNKNOWN_CONST
  );
  const moreThan3PricesAreEqual =
    dateToPrice.filter(
      ([, price], index, arr) =>
        price !== null && arr.filter(([, p]) => p === price).length > 3
    ).length > 0;

  if (hide) {
    return null;
  }

  return (
    <div
      className="relative w-[105.5%] md:w-[104.2%] -translate-x-4 py-4 before:absolute before:bottom-0 before:top-0 before:z-10 before:w-0"
      role="list"
    >
      <div className="flex snap-x overflow-x-auto overflow-y-auto px-4 scrollbar-hide after:pointer-events-none after:absolute after:top-0 after:right-0 after:bottom-0 after:w-12 max-sm:after:bg-gradient-to-l after:from-purpple-background">
        {dateToPrice.map(([date, price]) => (
          <DateButtonWithPrice
            key={date.toDateString()}
            date={date}
            price={price}
            isCheckIn={checkIn.valueOf() == date.valueOf()}
            noPriceAvailable={allPricesNull || moreThan3PricesAreEqual}
            setCheckIn={setCheckIn}
            isLoading={isLoading}
          />
        ))}
      </div>
    </div>
  );
};

export default LineCalendar;
