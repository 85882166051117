import { Geodesic } from 'geographiclib-geodesic';
import { Feature, GeoJsonProperties, LineString, MultiLineString } from 'geojson';
import {
  length as turfLength,
  lineIntersect,
  lineSlice,
  booleanPointInPolygon,
  Coord,
} from '@turf/turf';
import untypedTrainIntensity from './train_intensity.geo.json';
import untypedCarbonIntensityElectricity from './carbon_intensity_electricity.geo.json';
import { RouteType } from 'utils/route';

type CountryShape = GeoJSON.FeatureCollection<GeoJSON.Polygon | GeoJSON.MultiPolygon>;

const trainIntensity = untypedTrainIntensity as CountryShape;
const carbonIntensityElectricity = untypedCarbonIntensityElectricity as CountryShape;

function annotateRouteWithCountriesEmissionFactor(
  route: RouteType,
  method: string
): [GeoJsonProperties, number][] {
  let emissionIntensityData: CountryShape;

  if (method === 'train') {
    emissionIntensityData = trainIntensity as CountryShape;
  } else {
    emissionIntensityData = carbonIntensityElectricity as CountryShape;
  }

  const emissionsOnRoute: [GeoJsonProperties, number][] = [];

  emissionIntensityData.features.forEach((country) => {
    const countryPolygon = country.geometry;

    const kmTravelledInTheCountry = computeKmTravelledInCountry(countryPolygon, route);
    if (kmTravelledInTheCountry > 0) {
      emissionsOnRoute.push([country.properties, kmTravelledInTheCountry]);
    }
  });

  return emissionsOnRoute;
}

function computeKmTravelledInCountry(
  countryPolygon: GeoJSON.Polygon | GeoJSON.MultiPolygon,
  route: RouteType
): number {
  let segmentLimitsOfTravelInTheCountry: Coord[] = [];

  const startingPoint = route.geometry.coordinates[0];
  const isStartingPointInTheCountry = booleanPointInPolygon(
    startingPoint,
    countryPolygon
  ) as boolean;
  if (isStartingPointInTheCountry) {
    segmentLimitsOfTravelInTheCountry.push(startingPoint);
  }

  const crossedFrontiersPointForCountry = lineIntersect(
    route,
    countryPolygon
  ) as GeoJSON.FeatureCollection<GeoJSON.Point>;

  if (crossedFrontiersPointForCountry.features.length > 0) {
    segmentLimitsOfTravelInTheCountry.push(
      ...crossedFrontiersPointForCountry.features.map((p) => p.geometry.coordinates)
    );
  }

  const endingPoint = route.geometry.coordinates[route.geometry.coordinates.length - 1];
  const isEndingPointInTheCountry = booleanPointInPolygon(
    endingPoint,
    countryPolygon
  ) as boolean;
  if (isEndingPointInTheCountry) {
    segmentLimitsOfTravelInTheCountry.push(endingPoint);
  }

  let kmTravelledInTheCountry = 0;
  while (segmentLimitsOfTravelInTheCountry.length > 0) {
    const startSegment = segmentLimitsOfTravelInTheCountry.shift();
    const endSegment = segmentLimitsOfTravelInTheCountry.shift();

    const routeInTheCountry = lineSlice(startSegment, endSegment, route);

    kmTravelledInTheCountry += turfLength(
      routeInTheCountry as Feature<LineString | MultiLineString>,
      {
        units: 'kilometers',
      }
    ) as number;
  }

  return kmTravelledInTheCountry;
}

//TODO: replace with turf greatCircle
function greatCircleGeometry(
  dep: Coord,
  arr: Coord,
  nb: number = 100
): [LineString, number] {
  /**
   * Create the great circle geometry with geographiclib
   * parameters:
   *     - nb : number of points (default is 100)
   *     - dep, arr : departure and arrival
   * return:
   *     - GeoJSON LineString geometry
   *     - Geodesic distance in km.
   */

  // Projection
  const geod = Geodesic.WGS84;

  // Returns a list of longitude/latitude pairs describing npts equally spaced
  // intermediate points along the geodesic between the initial and terminus points.
  const r = geod.InverseLine(dep[1], dep[0], arr[1], arr[0]);

  const lons: number[] = [];
  const lats: number[] = [];
  const dist = r.s13 / 1e3; // in km

  for (let i = 0; i <= nb; i++) {
    const s = (r.s13 * i) / nb;
    const { lat2, lon2 } = r.Position(s, Geodesic.LATITUDE | Geodesic.LONGITUDE);
    lons.push(lon2);
    lats.push(lat2);
  }

  // Create the geometry
  // Displaying results over the antimeridian
  let coordinates: [number, number][];
  if (Math.abs(Math.min(...lons) - Math.max(...lons)) > 180) {
    // Then the other way is faster, we add 360° to the destination with neg lons
    coordinates = lons.map((lon, index) => [lon < 0 ? lon + 360 : lon, lats[index]]);
  } else {
    coordinates = lons.map((lon, index) => [lon, lats[index]]);
  }

  // Return geometry and distance
  return [{ type: 'LineString', coordinates }, dist];
}

export { annotateRouteWithCountriesEmissionFactor, greatCircleGeometry };
