import { Duration } from 'luxon';

import AllIcon from '../../public/assets/icons/all.svg';
import BusIcon from '../../public/assets/icons/bus.svg';
import CarIcon from '../../public/assets/icons/car.svg';
import TrainIcon from '../../public/assets/icons/train.svg';
import PlaneIcon from '../../public/assets/icons/plane.svg';
import { useTranslations } from 'next-intl';
import CarbonEmissionDialog from '../carbonEmissions/CarbonEmissionDialog';
import { useEffect, useState } from 'react';
import { planeToGdf } from 'utils/lowtrip/planes';
import getTrainEmissionsEurope, { TrainEmissions } from 'utils/lowtrip/trains';
import { calculateBestPrices } from 'utils/sortingUtils';
import { securityAndWaitingTime } from './SearchResult';
import { useNightTrainMode } from 'hooks/useQuerySearch';
import { Journey } from 'utils/journey';
import { RouteType } from 'utils/route';

interface Props {
  selectedTransportMode: string;
  setTransportMode: (transportMode: string) => void;
  isLoading: boolean;
  journeys: Journey[] | null;
  route: RouteType | null;
}

const TransportModeSelector = ({
  selectedTransportMode,
  setTransportMode,
  isLoading,
  journeys,
  route,
}: Props) => {
  const t = useTranslations('TicketList');
  const [bestPrices, setBestPrices] = useState({
    train: { min_amount: 0, min_c02: 0, min_duration: undefined as number | undefined },
    nightTrain: {
      min_amount: 0,
      min_c02: 0,
      min_duration: undefined as number | undefined,
    },
    bus: { min_amount: 0, min_c02: 0, min_duration: undefined as number | undefined },
    plane: { min_amount: 0, min_c02: 0, min_duration: undefined as number | undefined },
    all: { min_amount: 0, min_c02: 0, min_duration: undefined as number | undefined },
  });
  const isNightTrainSelected = selectedTransportMode === 'nightTrain';
  const { nightTrain: _, all: __, ...bestPricesExceptNightTrainAndPlane } = bestPrices;
  const availableTransportModes = Object.keys(bestPricesExceptNightTrainAndPlane);

  if (isNightTrainSelected) {
    availableTransportModes.splice(availableTransportModes.indexOf('plane'), 1);
  }
  const numberOfAvailableTransportModes = availableTransportModes.length;
  const trueTransportMode = isNightTrainSelected ? 'train' : selectedTransportMode;

  const [nightTrainMode] = useNightTrainMode();
  const [trainCarbonEmissions, setTrainCarbonEmissions] = useState<number | null>(0);
  const [flightCarbonEmissions, setFlightCarbonEmissions] = useState<number>(0);
  const [flightDuration, setFlightDuration] = useState<number>(0);
  const [flightPriceEstimation, setflightPriceEstimation] = useState<number>(0);
  const [detailedTrainCarbonEmissions, setDetailedTrainCarbonEmissions] = useState<
    TrainEmissions[] | null
  >(null);

  const takeoffAndLandingTime = 0.55;
  const flightSpeed = 800;
  const FlightDurationInHours = (FlightLenghtInKm) =>
    parseInt(FlightLenghtInKm) / flightSpeed +
    takeoffAndLandingTime +
    securityAndWaitingTime;

  const minPrice = 30;
  const maxPrice = 800;

  useEffect(() => {
    if (journeys) {
      const newBestPrices = calculateBestPrices(
        journeys,
        flightPriceEstimation,
        flightDuration * 3600,
        flightCarbonEmissions,
        trainCarbonEmissions
      );

      // We already force the mode to 'train' above when we are in nightTrainMode
      if (
        newBestPrices[selectedTransportMode] === undefined &&
        selectedTransportMode !== 'train' &&
        !nightTrainMode
      ) {
        setTransportMode('train');
      }
      setBestPrices(newBestPrices);
    }
  }, [journeys]);

  useEffect(() => {
    if (!route || (route?.geometry?.coordinates?.length ?? 0) == 0) {
      return;
    }

    const routeOriginCoordinates: [number, number] = [
      route.geometry.coordinates[0][0],
      route.geometry.coordinates[0][1],
    ];
    const routeDestinationCoordinates: [number, number] = [
      route.geometry.coordinates[route.geometry.coordinates.length - 1][0],
      route.geometry.coordinates[route.geometry.coordinates.length - 1][1],
    ];
    Promise.all([
      getTrainEmissionsEurope(route),
      planeToGdf(routeOriginCoordinates, routeDestinationCoordinates),
    ])
      .then(([trainEmission, flightEmission]) => {
        setDetailedTrainCarbonEmissions(trainEmission);
        setFlightDuration(FlightDurationInHours(parseInt(flightEmission[1].length)));
        setflightPriceEstimation(
          ((flightDuration - 0) / (24 - 0)) * (maxPrice - minPrice) + minPrice
        );
        setFlightCarbonEmissions(
          flightEmission[0].kgCO2eq[0] + flightEmission[0].kgCO2eq[1]
        );
        if (trainEmission) {
          const totalEmissions = trainEmission.reduce(
            (acc, current) => acc + current.totalEmissionsKg,
            0
          );
          setTrainCarbonEmissions(totalEmissions);
        }
      })
      .catch((error) => {
        console.error('Error fetching emissions:', error);
      });
  }, [route]);

  if (numberOfAvailableTransportModes == 0) {
    return null;
  }

  return (
    <div>
      <div className="flex overflow-hidden">
        <div className="relative mb-0 inline-block w-full flex-auto overflow-hidden whitespace-nowrap p-1">
          <div className="relative z-10 flex gap-2 whitespace-nowrap" role="tablist">
            {availableTransportModes.map((transportMode) => (
              <button
                id={`selector-${transportMode}`}
                key={transportMode}
                className={`relative outline-none ${
                  transportMode == trueTransportMode
                    ? 'box-border bg-orange bg-opacity-10'
                    : 'hover:bg-orange hover:bg-opacity-10'
                } h-12 w-20 flex-shrink flex-grow rounded-full px-[2px]`}
                // Required for the button to be focusable
                tabIndex={0}
                type="button"
                role="tab"
                aria-selected="true"
                onClick={() => {
                  setTransportMode(transportMode);
                }}
              >
                <span
                  className={`flex w-full flex-row items-center justify-center ${
                    numberOfAvailableTransportModes == 4
                      ? 'gap-0.5'
                      : numberOfAvailableTransportModes == 3
                        ? 'gap-1'
                        : 'gap-2'
                  }`}
                >
                  <TransportIcon
                    mode={transportMode}
                    className="fill-orange mb-0.5 sm:mb-1 h-7 w-7 sm:h-8 sm:w-8"
                  />
                  {isLoading ? (
                    <div className="flex max-w-full flex-col items-start text-sm">
                      <span className="animate-pulse rounded-lg bg-orange/50 leading-6 text-transparent">
                        5h15
                      </span>
                    </div>
                  ) : (
                    <div className="flex max-w-full flex-col items-start justify-center text-sm">
                      <span
                        className={`font-semibold leading-none  ${
                          transportMode == trueTransportMode
                            ? 'text-orange'
                            : 'text-gray-700'
                        }`}
                      >
                        {t('transportModes.' + transportMode)}
                      </span>
                      <span
                        className={`flex flex-row gap-1 pt-0.5 text-xs font-medium leading-none text-gray-500 ${
                          transportMode == 'all' && 'hidden'
                        }`}
                      >
                        <p>
                          {transportMode !== 'all' &&
                            bestPrices[transportMode].min_duration &&
                            // display duration in hours and minutes
                            Duration.fromObject({
                              seconds: bestPrices[transportMode].min_duration,
                            }).toFormat("h'h'mm")}
                        </p>
                        {bestPrices[transportMode].min_c02 !== Infinity && (
                          <p className="hidden md:inline-block">
                            <span>{'  • '}</span>
                            <span>{bestPrices[transportMode].min_c02.toFixed(0)}</span>kg
                          </p>
                        )}
                      </span>
                    </div>
                  )}
                </span>
              </button>
            ))}
          </div>
        </div>
      </div>
      <CarbonEmissionDialog
        route={route}
        transportMode={selectedTransportMode}
        trainCarbonEmissions={trainCarbonEmissions}
        flightCarbonEmissions={flightCarbonEmissions}
        flightDuration={flightDuration}
        detailedTrainCarbonEmissions={detailedTrainCarbonEmissions}
      />
    </div>
  );
};

export const TransportIcon = ({
  mode,
  className,
}: {
  mode: string;
  className: string;
}) => {
  let TransportComponent;
  switch (mode) {
    case 'bus':
      TransportComponent = BusIcon;
      break;
    case 'train':
      TransportComponent = TrainIcon;
      break;
    case 'car':
      TransportComponent = CarIcon;
      break;
    case 'nightTrain':
      TransportComponent = TrainIcon;
      break;
    case 'plane':
      TransportComponent = PlaneIcon;
      break;
    default:
      TransportComponent = AllIcon;
      break;
  }

  return <TransportComponent alt={mode + ' icon'} className={className} />;
};

export default TransportModeSelector;
