import { useEffect, useState } from 'react';

import { getDistance } from 'geolib';
import { ArrowRightIcon } from 'lucide-react';
import Image from 'next/image';
import Link from 'next/link';

import { ClosestStore, LokkiResponse, Store } from 'pages/api/getLokki';
import { Station } from 'utils/stations';

import { Badge } from '../ui/badge';
import { Card, CardDescription, CardHeader } from '../ui/card';
import { Skeleton } from '../ui/skeleton';

interface LokkiRentalCardProps {
  destinationStation: Station;
  checkIn: Date;
}

const MAX_DISTANCE_DIFFERENCE = 1000;
//TODO: remove this once we checked this is redundant with the one in the API
const MAX_DISTANCE_FROM_STATION = 5000;

// 10km = 0.09 degrees
const SQUARE_SIZE_IN_DEGREES = 0.09;

const LokkiRentalCard = ({ destinationStation, checkIn }: LokkiRentalCardProps) => {
  const [storeList, setStoreList] = useState<Store[]>([]);
  const [closestStore, setClosestStore] = useState<ClosestStore | null>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const startDate = checkIn;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const swLat = destinationStation.latitude! - SQUARE_SIZE_IN_DEGREES / 2;
        const swLng = destinationStation.longitude! - SQUARE_SIZE_IN_DEGREES / 2;
        const neLat = destinationStation.latitude! + SQUARE_SIZE_IN_DEGREES / 2;
        const neLng = destinationStation.longitude! + SQUARE_SIZE_IN_DEGREES / 2;

        const params = new URLSearchParams();
        const query = {
          swLat: swLat,
          swLng: swLng,
          neLat: neLat,
          neLng: neLng,
          persons: 2,
          minPrice: 2,
          maxPrice: 300,
          startDate: checkIn.toISOString(),
          endDate: checkIn.toISOString(),
        };
        for (const key in query) {
          params.append(key, query[key]);
        }
        const response = await fetch(`/api/getLokki?${params.toString()}`, {
          method: 'GET',
        });
        if (response.ok) {
          const lokkiResponse: LokkiResponse = await response.json();
          setStoreList(lokkiResponse.stores);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (destinationStation.country === 'FR') {
      void fetchData();
    }
  }, [destinationStation, startDate]);

  useEffect(() => {
    if (!storeList || storeList?.length === 0) {
      return;
    }

    if (destinationStation.latitude === null || destinationStation.longitude === null) {
      return;
    }
    debugger;
    let temporaryClosestStore = storeList[0];
    let minDistance = getDistance(
      {
        latitude: destinationStation.latitude,
        longitude: destinationStation.longitude,
      },
      {
        latitude: storeList[0].lat,
        longitude: storeList[0].lng,
      }
    );

    for (let i = 1; i < storeList.length; i++) {
      const store = storeList[i];
      const distance = getDistance(
        {
          latitude: destinationStation.latitude,
          longitude: destinationStation.longitude,
        },
        {
          latitude: store.lat,
          longitude: store.lng,
        }
      );

      if (
        distance < minDistance ||
        (store?.images[0] &&
          !temporaryClosestStore?.images[0] &&
          distance - minDistance < MAX_DISTANCE_DIFFERENCE)
      ) {
        minDistance = distance;
        temporaryClosestStore = store;
      }
    }

    setClosestStore({ ...temporaryClosestStore, distanceFromStation: minDistance });
  }, [storeList]);

  if (loading) {
    return <Skeleton className="w-full h-28 rounded-md border" />;
  } else if (error) {
    return (
      <div className="w-full h-28 rounded-md border bg-orange/20">
        <p className="text-center text-gray-500">{error}</p>
      </div>
    );
  } else if (
    error ||
    !storeList ||
    !closestStore ||
    storeList?.length === 0 ||
    !closestStore?.url ||
    closestStore?.distanceFromStation > MAX_DISTANCE_FROM_STATION
  ) {
    return null;
  }

  return (
    <Link key={destinationStation.id} href={closestStore.url} target="_blank" passHref>
      <Card className="relative group hover:bg-secondary transform duration-200">
        <ArrowRightIcon className="opacity-0 group-hover:opacity-100 absolute top-2 right-2 w-5 h-5 text-orange" />
        <CardHeader className="flex flex-row items-center justify-center w-full pl-3 py-2">
          <Image
            src={
              closestStore.images[0] ||
              closestStore.logoURL ||
              '/assets/stickers/packed-bike.svg'
            }
            alt={closestStore.name}
            width={200}
            height={200}
            className="rounded-md h-24 object-cover"
          />
          <div className="flex flex-col items-start w-full">
            <div className="flex flex-col items-start justify-start w-full h-full px-4 py-2">
              <h2 className="text-lg font-semibold text-gray-800">{closestStore.name}</h2>
              <CardDescription className="text-sm text-gray-500">
                à {Math.round(closestStore.distanceFromStation / 10) * 10}m de la gare
              </CardDescription>
            </div>
            <div className="flex flex-row items-center justify-start w-full h-full px-4 pb-3">
              <Badge className="text-sm flex items-center">
                <span className="text-xs">{closestStore?.googleRating} sur Google</span>
              </Badge>
            </div>
          </div>
        </CardHeader>
      </Card>
    </Link>
  );
};
export default LokkiRentalCard;
