import { Session } from 'next-auth';

enum Role {
  USER = 'user',
  ADMIN = 'admin',
}

export function isAdmin(session: Session): boolean {
  const role = session?.user?.role;
  return role === Role.ADMIN;
}
